import { Product } from "../type/models"
import { BaseProductsRequest, ListCustomersRequest, ListProductsRequest } from "../type/request"
import { BaseResponse, PaginatedResponse, ProductResponse } from "../type/response"
import { BASE_URL, CMS_API_CONTROLLER } from "../utils/const/apiConst"
import { deleteCall, getCall, postCall, postFormCall } from "./baseCall"

export const getProductList = async (params: ListProductsRequest) => {
    return await postCall<ListProductsRequest, PaginatedResponse<Product>>(`${BASE_URL}${CMS_API_CONTROLLER}/productsList`, params)
}
export const getProductById = async (id: string) => {
    return await getCall<{}, ProductResponse>(`${BASE_URL}${CMS_API_CONTROLLER}/getProductById?id=${id}`)
}
export const upsertProduct = async (id: string, product: BaseProductsRequest) => {
    return await postFormCall<BaseProductsRequest, ProductResponse>(`${BASE_URL}${CMS_API_CONTROLLER}/upsertProduct`, product)
}
export const deleteProduct = async (id: string) => {
    return await deleteCall<BaseResponse>(`${BASE_URL}${CMS_API_CONTROLLER}/deleteProduct?id=${id}`)
}
