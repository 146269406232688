import { FC, useContext } from "react";
import { AuthContext, WithChildren } from "../../context/AuthContext";
import Flex from "../primitives/Flex";
import { AppGeneralContext } from "../../context/AppGeneralContext";
import Box from "../primitives/Box";
import Button from "../primitives/Button";
import { removeAuthCall } from "../../api/AuthCall";
import { useNavigate } from "react-router-dom";
import { BoxArrowRight } from "react-bootstrap-icons";
import { ThemeContext } from "styled-components";
import { ThemeSwitcher } from "./ThemeSwitcher";
import { Aside } from "./Aside";
import { PAGE } from "../../pages";

export const Layout: FC<WithChildren> = ({ children }) => {
  const { isMobile } = useContext(AppGeneralContext);
  const { auth, logout } = useContext(AuthContext);

  const navigate = useNavigate();
  const { colors } = useContext(ThemeContext);
  const Logout = () => {
    removeAuthCall();
    logout();
    navigate(`/${PAGE.login.path}`);
  };
  return (
    <>
      <Flex width={isMobile ? [1] : [12 / 12]}>
        {auth &&
          <Flex backgroundColor={colors.asideBg} flexDirection={'column'} overflow={'hidden'} width={'15%'} height={'100%'} >
            <Aside collapse={false}></Aside>
          </Flex>

        }
        <Flex
          justifyContent={"center"}
          width={auth ? "85%" : '100%'}
          flexDirection={"row"}
          height={"100vh"}
          alignItems={"center"}
          paddingBottom={3}
          overflow={'scroll'}
          overflowX={'hidden'}
        >
          {children}
        </Flex>
        <Flex
          alignItems={'center'}
          position={"absolute"}
          top={2}
          right={2}
          height={"10%"}
          zIndex={999}
        >
          {auth !== undefined && (
            <Box marginLeft={3}>
              {isMobile ? <Button width={"xs"} variant="danger" onClick={() => Logout()}>
                <BoxArrowRight
                  color={colors.whiteA12}

                />
              </Button> : <Button
                onClick={() => Logout()}
                type="button"
                variant={"danger"}
                width={"sm"}
              >
                Logout
              </Button>}

            </Box>)}
        </Flex>

      </Flex>
    </>
  );
};
