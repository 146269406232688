import axios from "axios";
import { LoginModel } from "../type/models";
import { LoginRequest, Verify_tokenRequest } from "../type/request";
import { LoginResponse, Verify_tokenResponse } from "../type/response/baseResponses";
import { AUTH_API_CONTROLLER, BASE_URL } from "../utils/const/apiConst";
import { postCall } from "./baseCall";

export const getAuthCall = async (user: LoginModel) =>
  await postCall<LoginRequest, LoginResponse>(
    `${BASE_URL}${AUTH_API_CONTROLLER}/Login`,
    user
  );
export const verifyTokenCall = async (api_token: string) =>
  await postCall<Verify_tokenRequest, Verify_tokenResponse>(
    `${BASE_URL}${AUTH_API_CONTROLLER}/VerifyToken`,
    {api_token}
  );
 export const removeAuthCall = async()=>{
  
  delete axios.defaults.headers.common["Authorization"]
 }