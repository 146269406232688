import { useContext, useMemo } from "react";
import { Pager } from "../../type/response";
import Txt from "./Txt";
import Flex from "./Flex";
import Button from "./Button";
import { AppGeneralContext } from "../../context/AppGeneralContext";

interface PagerProps {
  pager: Pager;
  setPage: (page: number | undefined) => void
}

export const PagerComponent = ({ pager, setPage }: PagerProps) => {
  const showPager = useMemo(() => {
    if (pager) {
      return pager.links.map((el, i) => {
        if (!el.isFirst && !el.isLast) {
          return (
            <Txt
              key={`${el.label}${i}`}
              onClick={() => {
                el.page !== null && setPage(el.page);
              }}
              marginX={1}
              borderColor={"primaryBgActive"}
              borderBottom={el.active ? "2px solid" : ""}
              variant={el.page !== null ? "link" : "disabledLink"}
            >
              {el.label}
            </Txt>
          );
        }
        return (
          <Button
            key={el.label}
            width={el.isFirst || el.isLast ? "sm" : "xs"}
            marginRight={el.isFirst ? 2 : ''}
            marginLeft={el.isLast ? 2 : ''}
            disabled={el.page === null}
            variant={"login"}
            onClick={() => {
              el.page !== null && setPage(el.page);
            }}
          >
            {el.label}
          </Button>
        );
      });
    }
  }, [pager, setPage]);

  return (
    <Flex flexDirection={"row"} alignItems={"center"}>
      {showPager}
    </Flex>
  );
};
