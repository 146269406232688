import Flex from "../components/primitives/Flex";
import Box from "../components/primitives/Box";
import { FormLogin } from "../components/molecules/FormLogin";


export const Login = () => {

  return (
    <Flex paddingBottom={3} width={'100%'} justifyContent={"center"} alignItems={'center'} flexDirection={"column"} height={'100vh'}>
    <Box height={'70%'} width={'30%'}>
      <FormLogin/>
    </Box>
    </Flex>
  );
};
