import styled from "styled-components";
import { ColorProps, flexbox, LayoutProps, SpaceProps, layout, space, FlexboxProps, position, PositionProps, border, BorderProps, color } from "styled-system";

type CursorProps = {
  cursor?: 'default' | 'pointer'
}

const Flex = styled.div<CursorProps & ColorProps & FlexboxProps & LayoutProps & SpaceProps & PositionProps & BorderProps>`
  display: flex;
  cursor: ${(p)=>p.cursor };
  ${space}
    ${layout}
    ${flexbox}
    ${position}
    ${border}
    ${color}
`
export default Flex 