/* eslint-disable react-hooks/exhaustive-deps */
import styled from "styled-components";
import { HidePasswordIcon, StyledIconContainer } from "./HidePasswordIcon";
import { FormikContextType } from "formik";
import {
  HTMLInputTypeAttribute,
  useMemo,
  useState,
} from "react";

import Box from "../primitives/Box";
import { Icon, X } from "react-bootstrap-icons";


/**
 * INTERFACE description
 *
 * @interface CustomInputProps
 * @param {'password'| 'normal'} variant Choose if you want password input with eye icon || any other input type.
 * @param {string} type if variant==='normal' select the strict input type
 * @param {FormikContextType<any>} formik if You need it, enable directly in the component, or just disable it with comment
 *
 * 
 * 
 * HOW TO USE
 * 
 * you have access to 3 callbacks, onClick, onChange and onClear.
 * use onChange when formik is not expected,
 * onClick when you need it and onClear in combination with clearable, to have the possibility to
 * reset the input value. Attention, 'onClear' is not a function already implemented, it needs an external state.
 * obviously, to take advantage of the type password features, it needs the HidePassword component
 * 
 * 
 * 
 */

interface CustomInputProps {
  leftIconBackground?:string,
  id?: string;
  variant: "password" | "normal";
  name: string;
  formik: FormikContextType<any> | undefined;
  iconSize?: string | number | undefined
  leftIcon?: boolean;
  icon?: JSX.Element;
  onClickCallback?: () => void;
  value?: string | number;
  className?: string;
  checked?: boolean;
  placeholder?: string;
  type?: HTMLInputTypeAttribute;
  readOnly?: boolean;
  defaultChecked?: boolean;
  clearable?: boolean;
  onChangeCallback?: React.ChangeEventHandler<HTMLInputElement> | undefined;
  onClear?: () => void;
}

export const StyledInput = styled.input<{$leftIcon:boolean|undefined}>`
  width: 100%;
  padding:${(props) => props.$leftIcon?'0.6em 2.2em':'0.6em'} ;
  border-radius: 8px;
  font-size: ${(props) => props.theme.fontSizes.md};
  color: ${(props) => props.theme.colors.textColor};
  background-color: ${(props) => props.theme.colors.primaryBgActive};
  border-color: transparent;
  ${(props) => (props.type === "radio" ? "cursor:pointer" : "")};

  ::placeholder {
    color: ${(props) => props.theme.colors.gray10};
  }
`;

export const Input = ({
  id,
  iconSize,
  value,
  name,
  className,
  placeholder,
  formik,
  variant,
  leftIcon,
  icon,
  type,
  onClickCallback,
  readOnly = false,
  checked,
  defaultChecked,
  clearable,
  onChangeCallback,
  onClear,
  leftIconBackground,
}: CustomInputProps) => {

  
  const [isHidden, setIsHidden] = useState(true);
  const handleHidePassword = (isHidden: boolean) => {
    setIsHidden(isHidden);
  };

  const showClearableIcon = useMemo(() => {
    if (clearable) {
      return (
        <StyledIconContainer iconPosition="right">
          <X
           
            onClick={() => onClear?.()}
            cursor={"pointer"}
            color="rgb(0, 144, 255)"
          />
        </StyledIconContainer>
      );
    }
  }, [clearable, name]);
  const showPasswordIcon = useMemo(() => {
    if (variant === "password") {
      return (
        <HidePasswordIcon
          callback={handleHidePassword}
          iconSize={iconSize}
        ></HidePasswordIcon>
      );
    } else {
      return null;
    }
  }, [variant, iconSize]);

  const showLeftIcon = useMemo(() => {
    if (leftIcon && icon) {
      return (
        <StyledIconContainer background={leftIconBackground} iconPosition="left">
          <>
          {icon}
          </>
            
         
          
        </StyledIconContainer>
      );
    }
    return null;
  }, [leftIcon, icon,leftIconBackground]);

  return (
    <Box position={"relative"} width={"100%"}>
      <StyledInput
      $leftIcon={leftIcon}
        id={id}
        defaultChecked={defaultChecked}
        checked={checked}
        readOnly={readOnly}
        value={value}
        name={name}
        className={className}
        type={
          type ? type : isHidden && variant === "password" ? "password" : "text"
        }
        placeholder={placeholder}
        onChange={
          formik !== undefined
            ? formik.handleChange
            : (e) => onChangeCallback?.(e)
        }
        onBlur={() => formik?.setFieldTouched(name, true)}
        autoComplete="current-password"
        onClick={() => onClickCallback?.()}
      />
      {showLeftIcon}
      {showPasswordIcon}
      {showClearableIcon}
    </Box>
  );
};
