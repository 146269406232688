import React, { useEffect } from "react";


/**
 * Hook that alerts clicks outside of the passed ref
 */
export function useOutsideAlerter(ref: React.MutableRefObject<HTMLElement | null>, isOpen: boolean, callback: () => void, ids?: string) {

    useEffect(() => {

        if (!isOpen) {
            return
        }

        function handleClickOutside(event: { target: any }) {

            if (event.target.parentNode.matches(`#${ids}`) || event.target.matches((`#${ids}`)))
                return
            if (ref.current && !ref.current.contains(event.target)) {

                callback()
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref, isOpen]);
}