import { useContext, useEffect, useMemo } from 'react'
import Box from '../components/primitives/Box'
import Flex from '../components/primitives/Flex'
import { AppGeneralContext } from '../context/AppGeneralContext';
import { TableHeading, TableBody } from '../components/table';
import Txt from '../components/primitives/Txt';
import Label from '../components/primitives/Label';
import { Input } from '../components/atoms';
import { PlusCircle, Search, Trash } from 'react-bootstrap-icons';
import { PagerComponent } from '../components/primitives';
import Tooltip from '../components/primitives/Tooltip';
import { ThemeContext } from 'styled-components';
import { CustomerContext } from '../context/CustomerContext';
import { useNavigate } from 'react-router-dom';
import { ActionsType } from '../type/useReducer';
import { PAGE } from './pagesPath';
import { CouponTableBody } from '../components/table/CouponTableBody';
import { CouponContext } from '../context/CouponContext';
import { CouponTableHeading } from '../components/table/CouponTableHeading';

export const CouponsList = () => {
  const { isMobile } = useContext(AppGeneralContext);
  const navigate = useNavigate()
  const { colors } = useContext(ThemeContext);
  const { binAction, setBinAction, pager, setPage, setNameToSearch, nameToSearch, setSurnameToSearch,
    surnameToSearch, setEmailToSearch, emailToSearch, couponDispatch,  couponsList, getCouponsList, codeToSearch, setCodeToSearch } = useContext(CouponContext)

  const showPagination = useMemo(() => {
    if (pager.totalPages < 1) {
      return <></>;
    }
    return (
      <Flex
        flexDirection={"row"}
        width={"100%"}
        justifyContent={"end"}
        height={'20%'}
      >
        <PagerComponent pager={pager} setPage={setPage}></PagerComponent>
      </Flex>
    );
  }, [pager]);

  useEffect(() => {
    couponDispatch({ type: ActionsType.RESET_COUPON })
  }, [])

  useEffect(() => {
    getCouponsList()
}, []);

  return (
    <Flex flexDirection={"column"} width={"90%"} height={"100vh"}>
      <Flex justifyContent={"center"} alignItems={"center"} height={"12%"} mt={'20px'} mb={'25px'}>
        <Txt>
          <h2>Lista Coupons</h2>
        </Txt>
      </Flex>
      <Flex width={"100%"} flexDirection={'row'} height={'10%'} marginBottom={3} justifyContent={'space-between'}>
        <Flex alignItems={"end"} width={'100%'} >
          {couponsList.length === 0 && surnameToSearch === "" && nameToSearch === ""
            && emailToSearch === "" && codeToSearch === "" && !binAction ? (
            <></>
          ) : (
            <>
            <Box width={"md"} marginRight={3} textAlign={"left"}>
                <Label pl={2}>Filtra per Codice</Label>
                <Input
                  leftIconBackground={colors.transparent}
                  clearable
                  icon={<Search color={colors.primaryText} />}
                  leftIcon
                  variant={"normal"}
                  value={codeToSearch}
                  type="text"
                  name={"Code"}
                  formik={undefined}
                  onChangeCallback={(e) => setCodeToSearch(e.target.value)}
                  onClear={() => setCodeToSearch("")}
                  placeholder="Codice"
                />
              </Box>
              <Box width={"md"} marginRight={3} textAlign={"left"}>
                <Label pl={2}>Filtra per Nome</Label>
                <Input
                  leftIconBackground={colors.transparent}
                  clearable
                  icon={<Search color={colors.primaryText} />}
                  leftIcon
                  variant={"normal"}
                  value={nameToSearch}
                  type="text"
                  name={"Nome"}
                  formik={undefined}
                  onChangeCallback={(e) => setNameToSearch(e.target.value)}
                  onClear={() => setNameToSearch("")}
                  placeholder="Nome"
                />
              </Box>
              <Box width={"md"} marginRight={3} textAlign={"left"}>
                <Label pl={2}>Filtra per Cognome</Label>
                <Input
                  leftIconBackground={colors.transparent}
                  clearable
                  icon={<Search color={colors.primaryText} />}
                  leftIcon
                  variant={"normal"}
                  value={surnameToSearch}
                  type="text"
                  name={"Cognome"}
                  formik={undefined}
                  onChangeCallback={(e) => setSurnameToSearch(e.target.value)}
                  onClear={() => setSurnameToSearch("")}
                  placeholder="Cognome"
                />
              </Box>
              <Box width={"md"} marginRight={3} textAlign={"left"}>
                <Label pl={2}>Filtra per E-mail</Label>
                <Input
                  leftIconBackground={colors.transparent}
                  clearable
                  icon={<Search color={colors.primaryText} />}
                  leftIcon
                  variant={"normal"}
                  value={emailToSearch}
                  type="text"
                  name={"Email"}
                  formik={undefined}
                  onChangeCallback={(e) => setEmailToSearch(e.target.value)}
                  onClear={() => setEmailToSearch("")}
                  placeholder="Indirizzo e-mail"
                />
              </Box>
            </>
          )}
          {(nameToSearch !== "" || surnameToSearch !== "" || emailToSearch !== "" || codeToSearch !== "") &&
            <Box marginLeft={4} marginBottom={1}>
              <Trash size={'1.3em'} color={colors.primaryText}
                onClick={() => {
                  setSurnameToSearch(''); setNameToSearch(''); setEmailToSearch(''); setCodeToSearch(''); setBinAction(true)
                }}
                cursor={'pointer'} /></Box>}
        </Flex>
        <Flex
          alignItems={"end"}
          justifyContent={"center"}
          height={"100%"}
        >
        </Flex>
      </Flex>
      <Flex
        flexDirection={"column"}
        justifyContent={"space-between"}
        height={"78%"}
        position={"relative"}
        width={'100%'}
      >
        {couponsList.length > 0 ? (
          <Flex
            flexDirection={"column"}
            height={"80%"}
            marginTop={isMobile ? "80px" : "59px"}
            overflowY={"scroll"}
            width={"100%"}
          >
            <CouponTableHeading />
            <CouponTableBody data={couponsList} />
          </Flex>) : (<>Mi dispiace, ma non ho trovato ciò che cerchi</>)}
        {showPagination}
      </Flex>
    </Flex>
  )
}
