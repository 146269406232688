import { Dispatch, FC, SetStateAction, createContext, useCallback, useContext, useEffect, useReducer, useState } from "react";
import { WithChildren } from "./AuthContext";
import { useDebounce } from "../utils";
import { Customer } from "../type/models";
import { PagerInitialState } from "../utils/const/initialValue";
import { Pager } from "../type/response";
import { ModalContext } from "./ModalContext";
import { ListCustomersRequest } from "../type/request";
import { AppGeneralContext } from "./AppGeneralContext";
import { toastType } from "../type/enum";
import { CustomerActions, customerInit, customerInitState, customerReducer } from "../type/useReducer";
import { getAdventCustomersList } from "../api/CustomersCall";


interface AdventCustomerContextProps {
    page: number | undefined;
    setPage: (page: number | undefined) => void;
    getAdventCalendarCustomersList: () => Promise<void>
    coupon: Customer
    customerDispatch: Dispatch<CustomerActions>
    customersList: Customer[]
    setCustomersList: Dispatch<SetStateAction<Customer[]>>
    nameToSearch: string | undefined,
    setNameToSearch: Dispatch<SetStateAction<string | undefined>>
    surnameToSearch: string | undefined,
    setSurnameToSearch: Dispatch<SetStateAction<string | undefined>>
    emailToSearch: string | undefined,
    setEmailToSearch: Dispatch<SetStateAction<string | undefined>>
    phoneToSearch: string | undefined,
    setPhoneToSearch: Dispatch<SetStateAction<string | undefined>>
    pager: Pager,
    setPager: Dispatch<SetStateAction<Pager>>
    binAction: boolean,
    setBinAction: Dispatch<SetStateAction<boolean>>
}

const AdventCustomerContextInit: AdventCustomerContextProps = {
    page: undefined,
    setPage: () => { },
    getAdventCalendarCustomersList: () => new Promise<void>(() => { }),
    coupon: customerInit,
    customerDispatch: () => { },
    customersList: [],
    setCustomersList: () => { },
    nameToSearch: '',
    setNameToSearch: () => { },
    surnameToSearch: '',
    setSurnameToSearch: () => { },
    emailToSearch: '',
    setEmailToSearch: () => { },
    phoneToSearch: '',
    setPhoneToSearch: () => { },
    binAction: false,
    setBinAction: () => { },
    pager: PagerInitialState,
    setPager: () => { }
};

export const AdventCustomerContext =
    createContext<AdventCustomerContextProps>(AdventCustomerContextInit);

export const AdventCustomerContextProvider: FC<WithChildren> = ({ children }) => {
    const { setLoadingModal, closeModal } = useContext(ModalContext)
    const { addToast } = useContext(AppGeneralContext);
    const [pager, setPager] = useState<Pager>(PagerInitialState)
    const [page, setPage] = useState<number | undefined>(undefined);
    const [binAction, setBinAction] = useState<boolean>(false)
    const [nameToSearch, setNameToSearch] = useState<string | undefined>("");
    const [surnameToSearch, setSurnameToSearch] = useState<string | undefined>("");
    const [emailToSearch, setEmailToSearch] = useState<string | undefined>("");
    const [phoneToSearch, setPhoneToSearch] = useState<string | undefined>("");

    const [customersList, setCustomersList] = useState<Customer[]>([])
    const [customer, customerDispatch] = useReducer(customerReducer, customerInit, customerInitState)

    const searchSurnameParams = useDebounce(surnameToSearch, 200);
    const searchNameParams = useDebounce(nameToSearch, 200);
    const searchEmailParams = useDebounce(emailToSearch, 200);
    const searchPhoneParams = useDebounce(phoneToSearch, 200);

    const modalDelay = 300

    const getAdventCalendarCustomersList = useCallback(async () => {
        setLoadingModal()
        const req: ListCustomersRequest = {
            pageNum: page ?? 1,
            pageSize: 10,
            name: searchNameParams ?? '',
            surname: searchSurnameParams ?? '',
            email: searchEmailParams ?? '',
            phone: searchPhoneParams ?? ''
        }

        const resp = await getAdventCustomersList(req)
        if (!resp.response || !resp.isSuccess) {
            closeModal(modalDelay)
            addToast(toastType.error, 'Errore durante il caricamento dei dati')
            return
        }
        setBinAction(false)
        closeModal(modalDelay)
        setCustomersList(resp.response.data)
        setPager(resp.response.pager)

    }, [searchSurnameParams, searchNameParams, searchEmailParams, searchPhoneParams, page])

    useEffect(() => {
        setPage(1)
    }, [searchSurnameParams, searchNameParams, searchEmailParams, searchPhoneParams]);

    useEffect(() => {
        getAdventCalendarCustomersList()
    }, [getAdventCalendarCustomersList]);

    const AdventCustomerContextInitValue: AdventCustomerContextProps = {
        page,
        setPage,

        pager,
        setPager,

        binAction,
        setBinAction,

        getAdventCalendarCustomersList,

        coupon: customer,
        customerDispatch,

        customersList,
        setCustomersList,

        nameToSearch,
        setNameToSearch,

        surnameToSearch,
        setSurnameToSearch,

        emailToSearch,
        setEmailToSearch,

        phoneToSearch,
        setPhoneToSearch
    };

    return (
        <AdventCustomerContext.Provider value={AdventCustomerContextInitValue}>
            {children}
        </AdventCustomerContext.Provider>
    );
};
