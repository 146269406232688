import { Outlet } from "react-router-dom";
import { CouponContextProvider } from "../context/CouponContext";

export const CouponMainBody = () => {
  return (
    <CouponContextProvider>
      <Outlet />
    </CouponContextProvider>
  );
};
