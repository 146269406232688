import { useContext, useEffect, useMemo, useReducer, useState } from 'react'
import Flex from '../components/primitives/Flex'
import { AppGeneralContext } from '../context/AppGeneralContext';
import Txt from '../components/primitives/Txt';
import { PagerComponent } from '../components/primitives';
import { ThemeContext } from 'styled-components';
import { ModalContext } from '../context/ModalContext';
import { toastType } from '../type/enum';
import { Order } from '../type/models';
import { ApiSimpleResponse, OrderResponse, Pager } from '../type/response';
import { PagerInitialState } from '../utils/const/initialValue';
import Form from '../components/primitives/Form'
import { orderReducer, orderInit, orderInitState } from '../type/useReducer';
import { useLocation } from 'react-router-dom';
import { OrderContext } from '../context/OrderContext';
import { getOrderById, upsertOrder } from '../api/OrdersCall';
import { useFormik } from 'formik';
import { orderValidationSchema } from '../utils/formikSchemas';

export const OrderDetail = () => {
  const { order: data, setPage } = useContext(OrderContext)
  const location = useLocation()
  const orderId = location.state.orderId as string
  const { isMobile, addToast } = useContext(AppGeneralContext);
  const { colors } = useContext(ThemeContext);
  const [orderDetail, setOrderDetail] = useState<Order>()
  const [pager, setPager] = useState<Pager>(PagerInitialState)
  const [binAction, setBinAction] = useState<boolean>(false)
  const [orderState, orderDispatch] = useReducer(orderReducer, orderInit, orderInitState)
  const [copiedToClipboard, setCopiedToClipboard] = useState<boolean>(false)

  const { setLoadingModal, closeModal, openApiModalMessage } = useContext(ModalContext);

  const modalDelay = 300

  const getOrder = async (id: string) => {
    setLoadingModal()
    const resp = await getOrderById(id)
    if (!resp.response || !resp.isSuccess) {
      closeModal(modalDelay)
      addToast(toastType.error, 'Errore durante il caricamento dei dati')
      return
    }
    closeModal(modalDelay)
    setOrderDetail(resp.response.data)
  }

  useEffect(() => {
    if (!!orderId) {
      getOrder(orderId)
    }
  }, []);

  const showPagination = useMemo(() => {
    if (pager.totalPages < 1) {
      return <></>;
    }
    return (
      <Flex
        flexDirection={"row"}
        width={"100%"}
        justifyContent={"end"}
        height={'20%'}
      >
        <PagerComponent pager={pager} setPage={setPage}></PagerComponent>
      </Flex>
    );
  }, [pager]);

  useEffect(() => {
    if (!copiedToClipboard) {
      return
    }
    const timeout = setTimeout(() => {
      setCopiedToClipboard(false);
    }, 1500);
    return () => clearInterval(timeout)
  }, [copiedToClipboard])

  const copyToClipboard = (textToWrite: string) => {
    navigator.clipboard.writeText(textToWrite).then(() => {
      setCopiedToClipboard(true)
    })
    setCopiedToClipboard(true)
  }
  const formik = useFormik<Order>({
    enableReinitialize: true,
    initialValues: orderDetail ?? orderInit,
    validationSchema: orderValidationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setLoadingModal()
      setSubmitting(true)
      let resp: ApiSimpleResponse<OrderResponse>
      resp = await upsertOrder(orderDetail?.id ?? '', { ...values })
      if (!resp.response || !resp.isSuccess) {
        openApiModalMessage(resp)
        return
      }
      closeModal(modalDelay)
      setSubmitting(false)
      setOrderDetail(resp.response.data)
    }
  })

  // const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   const { name, checked } = event.target;
  //   setOrderDetail((prevValues) => ({ ...prevValues, [name]: checked }));
  // };

  return (
    <Flex flexDirection={"column"} width={"90%"} height={"100vh"}>
      <Flex justifyContent={"center"} alignItems={"center"} height={"12%"} mt={'20px'} mb={'20px'}>
        <Txt>
          <h2>Ordine num. {orderDetail?.orderNumber} di {orderDetail?.customer!.name} {orderDetail?.customer!.surname}</h2>
        </Txt>
      </Flex>
      <Form width={'100%'} flexDirection={'column'} justifyContent={'space-around'}>
        <Flex flexWrap={'wrap'} justifyContent={'space-evenly'}>
          <Flex flexDirection={'row'} justifyContent={'center'} borderBottom={'2px solid pink'}>
            <Flex cursor='pointer' width={"lg"} marginRight={[0, 3]} alignItems={'center'} justifyContent={'center'}>
              <Txt fontWeight={700} onClick={() => copyToClipboard(`${orderDetail?.orderNumber}`)}>
                {copiedToClipboard ? 'Copiato!' : `Copia Numero ordine: ${orderDetail?.orderNumber}`}
              </Txt>
            </Flex>
            <Flex width={"lg"} marginRight={[0, 3]} alignItems={'center'}>
              <Txt fontWeight={700}> Data esecuzione: {orderDetail?.dateString}
              </Txt>
            </Flex>
          </Flex>
        </Flex>
        <Flex flexWrap={'wrap'} justifyContent={'center'}>
          <Flex flexDirection={'row'} justifyContent={'center'} mt={'40px'}>
            <Flex width={"lg"} marginRight={[0, 3]} alignItems={'center'}>
              <Txt fontWeight={700}> Dati cliente: {orderDetail?.customer?.name} {orderDetail?.customer?.surname}
              </Txt>
            </Flex>
          </Flex>
        </Flex>
        <Flex flexWrap={'wrap'} justifyContent={'center'} >
          <Flex flexDirection={'row'} justifyContent={'center'} mt={'20px'} borderBottom={'2px solid pink'} >
            <Flex width={"lg"} marginRight={[0, 3]} alignItems={'center'}>
              <Txt fontWeight={700}> E-mail: {orderDetail?.customer?.email}
              </Txt>
            </Flex>
            <Flex width={"lg"} marginRight={[0, 3]} alignItems={'center'}>
              <Txt fontWeight={700}> Telefono: {orderDetail?.customer?.phoneNumber}
              </Txt>
            </Flex>
          </Flex>
        </Flex>
        <Flex flexWrap={'wrap'} justifyContent={'center'}>
          <Flex flexDirection={'row'} justifyContent={'center'} mt={'40px'}>
            <Flex width={"lg"} marginRight={[0, 3]} alignItems={'center'}>
              <Txt fontWeight={700}> Dati Prodotto/Servizio ordinato:
              </Txt>
            </Flex>
          </Flex>
        </Flex>
        <Flex flexWrap={'wrap'} justifyContent={'center'} >
          <Flex flexDirection={'row'} justifyContent={'center'} mt={'20px'} borderBottom={'2px solid pink'} >
            <Flex width={"lg"} marginRight={[0, 3]} alignItems={'center'}>
              <Txt fontWeight={700}> {orderDetail?.productService?.title} {orderDetail?.productService?.brandName}
              </Txt>
            </Flex>
            <Flex width={"lg"} marginRight={[0, 3]} alignItems={'center'}>
              <Txt fontWeight={700}> Quantità: {orderDetail?.quantity}
              </Txt>
            </Flex>
          </Flex>
        </Flex>
        {/* <Flex width={"lg"} marginTop={20} alignItems={'center'}>
          <Txt fontWeight={700}>
            <div className="form-group-checkbox mb-0">
              <input type="checkbox" id="isPaid" name="isPaid"
                className="form-checkbox"
                checked={formik.initialValues.isPaid}
                onChange={handleCheckboxChange} />
              <label className="">Pagato</label>
            </div>
          </Txt>
          <Txt fontWeight={700}>
            <div className="form-group-checkbox mb-0">
              <input type="checkbox" id="isDelivered" name="isDelivered"
                className="form-checkbox"
                checked={formik.initialValues.isDelivered}
                onChange={handleCheckboxChange} />
              <label className="">Consegnato/Ritirato</label>
            </div>
          </Txt>
        </Flex> */}
      </Form >
    </Flex >
  )
}
