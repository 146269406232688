import styled from "styled-components";
import {
  ColorProps,
  LayoutProps,
  SpaceProps,
  WidthProps,
  layout,
  space,
  position,
  PositionProps,
  border,
  BorderProps,
  color,
  textAlign,
  TextAlignProps
} from "styled-system";

const Box = styled.div<TextAlignProps&BorderProps&LayoutProps & ColorProps & WidthProps & SpaceProps&PositionProps>`
  ${space}
  ${layout}
  ${position}
  ${border}
  ${color}
  ${textAlign}
`;
export default Box;
