import { Dispatch, FC, SetStateAction, createContext, useCallback, useContext, useEffect, useReducer, useState } from "react";
import { WithChildren } from "./AuthContext";
import { useDebounce } from "../utils";
import { Product } from "../type/models";
import { PagerInitialState } from "../utils/const/initialValue";
import { Pager } from "../type/response";
import { ModalContext } from "./ModalContext";
import { ListProductsRequest } from "../type/request";
import { AppGeneralContext } from "./AppGeneralContext";
import { toastType } from "../type/enum";
import { ProductActions, prodInit, prodInitState, prodReducer } from "../type/useReducer";
import { getProductList } from "../api/ProductsCall";
import { date } from "yup";


interface ProductContextProps {
    page: number | undefined;
    setPage: (page: number | undefined) => void;
    getProductsList: () => Promise<void>
    product: Product
    ProductDispatch: Dispatch<ProductActions>
    ProductsList: Product[]
    setProductsList: Dispatch<SetStateAction<Product[]>>
    titleToSearch: string | undefined,
    setTitleToSearch: Dispatch<SetStateAction<string | undefined>>
    brandNameToSearch: string | undefined,
    setBrandNameToSearch: Dispatch<SetStateAction<string | undefined>>
    dateFromToSearch: string | undefined,
    setDateFromToSearch: Dispatch<SetStateAction<string | undefined>>,
    dateToToSearch: string | undefined,
    setDateToToSearch: Dispatch<SetStateAction<string | undefined>>
    pager: Pager,
    setPager: Dispatch<SetStateAction<Pager>>
    binAction: boolean,
    setBinAction: Dispatch<SetStateAction<boolean>>
}

const ProductContextInit: ProductContextProps = {
    page: undefined,
    setPage: () => { },
    getProductsList: () => new Promise<void>(() => { }),
    product: prodInit,
    ProductDispatch: () => { },
    ProductsList: [],
    setProductsList: () => { },
    titleToSearch: '',
    setTitleToSearch: () => { },
    brandNameToSearch: '',
    setBrandNameToSearch: () => { },
    dateFromToSearch: '',
    setDateFromToSearch: () => { },
    dateToToSearch: '',
    setDateToToSearch: () => { },
    binAction: false,
    setBinAction: () => { },
    pager: PagerInitialState,
    setPager: () => { }
};

export const ProductContext =
    createContext<ProductContextProps>(ProductContextInit);

export const ProductContextProvider: FC<WithChildren> = ({ children }) => {
    const { setLoadingModal, closeModal } = useContext(ModalContext)
    const { addToast } = useContext(AppGeneralContext);
    const [pager, setPager] = useState<Pager>(PagerInitialState)
    const [page, setPage] = useState<number | undefined>(undefined);
    const [binAction, setBinAction] = useState<boolean>(false)
    const [titleToSearch, setTitleToSearch] = useState<string | undefined>("");
    const [brandNameToSearch, setBrandNameToSearch] = useState<string | undefined>("");
    const [dateFromToSearch, setDateFromToSearch] = useState<string | undefined>("");
    const [dateToToSearch, setDateToToSearch] = useState<string | undefined>("");

    const [ProductsList, setProductsList] = useState<Product[]>([])
    const [Product, ProductDispatch] = useReducer(prodReducer, prodInit, prodInitState)

    const searchBrandNameParams = useDebounce(brandNameToSearch, 200);
    const searchTitleParams = useDebounce(titleToSearch, 200);
    const searchDateFromParams = dateFromToSearch;
    const searchDateToParams = dateToToSearch;


    const modalDelay = 300

    const getProductsList = useCallback(async () => {
        setLoadingModal()
        const req: ListProductsRequest = {
            pageNum: page ?? 1,
            pageSize: 10,
            title: searchTitleParams ?? '',
            brandName: searchBrandNameParams ?? '',
            dateFrom: searchDateFromParams,
            dateTo: searchDateToParams
        }

        const resp = await getProductList(req)
        if (!resp.response || !resp.isSuccess) {
            closeModal(modalDelay)
            addToast(toastType.error, 'Errore durante il caricamento dei dati')
            return
        }
        setBinAction(false)
        closeModal(modalDelay)
        setProductsList(resp.response.data)
        setPager(resp.response.pager)

    }, [searchTitleParams, searchBrandNameParams, searchDateFromParams, searchDateToParams, page])

    useEffect(() => {
        setPage(1)
    }, [searchTitleParams, searchBrandNameParams, searchDateFromParams, searchDateToParams]);

    useEffect(() => {
        getProductsList()
    }, [getProductsList]);

    const ProductContextInitValue: ProductContextProps = {
        page,
        setPage,

        pager,
        setPager,

        binAction,
        setBinAction,

        getProductsList,

        product: Product,
        ProductDispatch,

        ProductsList,
        setProductsList,

        titleToSearch,
        setTitleToSearch,

        brandNameToSearch,
        setBrandNameToSearch,

        dateFromToSearch: dateFromToSearch,
        setDateFromToSearch: setDateFromToSearch,

        dateToToSearch: dateToToSearch,
        setDateToToSearch: setDateToToSearch
    };

    return (
        <ProductContext.Provider value={ProductContextInitValue}>
            {children}
        </ProductContext.Provider>
    );
};
