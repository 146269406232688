import * as TooltipPrimitive from "@radix-ui/react-tooltip";
import * as Popover from "@radix-ui/react-popover";
import Box from "./Box";

import { useMediaQuery } from "react-responsive";
import styled from "styled-components";




const PopoverArrow = styled(Popover.Arrow)`
  fill: ${(props) => props.theme.colors.primaryBgActive};
`;

const Tooltip = ({
  children,
  content,
  open,
  defaultOpen,
  onOpenChange,
  ...props
}: any) => {
  const isSmallDevice = useMediaQuery({ maxWidth: 600 });

  if (isSmallDevice) {
    return (
      <Popover.Root
        open={open}
        defaultOpen={defaultOpen}
        onOpenChange={onOpenChange}
      >
        <Popover.Trigger asChild>{children}</Popover.Trigger>
        <Popover.Content
          sideOffset={2}
          side="bottom"
          align="center"
          style={{ zIndex: 100, outline: "none" }}
          {...props}
        >
          <PopoverArrow />
          <Box zIndex={999} backgroundColor={'primaryBgActive'} style={{boxShadow:'0px 1px 5px rgba(0,0,0,0.2)', padding:'5px 10px'}} borderRadius={8} overflow={"hidden"}>
            <Box backgroundColor={'primaryBgActive'} paddingY={4} paddingX={8}>
              {content}
            </Box>
          </Box>
        </Popover.Content>
      </Popover.Root>
    );
  }
  return (
    <TooltipPrimitive.Root
      open={open}
      defaultOpen={defaultOpen}
      onOpenChange={onOpenChange}
      delayDuration={250}
    >
      <TooltipPrimitive.Trigger asChild>{children}</TooltipPrimitive.Trigger>
      <TooltipPrimitive.Content
        sideOffset={2}
        side="top"
        align="start"
        style={{ zIndex: 100 }}
        {...props}
      >
         
        <Box zIndex={999}  backgroundColor={'primaryBgActive'}  style={{boxShadow:'0px 1px 5px rgba(0,0,0,0.2)', padding:'5px 10px'}} borderRadius={8} overflow={"hidden"}>
          <Box  backgroundColor={'primaryBgActive'}  >
            {content} 
          </Box>
       
        </Box>
      </TooltipPrimitive.Content>
    </TooltipPrimitive.Root>
  );
};

export default Tooltip;
