import { FC, createContext, useState } from "react";
import { WithChildren } from "./AuthContext";

import { theme } from "../theme/theme.styled";


interface CustomThemeContextProps{
    customTheme:any;
    setCustomTheme:(theme:any)=>void;
}

const customThemeContextInit:CustomThemeContextProps={
    customTheme:{},
    setCustomTheme:(_:any)=>{},
};


export const CustomThemeContext = createContext<CustomThemeContextProps>(customThemeContextInit)

export const CustomThemeProvider:FC<WithChildren> =  ({ children }) => {
    //hook from styled-component to check current theme
   // const themeContext = useContext(ThemeContext)

    const [customTheme,setCustomTheme]= useState<any>(theme)

    const customThemeInitValue:CustomThemeContextProps ={
        customTheme,
        setCustomTheme,
    }


    return(
     <CustomThemeContext.Provider value={customThemeInitValue}>
        {children}
     </CustomThemeContext.Provider>
    )
}