import { AuthModel } from '../../type/response'

const AUTH_LOCAL_STORAGE_KEY = 'buoni-pass-k'

const getAuth = (): AuthModel | undefined => {
  if (!localStorage) {
    return
  }

  const localStorageValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
  if (!localStorageValue) {
    return
  }

  try {
    const auth: AuthModel = JSON.parse(localStorageValue) as AuthModel
    if (auth) {
      return auth
    }
  } catch (error) {
    console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
  }
}

const setAuth = (auth: AuthModel) => {
  if (!localStorage) {
    return
  }

  try {
    const localStorageValue = JSON.stringify(auth)
    localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, localStorageValue)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
  }
}

const removeAuth = () => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE REMOVE ERROR', error)
  }
}

export { getAuth, setAuth, removeAuth, AUTH_LOCAL_STORAGE_KEY }