import React, { useContext, useState } from "react";

import { Pencil, Download, Trash } from 'react-bootstrap-icons'

import Tooltip from "../primitives/Tooltip";
import { ThemeContext } from "styled-components";
import { useMediaQuery } from "react-responsive";
import FlexTableCell from "./TableCell";
import TableRow from "./TableRow";
import Txt from "../primitives/Txt";
import Flex from "../primitives/Flex";
import { ModalContext } from "../../context/ModalContext";
import { toastType } from "../../type/enum";
import { Coupon } from "../../type/models";
import { AppGeneralContext } from "../../context/AppGeneralContext";
import { getCouponById } from "../../api/CouponsCall";
import { columnsFlexDefinitions } from "./columnsDefinitions";
import { CustomerContext } from '../../context/CustomerContext';
import { useNavigate } from 'react-router-dom';
import { ActionsType } from '../../type/useReducer';
import { PAGE } from "../../pages";

interface CouponTableProps {
  data: Coupon[]
}

export const CouponTableBody = ({ data }: CouponTableProps) => {
  const { colors } = useContext(ThemeContext);
  const navigate = useNavigate()

  const getCoupon = async (id: string) => {
    const state = { couponId: id }
    navigate(`/${PAGE.coupons.path}/${PAGE.coupons.children.detail.path}`, { state })
  }
  
  return (
    <>
      {data.map((el) => {
        return (
          <Flex key={el.id}>
            <FlexTableCell flex={columnsFlexDefinitions.referralCode}>
              <Txt  marginLeft={4} display={'block'} textAlign={'start'}> {el.referralCode}</Txt>
            </FlexTableCell>
            <FlexTableCell flex={columnsFlexDefinitions.nameSurname}>
              <Txt> {el.customer?.name} {el.customer?.surname}</Txt>
            </FlexTableCell>
            <FlexTableCell flex={columnsFlexDefinitions.email}>
              <Txt >{el.customer?.email}</Txt>
            </FlexTableCell>
            <FlexTableCell flex={columnsFlexDefinitions.count} justifyContent={'flex-end'}>
              <Txt >{el.customer?.referredCount}</Txt>
            </FlexTableCell>
            <FlexTableCell flex={columnsFlexDefinitions.actions} justifyContent={'center'}>
              <Tooltip content={'Visualizza Coupon'}>
                <Pencil
                  onClick={() => getCoupon(el.id)}
                  cursor={'pointer'} color={colors.primaryText}
                />
              </Tooltip>
            </FlexTableCell>
          </Flex>
        );
      })}
    </>
  );
};
