
import styled from "styled-components";

import {
  ColorProps,
  SpaceProps,
  TypographyProps,
  space,
  color,
  typography,
  compose,
  variant,
  LayoutProps,
  layout,
  BorderProps,
  border,
  fontSize
} from "styled-system";

interface TextProps {
  variant?: 'success' | 'error' | 'link' | 'disabledLink'
}

const Txt = styled.span<TextProps & ColorProps & TypographyProps & SpaceProps & LayoutProps & BorderProps>`
  ${space}
  ${color}
    ${typography}
    ${layout}
    ${border}
    ${fontSize}
    

${props => compose(variant({
  variants: {

    link: {
      color: props.theme.colors.primaryText,
      fontWeight: 'bold',
      cursor: 'pointer',
    },

    success: {
      color: props.theme.colors.successText
    },
    error: {
      color: props.theme.colors.dangerText,
      fontSize: props.theme.fontSizes.sm
    },
    disabledLink: {
      color: props.theme.colors.primaryText,
      fontWeight: 'bold',
      cursor: 'default',
    }
  }
}))}
`;

export default Txt;
