export const BASE_URL = 'https://api.nbeaute.com'
// export const BASE_URL = 'https://localhost:7270'


export const AUTH_API_CONTROLLER= '/Auth'
export const CMS_API_CONTROLLER= '/Cms'
export const WEBSITE_URL= 'https://www.nbeaute.com/'
export const FORM_URL= 'https://passaparola.nbeaute.com/'
export const WAmessagePre= "P A S S A P A R O L A%0ACiao,%0Ail tuo Passaparola è la miglior pubblicità. Sappiamo quanto ci apprezzi, abbiamo pensato a te per far conoscere la Nouvelle Beauté a chi ami!%0AEcco come funziona:%0A-Invia questo link a 2 amiche/amici:%0A"
export const WAmessagePost= '%0A%0A- Fissando il loro appuntamento entro 1 mese beneficeranno gratuitamente del nostro Trattamento Benvenuto (valore di Fr. 120.-).%0A- Ti alleghiamo la descrizione del trattamento Benvenuto: condividilo con loro, potranno scegliere se fare viso o corpo.%0A-Per ringraziarti, quando entrambe avranno fatto il loro ingresso, riceverai un credito di Fr. 50.- da spendere su tutti i nostri servizi.%0A- Ricordati che, perchè tutte possiate beneficiare dei vantaggi legati a questa iniziativa,  entrambe le tue amiche devono fare il loro trattamento -> entro 1 mese.%0ASe hai delle domande scrivici qui, siamo a tua disposizione!%0AUn caro saluto :)%0AMaura e Veronica'
export const adventIcon= '/advent-calendar.png'
export const logo= '/NBLogoAvvento.png'
export const defaultimg= '/defaultimg.jpg'
