import { Dispatch, FC, SetStateAction, createContext, useCallback, useContext, useEffect, useReducer, useState } from "react";
import { WithChildren } from "./AuthContext";
import { useDebounce } from "../utils";
import { Coupon, Order } from "../type/models";
import { PagerInitialState } from "../utils/const/initialValue";
import { Pager } from "../type/response";
import { ModalContext } from "./ModalContext";
import { ListCouponsRequest, ListOrdersRequest } from "../type/request";
import { AppGeneralContext } from "./AppGeneralContext";
import { toastType } from "../type/enum";
import { CouponActions, OrderActions, couponInit, couponInitState, couponReducer, orderInit, orderInitState, orderReducer } from "../type/useReducer";
import { getCouponList } from "../api/CouponsCall";
import { getOrderList } from "../api/OrdersCall";

interface OrderContextProps {
    page: number | undefined;
    setPage: (page: number | undefined) => void;
    getOrdersList: () => Promise<void>
    order: Order
    orderDispatch: Dispatch<OrderActions>
    ordersList: Order[]
    setOrdersList: Dispatch<SetStateAction<Order[]>>
    nameToSearch: string | undefined,
    setNameToSearch: Dispatch<SetStateAction<string | undefined>>
    surnameToSearch: string | undefined,
    setSurnameToSearch: Dispatch<SetStateAction<string | undefined>>
    prodToSearch: string | undefined,
    setProdToSearch: Dispatch<SetStateAction<string | undefined>>
    orderNumberToSearch: string | undefined,
    setOrderNumberToSearch: Dispatch<SetStateAction<string | undefined>>
    pager: Pager,
    setPager: Dispatch<SetStateAction<Pager>>
    binAction: boolean,
    setBinAction: Dispatch<SetStateAction<boolean>>
}

const OrderContextInit: OrderContextProps = {
    page: undefined,
    setPage: () => { },
    getOrdersList: () => new Promise<void>(() => { }),
    order: orderInit,
    orderDispatch: () => { },
    ordersList: [],
    setOrdersList: () => { },
    nameToSearch: '',
    setNameToSearch: () => { },
    surnameToSearch: '',
    setSurnameToSearch: () => { },
    prodToSearch: '',
    setProdToSearch: () => { },
    orderNumberToSearch: '',
    setOrderNumberToSearch: () => { },
    binAction: false,
    setBinAction: () => { },
    pager: PagerInitialState,
    setPager: () => { }
};

export const OrderContext =
    createContext<OrderContextProps>(OrderContextInit);

export const OrderContextProvider: FC<WithChildren> = ({ children }) => {
    const { setLoadingModal, closeModal } = useContext(ModalContext)
    const { addToast } = useContext(AppGeneralContext);
    const [pager, setPager] = useState<Pager>(PagerInitialState)
    const [page, setPage] = useState<number | undefined>(undefined);
    const [binAction, setBinAction] = useState<boolean>(false)
    const [nameToSearch, setNameToSearch] = useState<string | undefined>("");
    const [surnameToSearch, setSurnameToSearch] = useState<string | undefined>("");
    const [prodToSearch, setProdToSearch] = useState<string | undefined>("");
    const [orderNumberToSearch, setOrderNumberToSearch] = useState<string | undefined>("");

    const [ordersList, setOrdersList] = useState<Order[]>([])
    const [order, orderDispatch] = useReducer(orderReducer, orderInit, orderInitState)

    const searchSurnameParams = useDebounce(surnameToSearch, 200);
    const searchNameParams = useDebounce(nameToSearch, 200);
    const searchProdParams = useDebounce(prodToSearch, 200);
    const searchOrderNumberParams = useDebounce(orderNumberToSearch, 200);

    const modalDelay = 300

    useEffect(() => {
        getOrdersList()
    }, [searchNameParams, searchSurnameParams, searchProdParams, searchOrderNumberParams, page]);

    const getOrdersList = useCallback(async () => {
        setLoadingModal()
        const req: ListOrdersRequest = {
            pageNum: page ?? 1,
            pageSize: 10,
            name: searchNameParams ?? '',
            surname: searchSurnameParams ?? '',
            title: searchProdParams ?? '',
            orderNumber: searchOrderNumberParams ?? '',
            orderDirection: "desc",
            orderBy: "Date"
        }

        const resp = await getOrderList(req)
        if (!resp.response || !resp.isSuccess) {
            closeModal(modalDelay)
            addToast(toastType.error, 'Errore durante il caricamento dei dati')
            return
        }
        setBinAction(false)
        closeModal(modalDelay)
        setOrdersList(resp.response.data)
        setPager(resp.response.pager)
    }, [searchSurnameParams, searchNameParams, searchProdParams, searchOrderNumberParams, page])

    useEffect(() => {
        setPage(1)
    }, [searchSurnameParams, searchNameParams, searchProdParams, searchOrderNumberParams]);

    const orderContextInitValue: OrderContextProps = {
        page,
        setPage,

        pager,
        setPager,

        binAction,
        setBinAction,

        nameToSearch,
        setNameToSearch,

        surnameToSearch,
        setSurnameToSearch,

        prodToSearch,
        setProdToSearch,

        orderNumberToSearch,
        setOrderNumberToSearch,

        getOrdersList,

        order,
        orderDispatch,

        ordersList,
        setOrdersList
    };

    return (
        <OrderContext.Provider value={orderContextInitValue}>
            {children}
        </OrderContext.Provider>
    );
};
