import styled from "styled-components";
import {flexDirection,FlexDirectionProps,FlexboxProps, SpaceProps,space,textAlign,TextAlignProps,FlexProps,flex,display,DisplayProps } from "styled-system";

 const Ul = styled.ul<SpaceProps&TextAlignProps&FlexProps &DisplayProps & FlexboxProps &FlexDirectionProps>`
 ${space}
 ${textAlign}   
 ${flex}
 ${display}
 ${flexDirection}
list-style: none;
margin-block-start: 0px;
margin-block-end: 0px;


 
 `

 export default Ul