import { useContext } from "react";

import { Pencil, Trash } from 'react-bootstrap-icons'

import Tooltip from "../primitives/Tooltip";
import { ThemeContext } from "styled-components";
import FlexTableCell from "./TableCell";
import Txt from "../primitives/Txt";
import Flex from "../primitives/Flex";
import { ModalContext } from "../../context/ModalContext";
import { ModalTypeEnum, toastType } from "../../type/enum";
import { Product } from "../../type/models";
import { AppGeneralContext } from "../../context/AppGeneralContext";
import { columnsFlexDefinitions } from "./columnsDefinitions";
import { useNavigate } from 'react-router-dom';
import { PAGE } from "../../pages";
import { deleteProduct } from "../../api/ProductsCall";
import { ProductContext } from "../../context/ProductContext";
interface ProductsTableProps {
  data: Product[]
}

export const ProductsTableBody = ({ data }: ProductsTableProps) => {
  const { colors } = useContext(ThemeContext);
  const { getProductsList, ProductDispatch } = useContext(ProductContext)
  const navigate = useNavigate()
  const { addToast } = useContext(AppGeneralContext)
  const { setLoadingModal, openConfirmModal, closeModal } = useContext(ModalContext)

  const getProduct = async (id: string) => {
    const state = { productId: id }
    navigate(`/${PAGE.prods.path}/${PAGE.prods.children.detail.path}`, { state })
  }

  const deleteMethod = async (id: string) => {
    setLoadingModal()
    const resp = await deleteProduct(id)
    if (!resp.response || !resp.isSuccess) {
      closeModal()
      addToast(toastType.error, 'Errore nella cancellazione')
      return
    }
    closeModal()
    addToast(toastType.success, 'Cancellazione avvenuta con successo')
    getProductsList()
  }

  return (
    <>
      {data.map((el) => {
        return (
          <Flex key={el.id}>
            <FlexTableCell flex={columnsFlexDefinitions.date}>
              <Txt marginLeft={4} display={'block'} textAlign={'start'}> {el.dateString}</Txt>
            </FlexTableCell>

            <FlexTableCell flex={columnsFlexDefinitions.title}>
              <Txt>{el.title}</Txt>
            </FlexTableCell>

            <FlexTableCell flex={columnsFlexDefinitions.brandName}>
              <Txt>{el.brandName}</Txt>
            </FlexTableCell>

            <FlexTableCell flex={columnsFlexDefinitions.price}>
              <Txt>{el.price}</Txt>
            </FlexTableCell>

            <FlexTableCell flex={columnsFlexDefinitions.discountedPrice}>
              <Txt>{el.discountedPrice}</Txt>
            </FlexTableCell>

            <FlexTableCell flex={columnsFlexDefinitions.quantity}>
              <Txt>{el.quantity}</Txt>
            </FlexTableCell>

            <FlexTableCell flex={columnsFlexDefinitions.actions} justifyContent={'space-around'}>
              <Tooltip content={'Visualizza Prodotto'}>
                <Pencil
                  onClick={() => getProduct(el.id)}
                  cursor={'pointer'} color={colors.primaryText}
                />
              </Tooltip>

              <Tooltip content={'Elimina Prodotto'}>
                <Trash
                  onClick={() => openConfirmModal(
                    'Elimina', `Sei sicuro di voler cancellare definitivamente il prodotto ${el.title}?`,
                    ModalTypeEnum.message, () => deleteMethod(el.id))}
                  cursor={'pointer'} color={colors.primaryText}
                />
              </Tooltip>
            </FlexTableCell>
          </Flex>
        );
      })}
    </>
  );
};
