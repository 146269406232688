import { Outlet } from "react-router-dom";
import Flex from "../components/primitives/Flex";
import * as Tooltip from "@radix-ui/react-tooltip";
import { CustomerContextProvider } from "../context/CustomerContext";


export const CustomerMainBody = () => {
  return (
    <CustomerContextProvider>
      <Outlet />
    </CustomerContextProvider>
  );
};
