import styled from "styled-components";
import {
  ColorProps,
  SpaceProps,
  WidthProps,
  compose,
  space,
  variant,
  width,
  color,

 
} from "styled-system";



export interface ButtonProps {
  variant?: "primary" | "success" | "danger" | "warning"|"switcher"|"login"|"social";
  theme: any;
}


 const Button = styled.button<ButtonProps & ColorProps & SpaceProps & WidthProps&{$outlined?:boolean}>`

  
  font-size:1em ;
  width: 12em;
  padding: 0.6em 1em;
  border-radius: 10px;
  font-family: 'DM Sans', sans-serif;
 
  :disabled {
    background-color: ${(props) => props.theme.colors.primaryBgActive};
    color: ${(props) => props.theme.colors.primaryText};
    cursor:default;
  }
  :disabled:hover {
    background-color: ${(props) => props.theme.colors.primaryBgHover};
    color: ${(props) => props.theme.colors.primaryText};
    cursor:default;
  }
  

  ${props=>compose(
    variant({
      variants: {
        primary: {
          backgroundColor: props.$outlined? "transparent": props.theme.colors.primaryBgActive,
          color: props.$outlined? props.theme.colors.primaryBorder: props.theme.colors.primaryTextContrast,
          active: props.theme.colors.primaryBgActive,
          borderColor: props.theme.colors.primaryBorder,
          "&:hover": {
            backgroundColor: props.theme.colors.primarySolidHover,
          },
        },
        success: {
          backgroundColor: props.$outlined? 'transparent': props.theme.colors.successSolid,
          color:props.$outlined? props.theme.colors.successText: props.theme.colors.successTextContrast,
          active: props.theme.colors.successBgActive,
          borderColor: props.theme.colors.successBorder,
          "&:hover": {
            backgroundColor: props.$outlined?props.theme.colors.successBg : props.theme.colors.successSolidHover,
          },
        },
        danger: {
          backgroundColor: props.$outlined? 'transparent': props.theme.colors.dangerSolid,
          color:props.$outlined? props.theme.colors.dangerText:props.theme.colors.dangerTextContrast,
          active: props.theme.colors.dangerBgActive,
          borderColor: props.theme.colors.dangerBorder,
          "&:hover": {
            backgroundColor: props.$outlined?props.theme.colors.dangerBg : props.theme.colors.dangerSolidHover,
            borderColor:props.$outlined?props.theme.colors.dangerBorderHover:props.theme.colors.dangerBorder,
          },
        },
        warning: {
          backgroundColor: props.theme.colors.warningSolid,
          color: props.theme.colors.warningTextContrast,
          active: props.theme.colors.warningBgActive,
          borderColor: props.theme.colors.warningBorder,
          "&:hover": {
            backgroundColor: props.theme.colors.warningSolidHover,
          },

        },
        switcher:{
          backgroundColor: props.theme.colors.indigo7,
          color: props.theme.colors.indigo9,
          active: props.theme.colors.indigo10,
          borderColor: props.theme.colors.indigo10,
          "&:hover": {
            backgroundColor: props.theme.colors.indigo8,
          },
        },

        login:{
          backgroundColor:props.theme.colors.primarySolid,
          color: props.theme.colors.primaryBase,
          active:props.theme.colors.primaryBgActive,
          borderColor:props.theme.colors.primaryBorder,
          "&:hover":{
            backgroundColor:props.theme.colors.primarySolidHover,
            borderColor:props.theme.colors.primaryBorderHover
          }
        },
        social:{
          backgroundColor:props.theme.colors.primaryBase,
          color: props.theme.colors.textColor,
          active:props.theme.colors.primaryBgActive,
          borderColor:props.theme.colors.primaryBorder,
          fontWeight:'bold',
          "&:hover":{
            backgroundColor:props.theme.colors.primarySolidHover,
            borderColor:props.theme.colors.primaryBorderHover,
            color:props.theme.colors.primaryBase,
          }
        }
      },
 }),
    width,
    space,
    color,
  
     
   )}
 
`;

export default Button


