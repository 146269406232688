import {
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  createContext,

  useContext,

  useState,
} from "react";
import axios from "axios";
import { AuthModel } from "../type/response/authResponses";
import { getAuthCall, verifyTokenCall } from "../api/AuthCall";
import { UserModel } from "../type/models";
import * as authHelpers from '../utils/function/authHelpers'

import { postRegisterCall } from "../api/registerCall";
import { AppGeneralContext } from "./AppGeneralContext";
import { toastType } from "../type/enum";
import { useNavigate } from "react-router-dom";

import { ModalContext } from "./ModalContext";
import { PAGE } from "../pages";



export type WithChildren = {
  children?: ReactNode;
};

export interface AuthContextType {
  auth: AuthModel | undefined;
  setAuth: (auth: AuthModel | undefined) => void;
  currentUser: UserModel | undefined;
  setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>;
  logout: () => void;
  authContextCall: (user: UserModel) => Promise<boolean>;
  registerContextCall: (user: UserModel) => void;

}

const authContextInitState: AuthContextType = {
  auth: authHelpers.getAuth(),
  setAuth: () => { },
  currentUser: undefined,
  setCurrentUser: () => { },
  logout: () => { },
  authContextCall: (_: UserModel) => new Promise<boolean>(() => { }),
  registerContextCall: (_: UserModel) => { },
};

export const AuthContext = createContext<AuthContextType>(authContextInitState);

export const AuthContextProvider: FC<WithChildren> = ({ children }) => {
  const navigate = useNavigate();
  const { addToast } = useContext(AppGeneralContext);
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelpers.getAuth());

  const { closeModal } = useContext(ModalContext)
  const [currentUser, setCurrentUser] = useState<UserModel | undefined>();

  const saveAuth = (auth: AuthModel | undefined) => {
    setAuth(auth)
    if (auth) {
      authHelpers.setAuth(auth)
    } else {
      authHelpers.removeAuth()
    }
  }

  const authContextCall = async (user: UserModel) => {

    const resp = await getAuthCall(user);

    if (!resp.response || !resp.isSuccess) {
      closeModal()
      saveAuth(undefined);
      addToast(toastType.error, "Email o password non corretti.");
      return false
    }

    if (!resp.response.jwtToken) {
      return false
    }
    const api_token = resp.response.jwtToken
    const response = await verifyTokenCall(api_token)

    if (!response.response || !resp.isSuccess) {
      closeModal()
      saveAuth(undefined);
      addToast(toastType.error, `Qualcosa è andato storto: ${response.response?.errorMessage}`);
      return false
    }

    axios.defaults.headers.common["Authorization"] =
      "Bearer " + resp.response?.jwtToken;

    const authUser: AuthModel = {
      jwt: resp.response?.jwtToken,
    };
    closeModal()
    setCurrentUser({ ...response.response.data })
    saveAuth(authUser);

    return true
  };



  const registerContextCall = async (user: UserModel) => {
    if (user) {

      const resp = await postRegisterCall(user);
      if (resp.isSuccess) {
        addToast(toastType.success, "Registration successful. Verify your email to login");
        navigate(`/${PAGE.login.path}`);
      } else {

        setAuth(undefined);

        addToast(toastType.error, resp.error ?? '');

      }

    } else {
      //TODO openErrorModal
    }
  };


  const logout = () => {
    setAuth(undefined);
    setCurrentUser(undefined);
  };

  const authContextInitialValue: AuthContextType = {
    auth,
    setAuth,
    currentUser,
    setCurrentUser,
    logout,
    authContextCall,
    registerContextCall,

  };

  return (
    <AuthContext.Provider value={authContextInitialValue}>
      {children}
    </AuthContext.Provider>
  );
};
