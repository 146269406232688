import React from 'react'
import Flex from '../primitives/Flex'
import Box from '../primitives/Box'
import Button from '../primitives/Button'
interface ActionButtons{
    onAbort:()=>void,
    onConfirm:()=>void
}
export const ActionButton = ({onAbort,onConfirm}:ActionButtons) => {
  return (
    <Flex justifyContent={'end'} my={'20px'}>
        {/* <Box width={"md"}>
            <Button $outlined type='button' variant='danger' onClick={()=>{onAbort()}}><b>Annulla</b></Button>
        </Box> */}
        <Box width={"md"}>
        <Button $outlined type='submit' variant='success' onClick={()=>{onConfirm()}}><b>Salva modifiche</b></Button>
        </Box>
    </Flex>
  )
}
