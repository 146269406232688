import React, { useContext, useEffect, useMemo, useReducer, useState } from 'react'
import Box from '../components/primitives/Box'
import Flex from '../components/primitives/Flex'
import { AppGeneralContext } from '../context/AppGeneralContext';
import Txt from '../components/primitives/Txt';
import Label from '../components/primitives/Label';
import { Input } from '../components/atoms';
import { PagerComponent } from '../components/primitives';
import { getCustomerById, upsertCustomer } from '../api/CustomersCall';
import { useDebounce } from '../utils';
import TextArea from '../components/atoms/TextArea'
import { useFormik } from 'formik'

import { ThemeContext } from 'styled-components';
import { ModalContext } from '../context/ModalContext';
import { toastType } from '../type/enum';
import { Customer } from '../type/models';
import { ApiSimpleResponse, CustomerResponse, Pager } from '../type/response';
import { PagerInitialState } from '../utils/const/initialValue';
import Form from '../components/primitives/Form'
import { customerInit, customerReducer, customerInitState } from '../type/useReducer';
import { customerValidationSchema } from '../utils/formikSchemas';
import { ActionButton } from '../components';
import { CustomerContext } from '../context/CustomerContext';
import { useLocation } from 'react-router-dom';
import { adventIcon } from '../utils/const/apiConst';


export const AdventCustomerDetail = () => {
  const { setCustomersList, customersList, setPage, page } = useContext(CustomerContext)
  const location = useLocation()
  const customerId = location.state.customerId as string | undefined
  const { addToast } = useContext(AppGeneralContext);
  const [nameToSearch, setNameToSearch] = useState<string | undefined>("");
  const [surnameToSearch, setSurnameToSearch] = useState<string | undefined>("");
  const [emailToSearch, setEmailToSearch] = useState<string | undefined>("");
  const [phoneToSearch, setPhoneToSearch] = useState<string | undefined>("");
  const searchSurnameParams = useDebounce(surnameToSearch, 200);
  const searchNameParams = useDebounce(nameToSearch, 200);
  const searchEmailParams = useDebounce(emailToSearch, 200);
  const searchPhoneParams = useDebounce(phoneToSearch, 200);
  const { colors } = useContext(ThemeContext);
  const [customerDetail, setCustomerDetail] = useState<Customer>(customerInit)
  const [pager, setPager] = useState<Pager>(PagerInitialState)
  const [binAction, setBinAction] = useState<boolean>(false)
  const [copiedToClipboard, setCopiedToClipboard] = useState<boolean>(false)
  const [customerState, customerDispatch] = useReducer(customerReducer, customerInit, customerInitState)

  const { setLoadingModal, closeModal, openApiModalMessage } = useContext(ModalContext);

  const modalDelay = 300

  const formik = useFormik<Customer>({
    enableReinitialize: true,
    initialValues: customerDetail ?? customerInit,
    validationSchema: customerValidationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setLoadingModal()
      setSubmitting(true)
      let resp: ApiSimpleResponse<CustomerResponse>
      resp = await upsertCustomer(customerDetail?.id ?? '', { ...values })
      if (!resp.response || !resp.isSuccess) {
        openApiModalMessage(resp)
        return
      }
      closeModal(modalDelay)
      setSubmitting(false)
      setCustomerDetail(resp.response.data)
    }
  })


  const getCustomer = async (id: string) => {
    setLoadingModal()
    const resp = await getCustomerById(id)
    if (!resp.response || !resp.isSuccess) {
      closeModal(modalDelay)
      addToast(toastType.error, 'Errore durante il caricamento dei dati')
      return
    }
    closeModal(modalDelay)
    setCustomerDetail(resp.response.data)
  }

  useEffect(() => {
    if (!!customerId) {
      getCustomer(customerId)
    }
  }, []);


  useEffect(() => {
    setPage(1)
  }, [searchSurnameParams, searchNameParams, searchEmailParams, searchPhoneParams]);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    setCustomerDetail((prevValues) => ({ ...prevValues, [name]: checked }));
  };

  return (
    <Flex flexDirection={"column"} width={"90%"} height={"100vh"}>
      <Flex justifyContent={"center"} alignItems={"center"} height={"12%"} mt={'20px'} mb={'20px'}>
        <Txt>
          <h2>Anagrafica di {customerDetail?.name} {customerDetail?.surname}</h2>
        </Txt>
      </Flex>
      <Form width={'100%'} onSubmit={formik.handleSubmit} flexDirection={'column'} justifyContent={'space-around'}>
        <Flex flexWrap={'wrap'} justifyContent={'center'}>
          <Flex width={'100%'} flexDirection={'row'} justifyContent={'space-evenly'}>
            <Box width={"lg"} marginRight={[0, 3]} textAlign={"left"}>
              <Label pl={2}>Nome</Label>
              <Input
                {...formik.getFieldProps("nome")}
                id='name'
                variant={"normal"}
                value={formik.values.name}
                type="text"
                name={"name"}
                formik={formik}
                placeholder="Nome"
              />
              {formik.errors.name && formik.touched.name && (
                <Txt variant="error" id="name">
                  {formik.errors.name}
                </Txt>
              )}
            </Box>
            <Box width={"lg"} marginRight={[0, 3]} textAlign={"left"}>
              <Label pl={2}>Cognome</Label>
              <Input
                {...formik.getFieldProps("surname")}
                id='surname'
                variant={"normal"}
                value={formik.values.surname}
                type="text"
                name={"surname"}
                formik={formik}
                placeholder="Cognome"
              />
              {formik.errors.surname && formik.touched.surname && (
                <Txt variant="error" id="surname">
                  {formik.errors.surname}
                </Txt>
              )}
            </Box>
          </Flex>
          <Flex width={'100%'} flexDirection={'row'} justifyContent={'space-evenly'} mt={'20px'}>
            <Box width={"lg"} marginRight={[0, 3]} textAlign={"left"}>
              <Label pl={2}>Numero di Telefono</Label>
              <Input
                {...formik.getFieldProps("phoneNumber")}
                id='phoneNumber'
                variant={"normal"}
                value={formik.values.phoneNumber}
                type="text"
                name={"phoneNumber"}
                formik={formik}
                placeholder="Numero Telefono"
              />
              {formik.errors.phoneNumber && formik.touched.phoneNumber && (
                <Txt variant="error" id="phoneNumber">
                  {formik.errors.phoneNumber}
                </Txt>
              )}
            </Box>
            <Box width={"lg"} marginRight={[0, 3]} textAlign={"left"}>
              <Label pl={2}>E-mail</Label>
              <Input
                {...formik.getFieldProps("email")}
                id='email'
                variant={"normal"}
                value={formik.values.email}
                type="text"
                name={"email"}
                formik={formik}
                placeholder="Indirizzo e-mail"
              />
              {formik.errors.email && formik.touched.email && (
                <Txt variant="error" id="email">
                  {formik.errors.email}
                </Txt>
              )}
            </Box>
          </Flex>
          <Flex width={"lg"} marginTop={20} alignItems={'center'}>
              <Txt fontWeight={700}>
                <div className="form-group-checkbox mb-0">
                  <input type="checkbox" id="waitingListAdventCalendar" name="waitingListAdventCalendar"
                    className="form-checkbox"
                    checked={formik.initialValues.waitingListAdventCalendar}
                    onChange={handleCheckboxChange} />
                  <label className="">Calendario dell'avvento</label>
                </div>
              </Txt>
              <img src={adventIcon} className='icon'></img>
            </Flex>
          <Flex flexDirection={'row'} width={"100%"} justifyContent={'center'} mt={'30px'}>
            <Box minWidth={"100%"} height={'100%'} marginRight={[0, 3]} textAlign={"left"}>
              <Label pl={2}>Note</Label>
              <TextArea
                {...formik.getFieldProps("note")}
                id='note'
                name={"note"}
                value={formik.values.note}
                placeholder='Note aggiuntive'
                onChange={formik.handleChange}
                onBlur={() => formik.setFieldTouched('note', true)}
              />
              {formik.errors.note && formik.touched.note && (
                <Txt variant="error" id="note">
                  {formik.errors.note}
                </Txt>
              )}

            </Box>
          </Flex>
        </Flex>
        <ActionButton onAbort={() => closeModal()} onConfirm={() => { }}></ActionButton>
      </Form >
    </Flex>
  )
}
