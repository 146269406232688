export const columnsFlexDefinitions = {
    adventCalendarIcon:0.25,
    name: 1,
    surname: 1,
    email: 1,
    phoneNumber: 1,
    referrer: 1,
    actions: 1,
    referralCode: 1,
    nameSurname: 1,
    count: 1,
    title: 1,
    price: 1,
    discountedPrice: 1,
    brandName: 1,
    date: 1,
    quantity: 1,
    orderNumber: 0.75,
    orderDiscountedPrice: 0.75
}