import styled from "styled-components";
import { LayoutProps, WidthProps,layout,width,flexbox,FlexboxProps } from "styled-system";


const Form = styled.form<&LayoutProps& WidthProps&FlexboxProps>`
${layout}
${width}
${flexbox}
    display: flex;
`

export default Form