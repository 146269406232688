import styled from "styled-components";
import { SpaceProps,space,textAlign,TextAlignProps } from "styled-system";

 const Label = styled.label<SpaceProps&TextAlignProps>`
 ${space}
 ${textAlign}   
 & a{
    color:${(props) => props.theme.colors.asideMenuText}
 }
 &:hover a{
    text-decoration: underline;
}
 `

 export default Label