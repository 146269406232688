import React, { useContext } from "react";

import { Pencil, Download, Trash } from 'react-bootstrap-icons'

import Tooltip from "../primitives/Tooltip";
import { ThemeContext } from "styled-components";
import { useMediaQuery } from "react-responsive";
import FlexTableCell from "./TableCell";
import TableRow from "./TableRow";
import Txt from "../primitives/Txt";
import Flex from "../primitives/Flex";
import { MOBILE_TABLE_TEMPLATE, TABLE_TEMPLATE } from "../../utils/const/utilsConst";
import { ModalContext } from "../../context/ModalContext";
import { ModalTypeEnum, toastType } from "../../type/enum";
import { Customer } from "../../type/models";
import { createLink, createProjectLink } from "../../utils";
import { download } from "../../utils/function/download";
import { AppGeneralContext } from "../../context/AppGeneralContext";
import { deleteCustomer } from "../../api/CustomersCall";
import { CMS_API_CONTROLLER } from "../../utils/const/apiConst";
import { columnsFlexDefinitions } from "./columnsDefinitions";
import { CustomerContext } from "../../context/CustomerContext";

interface ReferredTableProps {
  data: Customer[]
  getList: () => void
}

export const ReferredTableBody = ({ getList, data }: ReferredTableProps) => {
  const { colors } = useContext(ThemeContext);
  const isMobile = useMediaQuery({ maxWidth: 500 });
  const { addToast } = useContext(AppGeneralContext)
  const { openModalWithChildren, openApiModalMessage, setLoadingModal, openConfirmModal, closeModal } = useContext(ModalContext)

  const deleteMethod = async (id: string) => {
    setLoadingModal()
    const resp = await deleteCustomer(id)
    if (!resp.response || !resp.isSuccess) {
      closeModal()
      addToast(toastType.error, 'Errore nella cancellazione')
      return
    }
    closeModal()
    addToast(toastType.success, 'Cancellazione avvenuta con successo')
    getList()
  }

  return (
    <>
      {data?.map((el) => {
        return (
          <Flex key={el.id}>
            <FlexTableCell flex={columnsFlexDefinitions.name}>
              <Txt marginLeft={4} display={'block'} textAlign={'start'}> {el.name}</Txt>
            </FlexTableCell>

            <FlexTableCell flex={columnsFlexDefinitions.surname}>
              <Txt>{el.surname}</Txt>
            </FlexTableCell>

            <FlexTableCell flex={columnsFlexDefinitions.email}>
              <Txt>{el.email}</Txt>
            </FlexTableCell>

            <FlexTableCell flex={columnsFlexDefinitions.phoneNumber}>
              <Txt>{el.phoneNumber}</Txt>
            </FlexTableCell>
          </Flex>
        );
      })}
    </>
  );
};
