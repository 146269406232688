export enum toastType{
    success,
    error,
    warning
}

export enum TitleEnum {
    nowLoading = 'Caricamento...',
    error = 'Errore',
    success = 'Successo'
}



export enum ModalTypeEnum {
    detail= 'detail',
    edit= 'edit',
    loading='loading',
    error='error',
    success='success',
    message='message'
}

