import React from "react";
import styled from "styled-components";
import {
  ColorProps,
  SpaceProps,
  WidthProps,
  compose,
  variant,
  width,
  space,
  color,
} from "styled-system";
import { FormikContextType } from "formik";
import Flex from "../primitives/Flex";

const FileInput = styled.input`
  position: absolute;
  opacity: 0;
  z-index: -10;
  top: 0;
  left: 0;
`;
interface uploadContainerProps {
  variant?:
    | "primary"
    | "success"
    | "danger"
    | "warning"
    | "switcher"
    | "login"
    | "social";
}
const UploadContainer = styled.label <uploadContainerProps & ColorProps & SpaceProps & WidthProps>`
  font-size: 1em;
  text-align: center;
  display:block;
  padding: 0.6em 1em;
  border-radius: 10px;
  cursor: pointer;
  border-style:outset;
  border-width:2px;
  line-height:normal;
  :disabled {
    background-color: ${(props) => props.theme.colors.primaryBgActive};
    color: ${(props) => props.theme.colors.primaryText};
    cursor: default;
  }
  :disabled:hover {
    background-color: ${(props) => props.theme.colors.primaryBgHover};
    color: ${(props) => props.theme.colors.primaryText};
    cursor: default;
  }

  ${(props) =>
    compose(
      variant({
        variants: {
          primary: {
            backgroundColor: props.theme.colors.primaryBgActive,
            color: props.theme.colors.primaryTextContrast,
            active: props.theme.colors.primaryBgActive,
            borderColor: props.theme.colors.primaryBorder,
            "&:hover": {
              backgroundColor: props.theme.colors.primarySolidHover,
            },
          },
          success: {
            backgroundColor: props.theme.colors.successSolid,
            color: props.theme.colors.successTextContrast,
            active: props.theme.colors.successBgActive,
            borderColor: props.theme.colors.successBorder,
            "&:hover": {
              backgroundColor: props.theme.colors.successSolidHover,
            },
          },
          danger: {
            backgroundColor: props.theme.colors.dangerSolid,
            color: props.theme.colors.dangerTextContrast,
            active: props.theme.colors.dangerBgActive,
            borderColor: props.theme.colors.dangerBorder,
            "&:hover": {
              backgroundColor: props.theme.colors.dangerSolidHover,
            },
          },
          warning: {
            backgroundColor: props.theme.colors.warningSolid,
            color: props.theme.colors.warningTextContrast,
            active: props.theme.colors.warningBgActive,
            borderColor: props.theme.colors.warningBorder,
            "&:hover": {
              backgroundColor: props.theme.colors.warningSolidHover,
            },
          },
          switcher: {
            backgroundColor: props.theme.colors.indigo7,
            color: props.theme.colors.indigo9,
            active: props.theme.colors.indigo10,
            borderColor: props.theme.colors.indigo10,
            "&:hover": {
              backgroundColor: props.theme.colors.indigo8,
            },
          },

          login: {
            backgroundColor: props.theme.colors.primarySolid,
            color: props.theme.colors.primaryBase,
            active: props.theme.colors.primaryBgActive,
            borderColor: props.theme.colors.primaryBorder,
            "&:hover": {
              backgroundColor: props.theme.colors.primarySolidHover,
              borderColor: props.theme.colors.primaryBorderHover,
            },
          },
          social: {
            backgroundColor: props.theme.colors.primaryBase,
            color: props.theme.colors.textColor,
            active: props.theme.colors.primaryBgActive,
            borderColor: props.theme.colors.primaryBorder,
            fontWeight: "bold",
            "&:hover": {
              backgroundColor: props.theme.colors.primarySolidHover,
              borderColor: props.theme.colors.primaryBorderHover,
              color: props.theme.colors.primaryBase,
            },
          },
        },
      }),
      width,
      space,
      color
    )}
`;

interface UploadFileButtonProps {
  placeholder: string|JSX.Element;
  htmlId: string;
  variant:
    | "primary"
    | "success"
    | "danger"
    | "warning"
    | "switcher"
    | "login"
    | "social";
  formik: FormikContextType<any> | undefined;
  inputName: string;
  value?: File;
  inputRef: React.RefObject<HTMLInputElement> | null;
  acceptedFormat: string;
  setValue?:(_:any)=>void
}

export const UploadFileButton = ({
  htmlId,
  variant,
  placeholder,
  formik,
  inputName,
  value,
  inputRef,
  acceptedFormat,
  
}: UploadFileButtonProps) => {

  return (
    <UploadContainer
       onBlur={() => formik?.setFieldTouched(inputName, true)}
      htmlFor={htmlId}
      variant={variant}
    >
      <Flex flexDirection={'row'} alignItems={'center'} >

      {placeholder}
      </Flex>
      <FileInput
        name={inputName}
        onChange={  (e) => formik?.setFieldValue(inputName, e.currentTarget.files)}
        value={undefined}
        accept={acceptedFormat}
        id={htmlId}
        type="file"
        ref={inputRef}
      />
    </UploadContainer>
  );
};
