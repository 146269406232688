import { Order} from "../type/models"
import { ListOrdersRequest, OrdersRequest } from "../type/request"
import { BaseResponse, OrderResponse, PaginatedResponse } from "../type/response"
import { BASE_URL, CMS_API_CONTROLLER } from "../utils/const/apiConst"
import { deleteCall, getCall, postCall } from "./baseCall"

export const getOrderList = async (params: ListOrdersRequest) => {
    return await postCall<ListOrdersRequest, PaginatedResponse<Order>>(`${BASE_URL}${CMS_API_CONTROLLER}/ordersList`, params)
}
export const getOrderById = async (id: string) => {
    return await getCall<{}, OrderResponse>(`${BASE_URL}${CMS_API_CONTROLLER}/getOrderById?id=${id}`)
}
export const deleteOrder = async (id: string) => {
    return await deleteCall<BaseResponse>(`${BASE_URL}${CMS_API_CONTROLLER}/deleteOrder?id=${id}`)
}
export const upsertOrder = async (id: string, order: OrdersRequest) => {
    return await postCall<OrdersRequest, OrderResponse>(`${BASE_URL}${CMS_API_CONTROLLER}/upsertOrder`, order)
}