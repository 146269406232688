import React from "react";
import HeaderRow from "./HeaderRow";

import Txt from "../primitives/Txt";
import { useMediaQuery } from "react-responsive";
import FlexTableCell from "./TableCell";
import { MOBILE_TABLE_TEMPLATE, TABLE_TEMPLATE } from "../../utils/const/utilsConst";
import { columnsFlexDefinitions } from "./columnsDefinitions";

export const AdventTableHeading = () => {
  const isMobile = useMediaQuery({ maxWidth: 500 });
  return (
    <HeaderRow>
      <FlexTableCell flex={columnsFlexDefinitions.name}>
        <Txt textAlign={'start'} marginLeft={4}>Nome</Txt>
      </FlexTableCell>
      <FlexTableCell flex={columnsFlexDefinitions.surname}>
        <Txt>Cognome</Txt>
      </FlexTableCell>
      <FlexTableCell flex={columnsFlexDefinitions.email}>
        <Txt>E-mail</Txt>
      </FlexTableCell>
      <FlexTableCell flex={columnsFlexDefinitions.phoneNumber}>
        <Txt>Numero telefono</Txt>
      </FlexTableCell>
      <FlexTableCell justifyContent={"center"} flex={columnsFlexDefinitions.actions}>
        <Txt>Azioni</Txt>
      </FlexTableCell>
    </HeaderRow>
  );
};
