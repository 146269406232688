import { useContext, useEffect, useMemo } from 'react'
import Box from '../components/primitives/Box'
import Flex from '../components/primitives/Flex'
import { AppGeneralContext } from '../context/AppGeneralContext';
import { TableHeading, TableBody } from '../components/table';
import Txt from '../components/primitives/Txt';
import Label from '../components/primitives/Label';
import { Input } from '../components/atoms';
import { PlusCircle, Search, Trash } from 'react-bootstrap-icons';
import { PagerComponent } from '../components/primitives';
import Tooltip from '../components/primitives/Tooltip';
import { ThemeContext } from 'styled-components';
import { AdventCustomerContext } from '../context/AdventCustomerContext';
import { useNavigate } from 'react-router-dom';
import { ActionsType } from '../type/useReducer';
import { PAGE } from './pagesPath';
import { getAdventCustomersList } from '../api/CustomersCall';
import { AdventTableHeading } from '../components/table/AdventTableHeading';
import { AdventTableBody } from '../components/table/AdventTableBody';

export const AdventCustomersList = () => {
  const { isMobile } = useContext(AppGeneralContext);
  const navigate = useNavigate()
  const { colors } = useContext(ThemeContext);
  const { customerDispatch, binAction, setBinAction, pager, customersList, getAdventCalendarCustomersList, setNameToSearch, nameToSearch, setSurnameToSearch,
    surnameToSearch, setEmailToSearch, emailToSearch, setPhoneToSearch, phoneToSearch, setPage } = useContext(AdventCustomerContext)

  const showPagination = useMemo(() => {
    if (pager.totalPages < 1) {
      return <></>;
    }
    return (
      <Flex
        flexDirection={"row"}
        width={"100%"}
        justifyContent={"end"}
        height={'20%'}
      >
        <PagerComponent pager={pager} setPage={setPage} ></PagerComponent>
      </Flex>
    );
  }, [pager]);

  const newCustomerHandler = () => {
    const state = { isCreate: true }
    customerDispatch({ type: ActionsType.RESET_CUSTOMER })
    navigate(`/${PAGE.customers.path}/${PAGE.customers.children.detail.path}`, { state })
  };

  useEffect(() => {
    customerDispatch({ type: ActionsType.RESET_CUSTOMER })
  }, [])

  useEffect(() => {
    getAdventCalendarCustomersList()
  }, [getAdventCalendarCustomersList]);

  return (
    <Flex flexDirection={"column"} width={"90%"} height={"100vh"}>
      <Flex justifyContent={"center"} alignItems={"center"} height={"12%"} mt={'20px'} mb={'25px'}>
        <Txt>
          <h2>Lista d'attesa calendario dell'avvento</h2>
        </Txt>
      </Flex>
      <Flex width={"100%"} flexDirection={'row'} height={'10%'} marginBottom={3} justifyContent={'space-between'}>
        <Flex alignItems={"end"} width={'100%'} >
          {customersList.length === 0 && surnameToSearch === "" && nameToSearch === ""
            && emailToSearch === "" && phoneToSearch === "" && !binAction ? (
            <></>
          ) : (
            <>
              <Box width={"md"} marginRight={3} textAlign={"left"}>
                <Label pl={2}>Filtra per Nome</Label>
                <Input
                  leftIconBackground={colors.transparent}
                  clearable
                  icon={<Search color={colors.primaryText} />}
                  leftIcon
                  variant={"normal"}
                  value={nameToSearch}
                  type="text"
                  name={"Nome"}
                  formik={undefined}
                  onChangeCallback={(e) => setNameToSearch(e.target.value)}
                  onClear={() => setNameToSearch("")}
                  placeholder="Nome"
                />
              </Box>
              <Box width={"md"} marginRight={3} textAlign={"left"}>
                <Label pl={2}>Filtra per Cognome</Label>
                <Input
                  leftIconBackground={colors.transparent}
                  clearable
                  icon={<Search color={colors.primaryText} />}
                  leftIcon
                  variant={"normal"}
                  value={surnameToSearch}
                  type="text"
                  name={"Cognome"}
                  formik={undefined}
                  onChangeCallback={(e) => setSurnameToSearch(e.target.value)}
                  onClear={() => setSurnameToSearch("")}
                  placeholder="Cognome"
                />
              </Box>
              <Box width={"md"} marginRight={3} textAlign={"left"}>
                <Label pl={2}>Filtra per E-mail</Label>
                <Input
                  leftIconBackground={colors.transparent}
                  clearable
                  icon={<Search color={colors.primaryText} />}
                  leftIcon
                  variant={"normal"}
                  value={emailToSearch}
                  type="text"
                  name={"Email"}
                  formik={undefined}
                  onChangeCallback={(e) => setEmailToSearch(e.target.value)}
                  onClear={() => setEmailToSearch("")}
                  placeholder="Indirizzo e-mail"
                />
              </Box>
              <Box width={"md"} textAlign={"left"}>
                <Label pl={2}>Filtra per Telefono</Label>
                <Input
                  leftIconBackground={colors.transparent}
                  clearable
                  icon={<Search color={colors.primaryText} />}
                  leftIcon
                  variant={"normal"}
                  value={phoneToSearch}
                  type="text"
                  name={"Telefono"}
                  formik={undefined}
                  onChangeCallback={(e) => setPhoneToSearch(e.target.value)}
                  onClear={() => setPhoneToSearch("")}
                  placeholder="Numero telefono"
                />
              </Box>
            </>
          )}
          {(nameToSearch !== "" || surnameToSearch !== "" || emailToSearch !== "" || phoneToSearch !== "") &&
            <Box marginLeft={4} marginBottom={1}>
              <Trash size={'1.3em'} color={colors.primaryText}
                onClick={() => {
                  setSurnameToSearch(''); setNameToSearch(''); setEmailToSearch(''); setPhoneToSearch(''); setBinAction(true)
                }}
                cursor={'pointer'} /></Box>}
        </Flex>
        <Flex
          alignItems={"end"}
          justifyContent={"center"}
          height={"100%"}
        >
          <Tooltip content={"Aggiungi nuovo cliente"}>
            <PlusCircle
              size={'2.5em'}
              color={colors.primarySolidHover}
              cursor={"pointer"}
              onClick={() => {
                newCustomerHandler();
              }}
            />
          </Tooltip>
        </Flex>
      </Flex>
      <Flex
        flexDirection={"column"}
        justifyContent={"space-between"}
        height={"78%"}
        position={"relative"}
        width={'100%'}
      >
        {customersList.length > 0 ? (
          <Flex
            flexDirection={"column"}
            height={"80%"}
            marginTop={isMobile ? "80px" : "59px"}
            overflowY={"scroll"}
            width={"100%"}
          >
            <AdventTableHeading />
            <AdventTableBody data={customersList} />
          </Flex>) : (<>Mi dispiace, ma non ho trovato ciò che cerchi</>)}
        {showPagination}
      </Flex>
    </Flex>
  )
}
