import React, { useContext } from "react";
import Flex from "../primitives/Flex";
import { Input } from "../atoms";
import Box from "../primitives/Box";
import Txt from "../primitives/Txt";


import Form from "../primitives/Form";

import { AuthContext } from "../../context/AuthContext";

import * as Yup from "yup";
import { useFormik } from "formik";
import YupPassword from "yup-password";

import { LoginModel } from "../../type/models";
import { ModalTypeEnum } from "../../type/enum";
import { ModalContext } from "../../context/ModalContext";
import Button from "../primitives/Button";
import { Lock, Mailbox } from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import { PAGE } from "../../pages";




YupPassword(Yup);
const initialLoginSchema = Yup.object().shape({
  email: Yup.string()
    .email("Wrong email format")
    .required("Please complete this required field."),
  password: Yup.string()
    .password()
    .required()
    .min(
      3,
      "Password must contain 3 or more characters"
    )
    .minLowercase(0)
    .minUppercase(0)
    .minNumbers(0)
    .minSymbols(0),
});


export const FormLogin = () => {
  const { authContextCall, currentUser } = useContext(AuthContext);
  const { setLoadingModal } = useContext(ModalContext)
  const navigate = useNavigate()
  const { openApiModalMessage } = useContext(ModalContext);

  const initialLogin: LoginModel = {
    email: currentUser ? currentUser.email : "",
    password: "",
  };

  const formik = useFormik<LoginModel>({
    initialValues: initialLogin,
    validationSchema: initialLoginSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setLoadingModal()
      setSubmitting(true);

      const resp = await authContextCall(values);

      if (resp) {
        formik.resetForm()
        navigate(`/${PAGE.customers.path}/${PAGE.customers.children.list.path}`)
      } else {
        return
      }
      setSubmitting(false);
    },
  });

  return (
    <Form
      justifyContent={"space-around"}
      onSubmit={formik.handleSubmit}
      flexDirection={"column"}
      height={"100%"}
      alignItems={"center"}
    >
      <Txt>
        <h1>Login</h1>
      </Txt>

      <Flex
        width={"80%"}
        height={"30%"}
        flexDirection={"column"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        {/* email */}
        <Input
          {...formik.getFieldProps("email")}
          variant={"normal"}
          name={"email"}
          placeholder={"Email"}
          formik={formik}
          type="email"

          leftIcon
          icon={<Mailbox />}
          value={formik.values.email}
        />
        {formik.errors.email && formik.touched.email && (
          <Txt id="email" variant="error">
            {formik.errors.email}
          </Txt>
        )}
        {/* password */}
        <Input
          {...formik.getFieldProps("password")}
          variant={"password"}
          name={"password"}
          placeholder={"Password"}
          formik={formik}
          iconSize={'2em'}

          leftIcon
          icon={<Lock />}
          value={formik.values.password}

        />
        {formik.errors.password && formik.touched.password && (
          <Txt variant="error" id="password">
            {formik.errors.password}
          </Txt>
        )}


      </Flex>
      <Box>
        <Button
          disabled={
            formik.isSubmitting ||
            !formik.touched ||
            !formik.isValid ||
            !formik.values.email ||
            !formik.values.password
          }
          type="submit"
          width={"md"}
          variant={"login"}
        >
          Login
        </Button>
      </Box>
    </Form>
  );
};
