import React, { useCallback, useContext, useEffect, useMemo, useReducer, useState } from 'react'
import Box from '../components/primitives/Box'
import Flex from '../components/primitives/Flex'
import { AppGeneralContext } from '../context/AppGeneralContext';
import Txt from '../components/primitives/Txt';
import Label from '../components/primitives/Label';
import { Input } from '../components/atoms';
import { Search, Trash } from 'react-bootstrap-icons';
import { PagerComponent } from '../components/primitives';
import { getCustomerById, postReferredList, upsertCustomer } from '../api/CustomersCall';
import { useDebounce } from '../utils';
import TextArea from '../components/atoms/TextArea'
import { useFormik } from 'formik'

import { ThemeContext } from 'styled-components';
import { ModalContext } from '../context/ModalContext';
import { toastType } from '../type/enum';
import { ListCustomersRequest } from '../type/request';
import { Customer } from '../type/models';
import { ApiSimpleResponse, CustomerResponse, Pager } from '../type/response';
import { PagerInitialState } from '../utils/const/initialValue';
import Form from '../components/primitives/Form'
import { customerInit, customerReducer, customerInitState } from '../type/useReducer';
import { customerValidationSchema } from '../utils/formikSchemas';
import { ActionButton } from '../components';
import { ReferredTableHeading } from '../components/table/ReferredTableHeading';
import { ReferredTableBody } from '../components/table/ReferredTableBody';
import { CustomerContext } from '../context/CustomerContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { FORM_URL, WAmessagePost, WAmessagePre, adventIcon } from '../utils/const/apiConst';
import { PAGE } from './pagesPath';


export const CustomerDetail = () => {
  const { setCustomersList, customersList, setPage, page } = useContext(CustomerContext)
  const location = useLocation()
  const customerId = location.state.customerId as string | undefined
  const { isMobile, addToast } = useContext(AppGeneralContext);
  const [nameToSearch, setNameToSearch] = useState<string | undefined>("");
  const [surnameToSearch, setSurnameToSearch] = useState<string | undefined>("");
  const [emailToSearch, setEmailToSearch] = useState<string | undefined>("");
  const [phoneToSearch, setPhoneToSearch] = useState<string | undefined>("");
  const searchSurnameParams = useDebounce(surnameToSearch, 200);
  const searchNameParams = useDebounce(nameToSearch, 200);
  const searchEmailParams = useDebounce(emailToSearch, 200);
  const searchPhoneParams = useDebounce(phoneToSearch, 200);
  const { colors } = useContext(ThemeContext);
  const [customerDetail, setCustomerDetail] = useState<Customer>(customerInit)
  const [pager, setPager] = useState<Pager>(PagerInitialState)
  const [binAction, setBinAction] = useState<boolean>(false)
  const [copiedToClipboard, setCopiedToClipboard] = useState<boolean>(false)
  const [customerState, customerDispatch] = useReducer(customerReducer, customerInit, customerInitState)

  const { setLoadingModal, closeModal, openApiModalMessage } = useContext(ModalContext);

  const modalDelay = 300

  const formik = useFormik<Customer>({
    enableReinitialize: true,
    initialValues: customerDetail ?? customerInit,
    validationSchema: customerValidationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setLoadingModal()
      setSubmitting(true)
      let resp: ApiSimpleResponse<CustomerResponse>
      resp = await upsertCustomer(customerDetail?.id ?? '', { ...values })
      if (!resp.response || !resp.isSuccess) {
        openApiModalMessage(resp)
        return
      }
      closeModal(modalDelay)
      setSubmitting(false)
      getCustomer(resp.response.data.id)
    }
  })


  const getCustomer = async (id: string) => {
    setLoadingModal()
    const resp = await getCustomerById(id)
    if (!resp.response || !resp.isSuccess) {
      closeModal(modalDelay)
      addToast(toastType.error, 'Errore durante il caricamento dei dati')
      return
    }
    closeModal(modalDelay)
    setCustomerDetail(resp.response.data)
  }

  useEffect(() => {
    if (!!customerId) {
      getCustomer(customerId)
      getReferredList()
    }
  }, []);

  const getReferredList = useCallback(async () => {
    setLoadingModal()
    const req: ListCustomersRequest = {
      pageNum: page ?? 1,
      pageSize: 10,
      name: searchNameParams ?? '',
      surname: searchSurnameParams ?? '',
      email: searchEmailParams ?? '',
      phone: searchPhoneParams ?? '',
      referrerId: customerId
    }
    const resp = await postReferredList(req)
    if (!resp.response || !resp.isSuccess) {
      closeModal(modalDelay)
      addToast(toastType.error, 'Errore durante il caricamento dei dati')
      return
    }
    setBinAction(false)
    closeModal(modalDelay)
    setCustomersList(resp.response.data)
    setPager(resp.response.pager)
  }, [searchSurnameParams, searchNameParams, searchEmailParams, searchPhoneParams, page])

  useEffect(() => {
    getReferredList()
  }, [getReferredList]);

  useEffect(() => {
    setPage(1)
  }, [searchSurnameParams, searchNameParams, searchEmailParams, searchPhoneParams]);

  const showPagination = useMemo(() => {
    if (pager.totalPages < 1) {
      return <></>;
    }
    return (
      <Flex
        flexDirection={"row"}
        width={"100%"}
        justifyContent={"end"}
        height={'20%'}
      >
        <PagerComponent pager={pager} setPage={setPage}></PagerComponent>
      </Flex>
    );
  }, [pager]);

  const referralUrl = `${FORM_URL}${customerDetail?.coupon?.referralCode}`;

  const copyToClipboard = (textToWrite: string) => {
    navigator.clipboard.writeText(textToWrite).then(() => {
      setCopiedToClipboard(true)
    })
    setCopiedToClipboard(true)
  }

  useEffect(() => {
    if (!copiedToClipboard) {
      return
    }
    const timeout = setTimeout(() => {
      setCopiedToClipboard(false);
    }, 1500);
    return () => clearInterval(timeout)
  }, [copiedToClipboard])

  return (
    <Flex flexDirection={"column"} width={"90%"} height={"100vh"}>
      <Flex justifyContent={"center"} alignItems={"center"} height={"12%"} mt={'20px'} mb={'20px'}>
        <Txt>
          <h2>Anagrafica di {formik.values.name} {formik.values.surname}</h2>
        </Txt>
      </Flex>
      <Form width={'100%'} onSubmit={formik.handleSubmit} flexDirection={'column'} justifyContent={'space-around'}>
        <Flex flexWrap={'wrap'} justifyContent={'center'}>
          <Flex width={'100%'} flexDirection={'row'} justifyContent={'space-evenly'}>
            <Box width={"lg"} marginRight={[0, 3]} textAlign={"left"}>
              <Label pl={2}>Nome</Label>
              <Input
                {...formik.getFieldProps("name")}
                id='name'
                variant={"normal"}
                value={formik.values.name}
                type="text"
                name={"name"}
                formik={formik}
                placeholder="Nome"
              />
              {formik.errors.name && formik.touched.name && (
                <Txt variant="error" id="name">
                  {formik.errors.name}
                </Txt>
              )}
            </Box>
            <Box width={"lg"} marginRight={[0, 3]} textAlign={"left"}>
              <Label pl={2}>Cognome</Label>
              <Input
                {...formik.getFieldProps("surname")}
                id='surname'
                variant={"normal"}
                value={formik.values.surname}
                type="text"
                name={"surname"}
                formik={formik}
                placeholder="Cognome"
              />
              {formik.errors.surname && formik.touched.surname && (
                <Txt variant="error" id="surname">
                  {formik.errors.surname}
                </Txt>
              )}
            </Box>
            <Box width={"lg"} marginRight={[0, 3]} textAlign={"left"}>
              <Label pl={2}>Numero di Telefono</Label>
              <Input
                {...formik.getFieldProps("phoneNumber")}
                id='phoneNumber'
                variant={"normal"}
                value={formik.values.phoneNumber}
                type="text"
                name={"phoneNumber"}
                formik={formik}
                placeholder="Numero Telefono"
              />
              {formik.errors.phoneNumber && formik.touched.phoneNumber && (
                <Txt variant="error" id="phoneNumber">
                  {formik.errors.phoneNumber}
                </Txt>
              )}
            </Box>
          </Flex>
          <Flex width={'100%'} flexDirection={'row'} justifyContent={'space-evenly'} mt={'20px'}>
            <Box width={"lg"} marginRight={[0, 3]} textAlign={"left"}>
              <Label pl={2}>E-mail</Label>
              <Input
                {...formik.getFieldProps("email")}
                id='email'
                variant={"normal"}
                value={formik.values.email}
                type="text"
                name={"email"}
                formik={formik}
                placeholder="Indirizzo e-mail"
              />
              {formik.errors.email && formik.touched.email && (
                <Txt variant="error" id="email">
                  {formik.errors.email}
                </Txt>
              )}
            </Box>
            <Flex width={"lg"} marginRight={[0, 3]} alignItems={'center'}>
              <Txt fontWeight={700}> Passaparola da:  {!!customerDetail?.referrer ? `${customerDetail?.referrer?.name} ${customerDetail?.referrer?.surname}` : "Nessuno"}</Txt>
            </Flex>
            <Flex width={"lg"} marginRight={[0, 3]} alignItems={'center'}>
              <Txt fontWeight={700}>Quantità clienti referenziati: {customerDetail?.referredCount}</Txt>
            </Flex>
            <Flex width={"lg"} marginRight={[0, 3]} alignItems={'center'}>
              <Txt fontWeight={700}>
                <div className="form-group-checkbox mb-0">
                  <input type="checkbox" id="waitingListAdventCalendar" name="waitingListAdventCalendar"
                    className="form-checkbox"
                    checked={formik.values.waitingListAdventCalendar}
                    onChange={formik.handleChange} />
                  <label className="">Calendario dell'avvento</label>
                </div>
              </Txt>
              <img src={adventIcon} className='icon'></img>
            </Flex>
          </Flex>
          {customerDetail?.coupon?.id ?
            <Flex width={'100%'} flexDirection={'row'} justifyContent={'space-evenly'} mt={'20px'}>
              <Flex cursor='pointer' width={"lg"} marginRight={[0, 3]} alignItems={'center'} justifyContent={'center'}>
                <Txt fontWeight={700} onClick={() => copyToClipboard(referralUrl)}>
                  {copiedToClipboard ? 'Copiato!' : `Link da condividere: ${referralUrl}`}
                </Txt>
              </Flex>
              <Flex width={"lg"} marginRight={[0, 3]} alignItems={'center'}>
                <Txt><a target='_blank' href={`${'https://wa.me:/'}${customerDetail?.phoneNumber}/?text=${WAmessagePre} ${referralUrl} ${WAmessagePost}`}> Clicca qui per inviare il Coupon via whatsapp </a></Txt>
              </Flex>
            </Flex>
            :
            <Txt fontWeight={700}> L'utente non ha coupon associati</Txt>
          }
          <Flex flexDirection={'row'} width={"100%"}>
            <Flex flexDirection={'column'} width={"100%"} justifyContent={'start'} mt={'20px'}>
              <Flex width={"100%"}>
                <div>
                  <label><b>Orari di preferenza per essere contattata/o:</b></label>
                </div>
              </Flex>
              <Flex width={"lg"} marginRight={[0, 3]} alignItems={'center'}>
                <div>
                  <input type="checkbox" id="preferenceMorning" name="preferenceMorning"
                    className="form-checkbox"
                    checked={formik.values.preferenceMorning}
                    disabled
                    onChange={formik.handleChange}
                  />
                  <label className="">Mattina</label>
                </div>
              </Flex>
              <Flex width={"lg"} marginRight={[0, 3]} alignItems={'center'}>
                <div>
                  <input type="checkbox" id="preferenceAfternoon" name="preferenceAfternoon"
                    className="form-checkbox"
                    checked={formik.values.preferenceAfternoon}
                    disabled
                    onChange={formik.handleChange} />
                  <label className="">Pomeriggio</label>
                </div>
              </Flex>
              <Flex width={"lg"} marginRight={[0, 3]} alignItems={'center'}>
                <div className="form-group-checkbox mb-0">
                  <input type="checkbox" id="preferenceEvening" name="preferenceEvening"
                    className="form-checkbox"
                    checked={formik.values.preferenceEvening}
                    disabled
                    onChange={formik.handleChange} />
                  <label className="">Sera</label>
                </div>
              </Flex>
            </Flex>
            <Flex flexDirection={'column'} width={"100%"} mt={'20px'}>
              <Flex width={"100%"}>
                <div>
                  <label><b>Esito lead:</b></label>
                </div>
              </Flex>
              <Flex width={"lg"} marginRight={[0, 3]}>
                <div className="form-group-checkbox mb-0">
                  <input type="checkbox" id="appointmentTaken" name="appointmentTaken"
                    className="form-checkbox"
                    checked={formik.values.appointmentTaken}
                    onChange={formik.handleChange} />
                  <label className="">Appuntamento fissato</label>
                </div>
              </Flex>
              <Flex width={"lg"} marginRight={[0, 3]}>
                <div className="form-group-checkbox mb-0">
                  <input type="checkbox" id="lostLead" name="lostLead"
                    className="form-checkbox"
                    checked={formik.values.lostLead}
                    onChange={formik.handleChange} />
                  <label className="">Lead Perso </label>
                </div>
              </Flex>
            </Flex>
          </Flex>
          <Flex flexDirection={'row'} width={"100%"} justifyContent={'center'} mt={'30px'}>
            <Box minWidth={"100%"} height={'100%'} marginRight={[0, 3]} textAlign={"left"}>
              <Label pl={2}>Note</Label>
              <TextArea
                {...formik.getFieldProps("note")}
                id='note'
                name={"note"}
                value={formik.values.note}
                placeholder='Note aggiuntive'
                onChange={formik.handleChange}
                onBlur={() => formik.setFieldTouched('note', true)}
              />
              {formik.errors.note && formik.touched.note && (
                <Txt variant="error" id="note">
                  {formik.errors.note}
                </Txt>
              )}

            </Box>
          </Flex>
        </Flex>
        <ActionButton onAbort={() => closeModal()} onConfirm={() => { }}></ActionButton>
      </Form >

      {customerDetail?.referredCount === 0 ?
        <Flex justifyContent={"center"} alignItems={"center"} height={"12%"} mt={'30px'} mb={'20px'}>
          <Txt>
            <h3>Non ci sono clienti referenziati da visualizzare</h3>
          </Txt>
        </Flex>
        :
        <>
          <Flex justifyContent={"center"} alignItems={"center"} height={"12%"} mt={'30px'} mb={'20px'}>
            <Txt>
              <h3>Lista clienti referenziati</h3>
            </Txt>
          </Flex>
          <Flex width={"100%"} flexDirection={'row'} height={'10%'} marginBottom={3} justifyContent={'space-between'}>
            <Flex alignItems={"end"} width={'100%'} >
              {customersList.length === 0 && surnameToSearch === "" && nameToSearch === ""
                && emailToSearch === "" && phoneToSearch === "" && !binAction ? (
                <></>
              ) : (
                <>
                  <Box width={"md"} marginRight={3} textAlign={"left"}>
                    <Label pl={2}>Filtra per Nome</Label>
                    <Input
                      leftIconBackground={colors.transparent}
                      clearable
                      icon={<Search color={colors.primaryText} />}
                      leftIcon
                      variant={"normal"}
                      value={nameToSearch}
                      type="text"
                      name={"Nome"}
                      formik={undefined}
                      onChangeCallback={(e) => setNameToSearch(e.target.value)}
                      onClear={() => setNameToSearch("")}
                      placeholder="Nome"
                    />
                  </Box>
                  <Box width={"md"} marginRight={3} textAlign={"left"}>
                    <Label pl={2}>Filtra per Cognome</Label>
                    <Input
                      leftIconBackground={colors.transparent}
                      clearable
                      icon={<Search color={colors.primaryText} />}
                      leftIcon
                      variant={"normal"}
                      value={surnameToSearch}
                      type="text"
                      name={"Cognome"}
                      formik={undefined}
                      onChangeCallback={(e) => setSurnameToSearch(e.target.value)}
                      onClear={() => setSurnameToSearch("")}
                      placeholder="Cognome"
                    />
                  </Box>
                  <Box width={"md"} marginRight={3} textAlign={"left"}>
                    <Label pl={2}>Filtra per E-mail</Label>
                    <Input
                      leftIconBackground={colors.transparent}
                      clearable
                      icon={<Search color={colors.primaryText} />}
                      leftIcon
                      variant={"normal"}
                      value={emailToSearch}
                      type="text"
                      name={"Email"}
                      formik={undefined}
                      onChangeCallback={(e) => setEmailToSearch(e.target.value)}
                      onClear={() => setEmailToSearch("")}
                      placeholder="Indirizzo e-mail"
                    />
                  </Box>
                  <Box width={"md"} textAlign={"left"}>
                    <Label pl={2}>Filtra per Telefono</Label>
                    <Input
                      leftIconBackground={colors.transparent}
                      clearable
                      icon={<Search color={colors.primaryText} />}
                      leftIcon
                      variant={"normal"}
                      value={phoneToSearch}
                      type="text"
                      name={"Telefono"}
                      formik={undefined}
                      onChangeCallback={(e) => setPhoneToSearch(e.target.value)}
                      onClear={() => setPhoneToSearch("")}
                      placeholder="Numero telefono"
                    />
                  </Box>
                </>
              )}
              {(nameToSearch !== "" || surnameToSearch !== "" || emailToSearch !== "" || phoneToSearch !== "") &&
                <Box marginLeft={4} marginBottom={1}>
                  <Trash size={'1.3em'} color={colors.primaryText}
                    onClick={() => {
                      setSurnameToSearch(''); setNameToSearch(''); setEmailToSearch(''); setPhoneToSearch(''); setBinAction(true)
                    }}
                    cursor={'pointer'} /></Box>}
            </Flex>
          </Flex>

          <Flex
            flexDirection={"column"}
            justifyContent={"space-between"}
            height={"78%"}
            position={"relative"}
            width={'100%'}
          >
            {customersList.length > 0 ? (
              <Flex
                flexDirection={"column"}
                height={"80%"}
                marginTop={isMobile ? "80px" : "59px"}
                overflowY={"scroll"}
                width={"100%"}
              >
                <ReferredTableHeading />
                <ReferredTableBody getList={getReferredList} data={customersList} />
              </Flex>) : (<>Mi dispiace, ma non ho trovato ciò che cerchi</>)}
            {showPagination}
          </Flex>
        </>
      }
    </Flex>
  )
}
