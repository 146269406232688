import {
  gray,
  blue,
  red,
  green,
  grayDark,
  blueDark,
  redDark,
  greenDark,
  yellow,
  yellowDark,
  indigoDark,
  indigo,
  slate,
  slateDark,
  whiteA,
  violet,
  violetDark,
  purple,
  purpleDark,
  crimson,
  crimsonDark
} from "@radix-ui/colors";


const defaultSizes={
  xxs:'30px',
  xs:'50px',
  xsm:'80px',
  sm:'100px',
  md:'200px',
  lg:'300px'
}
// Create your theme
export const theme = {
  name:'light',
  
  
  colors: {
    ...gray,
    ...blue,
    ...red,
    ...green,
    ...yellow,
    ...indigo,
    ...slate,
    ...whiteA,
    ...crimson,
    //create Aliases

    primaryBase: indigo.indigo1,
    primaryBgSubtle: indigo.indigo2,
    primaryBg: indigo.indigo3,
    primaryBgHover: indigo.indigo4,
    primaryBgActive: crimson.crimson3,
    primaryLine: indigo.indigo6,
    primaryBorder: crimson.crimson4,
    primaryBorderHover: crimson.crimson12,
    primarySolid: crimson.crimson12,
    primarySolidHover: crimson.crimson12,
    primaryText: indigo.indigo11,
    primaryTextContrast: indigo.indigo12,

    //Success
    successBase: green.green1,
    successBgSubtle: green.green2,
    successBg: green.green3,
    successBgHover: green.green4,
    successBgActive: green.green5,
    successLine: green.green6,
    successBorder: green.green7,
    successBorderHover: green.green8,
    successSolid: green.green9,
    successSolidHover: green.green10,
    successText: green.green11,
    successTextContrast: green.green12,

    //Warning

    warningBase: yellow.yellow1,
    warningBgSubtle: yellow.yellow2,
    warningBg: yellow.yellow3,
    warningBgHover: yellow.yellow4,
    warningBgActive: yellow.yellow5,
    warningLine: yellow.yellow6,
    warningBorder: yellow.yellow7,
    warningBorderHover: yellow.yellow8,
    warningSolid: yellow.yellow9,
    warningSolidHover: yellow.yellow10,
    warningText: yellow.yellow11,
    warningTextContrast: yellow.yellow12,

    //Danger

    dangerBase: red.red1,
    dangerBgSubtle: red.red2,
    dangerBg: red.red3,
    dangerBgHover: red.red4,
    dangerBgActive: red.red5,
    dangerLine: red.red6,
    dangerBorder: red.red7,
    dangerBorderHover: red.red8,
    dangerSolid: red.red9,
    dangerSolidHover: red.red10,
    dangerText: red.red11,
    dangerTextContrast: red.red12,
  
    //indigo

    indigo1: indigo.indigo1,
    indigo2: indigo.indigo2,
    indigo3: indigo.indigo3,
    indigo4: indigo.indigo4,
    indigo5: indigo.indigo5,
    indigo6: indigo.indigo6,
    indigo7: indigo.indigo7,
    indigo8: indigo.indigo8,
    indigo9: indigo.indigo9,
    indigo10: indigo.indigo10,
    indigo11: indigo.indigo11,
    indigo12: indigo.indigo12,


    violet1: violet.violet1,
    violet2: violet.violet2,
    violet3: violet.violet3,
    violet4: violet.violet4,
    violet5: violet.violet5,
    violet6: violet.violet6,
    violet7: violet.violet7,
    violet8: violet.violet8,
    violet9: violet.violet9,
    violet10: violet.violet10,
    violet11: violet.violet11,
    violet12: violet.violet12,


    //bodyBg Color
    background:indigo.indigo1,

    asideBg:crimson.crimson4,
    asideMenuText:crimson.crimson12 ,
    textColor: slate.slate12,
    transparent:whiteA.whiteA1
  },
  fontSizes:{
    sm:'12px',
    md:'16px',
    lg:'18px'

  },

  sizes:defaultSizes
 
 
};

// Create your dark theme

export const darkTheme = {
  name:'dark',
  colors: {
    ...grayDark,
    ...blueDark,
    ...redDark,
    ...greenDark,
    ...yellowDark,
    ...indigoDark,
    ...slateDark,
    ...whiteA,
    ...crimsonDark,

    //create Aliases

    primaryBase: indigoDark.indigo1,
    primaryBgSubtle: indigoDark.indigo2,
    primaryBg: indigoDark.indigo3,
    primaryBgHover: indigoDark.indigo4,
    primaryBgActive: crimson.crimson12,
    primaryLine: indigoDark.indigo6,
    primaryBorder: indigoDark.indigo7,
    primaryBorderHover: indigoDark.indigo8,
    primarySolid: indigoDark.indigo9,
    primarySolidHover: crimson.crimson4,
    primaryText: indigoDark.indigo11,
    primaryTextContrast: indigoDark.indigo12,

    successBase: greenDark.green1,
    successBgSubtle: greenDark.green2,
    successBg: greenDark.green3,
    successBgHover: greenDark.green4,
    successBgActive: greenDark.green5,
    successLine: greenDark.green6,
    successBorder: greenDark.green7,
    successBorderHover: greenDark.green8,
    successSolid: greenDark.green9,
    successSolidHover: greenDark.green10,
    successText: greenDark.green11,
    successTextContrast: greenDark.green12,

    // repeat for all steps

    warningBase: yellowDark.yellow1,
    warningBgSubtle: yellowDark.yellow2,
    warningBg: yellowDark.yellow3,
    warningBgHover: yellowDark.yellow4,
    warningBgActive: yellowDark.yellow5,
    warningLine: yellowDark.yellow6,
    warningBorder: yellowDark.yellow7,
    warningBorderHover: yellowDark.yellow8,
    warningSolid: yellowDark.yellow9,
    warningSolidHover: yellowDark.yellow10,
    warningText: yellowDark.yellow11,
    warningTextContrast: yellowDark.yellow12,
    // repeat for all steps

    dangerBase: redDark.red1,
    dangerBgSubtle: redDark.red2,
    dangerBg: redDark.red3,
    dangerBgHover: redDark.red4,
    dangerBgActive: redDark.red5,
    dangerLine: redDark.red6,
    dangerBorder: redDark.red7,
    dangerBorderHover: redDark.red8,
    dangerSolid: redDark.red9,
    dangerSolidHover: redDark.red10,
    dangerText: redDark.red11,
    dangerTextContrast: redDark.red12,

    // indigo
    indigo1: indigoDark.indigo1,
    indigo2: indigoDark.indigo2,
    indigo3: indigoDark.indigo3,
    indigo4: indigoDark.indigo4,
    indigo5: indigoDark.indigo5,
    indigo6: indigoDark.indigo6,
    indigo7: indigoDark.indigo7,
    indigo8: indigoDark.indigo8,
    indigo9: indigoDark.indigo9,
    indigo10: indigoDark.indigo10,
    indigo11: indigoDark.indigo11,
    indigo12: indigoDark.indigo12,

    violet1: violetDark.violet1,
    violet2: violetDark.violet2,
    violet3: violetDark.violet3,
    violet4: violetDark.violet4,
    violet5: violetDark.violet5,
    violet6: violetDark.violet6,
    violet7: violetDark.violet7,
    violet8: violetDark.violet8,
    violet9: violetDark.violet9,
    violet10: violetDark.violet10,
    violet11: violetDark.violet11,
    violet12: violetDark.violet12,

    //bodyBg Color
    background:indigoDark.indigo1,
    asideBg:indigo.indigo12,
    asideMenuText: indigo.indigo2,
    transparent:whiteA.whiteA1,

    textColor: slateDark.slate12
  },
  fontSizes:{
    sm:'12px',
    md:'16px',
    lg:'16px'

  },


  
//sizing for button
sizes:defaultSizes
 
};

//*********** USAGE EXAMPLES */

// Use the colors in your styles

//   const Button = styled.button`
//     background-color: ${(props) => props.theme.colors.blue4};
//     color: ${(props) => props.theme.colors.blue11};
//     border-color: ${(props) => props.theme.colors.blue7};
//*********************************************************
// You can access to colors also with Aliases:
//
// example:
//     color: ${props => props.theme.colors.blue9};
//     border: 2px solid ${props => props.theme.colors.accentBorder};
//************* *************************************************/
//     &:hover {
//       background-color: ${(props) => props.theme.colors.blue5};
//       border-color: ${(props) => props.theme.colors.blue8};
//     }
//   `;

// Wrap your app with the theme provider and apply your theme to it
//   export default function App() {
//     return (
//       <ThemeProvider theme={theme}>
//         <Button> radix colorz </Button>
//       </ThemeProvider>
//     );
//   }
