import React, { useContext, useMemo, useRef } from 'react'

import { Link } from 'react-router-dom'

import { useOutsideAlerter } from '../../../utils/hooks/useOutsideClick';
import { AppGeneralContext } from '../../../context/AppGeneralContext';
import Flex from '../../primitives/Flex';
import Ul from '../../primitives/Ul';
import Label from '../../primitives/Label';
import { PAGE, PageDef } from '../../../pages';
import Txt from '../../primitives/Txt';
import Box from '../../primitives/Box';

interface AsideProps {
    collapse: boolean
}

export const Aside = ({ collapse }: AsideProps) => {
    const { setCloseAside, aside, setAside } = useContext(AppGeneralContext)
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef, aside.isOpen, () => setCloseAside(), 'asideMenu');


    const handleClickAside = () => { setCloseAside() }

    const buildAside: (pageRoot: PageDef, pageRootRoute: string) => JSX.Element[]
        = (pageRoot: PageDef, pageRootRoute: string) => {
            return Object.entries(pageRoot)
                .filter(([_, value]) => value.showInAside)
                .map(([_, value]) => {
                    const subRoute = `${pageRootRoute}/${value.path}`

                    return (
                        <Box key={subRoute}>
                            <Label onClick={() => handleClickAside()} ><Link to={subRoute}>{value.title}</Link></Label>
                            {!!value.children && <Box ml={3}>
                                {buildAside(value.children, subRoute)}
                            </Box>}
                        </Box>
                    )
                })
        }

    const ShowMenu = useMemo(() => buildAside(PAGE, ''), [PAGE])

    return (
        <Flex marginTop={3} flexDirection={'column'} ref={wrapperRef} p={3} height={'100%'}>
            <Ul flexDirection={'column'} display={'flex'} textAlign={'start'} padding={3}>
                {ShowMenu}
            </Ul>
        </Flex>
    )
}

