
export interface PageDef {
    [key: string]: {
        title: string,
        path: string,
        showInAside: boolean,
        children?: PageDef
    }
}

export const PAGE = {
    login: {
        title: 'login',
        path: 'login',
        showInAside: false
    },
    customers: {
        title: 'Contatti',
        path: 'customers',
        showInAside: true,
        children: {
            list: {
                title: 'Lista Clienti',
                path: 'listaContatti',
                showInAside: true
            },
            detail: {
                title: 'Anagrafica Cliente',
                path: 'anagraficaCliente',
                showInAside: false
            },
        }
    },
    coupons: {
        title: 'Coupons',
        path: 'coupons',
        showInAside: true,
        children: {
            list: {
                title: 'Lista Coupons',
                path: 'listaCoupons',
                showInAside: true
            },
            detail: {
                title: 'Anagrafica Coupon',
                path: 'anagraficaCoupon',
                showInAside: false
            },
        }
    },
    orders: {
        title: 'Ordini',
        path: 'orders',
        showInAside: true,
        children: {
            list: {
                title: 'Lista Ordini',
                path: 'listaOrdini',
                showInAside: true
            },
            detail: {
                title: 'Anagrafica Ordine',
                path: 'anagraficaOrdine',
                showInAside: false
            },
        }
    },
    adventCustomers: {
        title: 'Calendario Avvento',
        path: 'adventCustomers',
        showInAside: false,
        children: {
            list: {
                title: 'Lista di attesa',
                path: 'listaAvventoContatti',
                showInAside: false
            },
            detail: {
                title: 'Anagrafica Cliente',
                path: 'anagraficaCliente',
                showInAside: false
            },
        }
    },
    prods: {
        title: 'Prodotti e Servizi',
        path: 'productsServices',
        showInAside: true,
        children: {
            list: {
                title: 'Lista prodotti e servizi',
                path: 'listaProdottiServizi',
                showInAside: true
            },
            detail: {
                title: 'Anagrafica Prodotto',
                path: 'anagraficaProdottoServizio',
                showInAside: false
            },
        }
    },
    notFound: {
        title: 'Oooops...Something went wrong',
        path: '404',
        showInAside: false
    }
}