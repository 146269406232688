import { UserModel } from "../../type/models"
import { CustomersRequest } from "../../type/request"
import { AuthModel, Pager } from "../../type/response"

export const authInitialValue: AuthModel = {
    jwt: '',

}

export const userInitValue: UserModel = {
    id: '',
    email: 'admin@demo.it',
    password: 'qaz'
}


export const PagerInitialState: Pager = {
    pageNum: 1,
    pageSize: 0,
    totalItems: 0,
    totalPages: 0,
    links: []
}

