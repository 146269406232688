import { useContext, useEffect, useMemo } from 'react'
import Box from '../components/primitives/Box'
import Flex from '../components/primitives/Flex'
import { AppGeneralContext } from '../context/AppGeneralContext';
import Txt from '../components/primitives/Txt';
import Label from '../components/primitives/Label';
import { Input } from '../components/atoms';
import { Search, Trash } from 'react-bootstrap-icons';
import { PagerComponent } from '../components/primitives';
import { ThemeContext } from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { ActionsType } from '../type/useReducer';
import { OrderContext } from '../context/OrderContext';
import { OrderTableHeading } from '../components/table/OrderTableHeading';
import { OrderTableBody } from '../components/table/OrderTableBody';
import { deleteOrder } from '../api/OrdersCall';
import { ModalContext } from '../context/ModalContext';
import { toastType } from '../type/enum';

export const OrdersList = () => {
  const { isMobile } = useContext(AppGeneralContext);
  const navigate = useNavigate()
  const { colors } = useContext(ThemeContext);
  const { binAction, setBinAction, pager, setNameToSearch, nameToSearch, setSurnameToSearch,
    surnameToSearch, setProdToSearch, prodToSearch, setPage,
    orderNumberToSearch, setOrderNumberToSearch, orderDispatch, ordersList, getOrdersList } = useContext(OrderContext)
  const { setLoadingModal, openConfirmModal, closeModal } = useContext(ModalContext)
  const { addToast } = useContext(AppGeneralContext);

  const showPagination = useMemo(() => {
    if (pager.totalPages < 1) {
      return <></>;
    }
    return (
      <Flex
        flexDirection={"row"}
        width={"100%"}
        justifyContent={"end"}
        height={'20%'}
      >
        <PagerComponent pager={pager} setPage={setPage}></PagerComponent>
      </Flex>
    );
  }, [pager]);

  useEffect(() => {
    orderDispatch({ type: ActionsType.RESET_ORDER })
  }, [])

  useEffect(() => {
    getOrdersList()
  }, [getOrdersList]);

  return (
    <Flex flexDirection={"column"} width={"90%"} height={"100vh"}>
      <Flex justifyContent={"center"} alignItems={"center"} height={"12%"} mt={'20px'} mb={'25px'}>
        <Txt>
          <h2>Lista Ordini</h2>
        </Txt>
      </Flex>
      <Flex width={"100%"} flexDirection={'row'} height={'10%'} marginBottom={3} justifyContent={'space-between'}>
        <Flex alignItems={"end"} width={'100%'} >
          {ordersList.length === 0 && surnameToSearch === "" && nameToSearch === ""
            && prodToSearch === "" && orderNumberToSearch === "" && !binAction ? (
            <></>
          ) : (
            <>
              <Box width={"md"} marginRight={3} textAlign={"left"}>
                <Label pl={2}>Filtra per Num Ordine</Label>
                <Input
                  leftIconBackground={colors.transparent}
                  clearable
                  icon={<Search color={colors.primaryText} />}
                  leftIcon
                  variant={"normal"}
                  value={orderNumberToSearch}
                  type="text"
                  name={"Code"}
                  formik={undefined}
                  onChangeCallback={(e) => setOrderNumberToSearch(e.target.value)}
                  onClear={() => setOrderNumberToSearch("")}
                  placeholder="Numero Ordine"
                />
              </Box>
              <Box width={"md"} marginRight={3} textAlign={"left"}>
                <Label pl={2}>Filtra per Nome</Label>
                <Input
                  leftIconBackground={colors.transparent}
                  clearable
                  icon={<Search color={colors.primaryText} />}
                  leftIcon
                  variant={"normal"}
                  value={nameToSearch}
                  type="text"
                  name={"Nome"}
                  formik={undefined}
                  onChangeCallback={(e) => setNameToSearch(e.target.value)}
                  onClear={() => setNameToSearch("")}
                  placeholder="Nome"
                />
              </Box>
              <Box width={"md"} marginRight={3} textAlign={"left"}>
                <Label pl={2}>Filtra per Cognome</Label>
                <Input
                  leftIconBackground={colors.transparent}
                  clearable
                  icon={<Search color={colors.primaryText} />}
                  leftIcon
                  variant={"normal"}
                  value={surnameToSearch}
                  type="text"
                  name={"Cognome"}
                  formik={undefined}
                  onChangeCallback={(e) => setSurnameToSearch(e.target.value)}
                  onClear={() => setSurnameToSearch("")}
                  placeholder="Cognome"
                />
              </Box>
              <Box width={"md"} marginRight={3} textAlign={"left"}>
                <Label pl={2}>Filtra per Prodotto</Label>
                <Input
                  leftIconBackground={colors.transparent}
                  clearable
                  icon={<Search color={colors.primaryText} />}
                  leftIcon
                  variant={"normal"}
                  value={prodToSearch}
                  type="text"
                  name={"Prodotto"}
                  formik={undefined}
                  onChangeCallback={(e) => setProdToSearch(e.target.value)}
                  onClear={() => setProdToSearch("")}
                  placeholder="Nome prodotto"
                />
              </Box>
            </>
          )}
          {(nameToSearch !== "" || surnameToSearch !== "" || prodToSearch !== "" || orderNumberToSearch !== "") &&
            <Box marginLeft={4} marginBottom={1}>
              <Trash size={'1.3em'} color={colors.primaryText}
                onClick={() => {
                  setSurnameToSearch(''); setNameToSearch(''); setProdToSearch(''); setOrderNumberToSearch(''); setBinAction(true)
                }}
                cursor={'pointer'} /></Box>}
        </Flex>
        <Flex
          alignItems={"end"}
          justifyContent={"center"}
          height={"100%"}
        >
        </Flex>
      </Flex>
      <Flex
        flexDirection={"column"}
        justifyContent={"space-between"}
        height={"78%"}
        position={"relative"}
        width={'100%'}
      >
        {ordersList.length > 0 ? (
          <Flex
            flexDirection={"column"}
            height={"80%"}
            marginTop={isMobile ? "80px" : "59px"}
            overflowY={"scroll"}
            width={"100%"}
          >
            <OrderTableHeading />
            <OrderTableBody data={ordersList} />
          </Flex>) : (<>Mi dispiace, ma non ho trovato ciò che cerchi</>)}
        {showPagination}
      </Flex>
    </Flex>
  )
}
