import styled from "styled-components";


const TextArea = styled.textarea`
resize:none;
 width: 100%;
 height: 80%;
  padding: 0.6em ;
  border-radius: 8px;
  font-size: ${(props) => props.theme.fontSizes.md};
  color: ${(props) => props.theme.colors.textColor};
  background-color: ${(props) => props.theme.colors.primaryBgActive};
  border-color: transparent;
  font-family: 'DM Sans', sans-serif;
  ::placeholder {
    color: ${(props) => props.theme.colors.gray10};
  }
`

export default TextArea