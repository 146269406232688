import { Outlet } from "react-router-dom";
import { ProductContextProvider } from "../context/ProductContext";


export const ProductMainBody = () => {
  return (
    <ProductContextProvider>
      <Outlet />
    </ProductContextProvider>
  );
};
