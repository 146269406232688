import { Outlet } from "react-router-dom";
import Flex from "../components/primitives/Flex";
import * as Tooltip from "@radix-ui/react-tooltip";


export const MainBody = () => {
  return (
    <Flex justifyContent={"center"} width={"100%"}>

      <Tooltip.Provider>
        <Outlet />
      </Tooltip.Provider>

    </Flex>
  );
};
