import React, { CSSProperties, useEffect, useMemo, useState } from "react"
import Tooltip from 'react-bootstrap/Tooltip';
import { OverlayTrigger } from "react-bootstrap";
import { FormikProps, FieldInputProps, FormikContextType, Formik } from 'formik'
import { filetypeIsAllowed, filetypeIsAudio, filetypeIsImage, filetypeIsVideo } from "../../../utils/utils";
import './UploadFIleComponent.css'

export interface UploadFileComponentProps {
    previewType?: "video" | "audio" // default == image
    label: string
    url?: string
    fileType: string
    callback: (param: any) => void
    id?: string
    file?: File
    dimensioni: string
    formik: FormikContextType<any>
    inputName: string
    checkImgDimension?: { maxHeight: number, maxWidth: number, square: boolean }
    setError: (error: string) => void
    setDeleteImage?: (deleta: boolean) => void
    imageDeleted?: boolean
    imgPlaceholder: string
    dimensionNotValid?: boolean
    className?:string
    aspectRatio?:string
}

export interface AssetFile {
    file: File
    src?: string
}

export const UploadFileComponent = (props: UploadFileComponentProps) => {

    const [selectedFile, setSelectedFile] = useState<AssetFile>()
    const [isValid, setIsValid] = useState(true)
    const inputId = `imageObject${props.label.replace(" ", "")}${props?.id}`

    // copia immagine
    useEffect(() => {
        if (props.file) {
            const file = props.file
            const assetFile = { file, src: URL.createObjectURL(file) } as AssetFile
            setSelectedFile(assetFile);
            props.callback(assetFile.file);
            setIsValid(true);
        }
    }, [props.file])

    const onSelectFile = (e: any) => {
        props.setError("")
        props.formik.setFieldValue(`${props.inputName}`, e.target.files[0])
        props.formik.setFieldTouched(`${props.inputName}`, true)

        if (!e.target.files || e.target.files.length === 0) {
            setIsValid(false);
            setSelectedFile(undefined);
            props.formik.setFieldError(`${props.inputName}`, `File will be ignored, you can upload: ${props.fileType}`)
            props.setError(`File will be ignored, you can upload: ${props.fileType}`)
            // props.formik.setFieldValue(`${props.inputName}`, undefined)
            return
        }

        const file = e.target.files[0]

        const isValidExtension = filetypeIsAllowed(file?.type, props.previewType);

        if (!isValidExtension) {
            setIsValid(false);
            setSelectedFile(undefined);
            props.formik.setFieldError(`${props.inputName}`, `File will be ignored, you can upload: ${props.fileType}`)
            props.setError(`File will be ignored, you can upload: ${props.fileType}`)
            props.formik.setFieldValue(`${props.inputName}`, undefined)

            return
        }

        const assetFile = { file, src: URL.createObjectURL(file) } as AssetFile

        setIsValid(true);
        setSelectedFile(assetFile);
        props.setDeleteImage?.(false)
        props.formik.setFieldValue(`${props.inputName}`, assetFile?.file)
        props.callback(assetFile?.file);
        e.target.value = null;
        props.setError("")
    }

    const showPreviewImage = useMemo(() => {

        const Style = { width: "100%", height: "100%", objectFit:"cover",objectPosition:"center center", backgroundColor: "var(--white)"} as CSSProperties
        const commonStyle = props.aspectRatio ? {...Style ,  aspectRatio:`${props.aspectRatio} ` } : Style
        if (props.imageDeleted || props.dimensionNotValid) {
            return <img className=' ' style={commonStyle} src={props.imgPlaceholder} />
        }


        if (!selectedFile && isValid) {
            if (!!props?.url) {
                if (props.previewType === "video") {
                    return <video className='' style={commonStyle} src={props.url} controls loop autoPlay />
                }
                else if (props.previewType === "audio") {
                    return <audio controls className='w-75'>
                        <source style={{ zIndex: 9 }} src={props.url} type="audio/mpeg" />
                    </audio>
                }
                else {
                    return <img className="w-100 h-100" style={commonStyle} src={props?.url} />
                }
            }
            else if (props.previewType === "audio") {
                return <div className="" style={{ width: "410px", height: "54px", objectFit: "cover", backgroundColor: "#F5F8FA" }} />
            } else {
                return <div className="" style={commonStyle} />
            }

        }

        if (!!selectedFile && isValid) {
            if (filetypeIsVideo(selectedFile?.file?.type) && props.previewType === "video") {
                return <video className='' style={commonStyle} src={selectedFile?.src} controls loop autoPlay />
            }
            else if (filetypeIsAudio(selectedFile?.file?.type) && props.previewType === "audio") {
                return (
                    <audio controls className='w-75'>
                        <source style={{ zIndex: 9 }} src={selectedFile?.src} type="audio/mpeg" />
                    </audio>
                )
            }
            else if (filetypeIsImage(selectedFile?.file.type)) {
                return <img className='h-100 ' style={commonStyle} src={selectedFile?.src} />
            }
        }
        else {
            return <img className=' ' style={commonStyle} src={props.imgPlaceholder} />
        }

    }, [selectedFile, isValid, props?.url, props.previewType, props.imageDeleted, props.imgPlaceholder, props.dimensionNotValid])

    const showDeleteFile = useMemo(() => {
        if (selectedFile || !isValid) {
            return (
                <label className="cursor-pointer" onClick={() => {
                    setIsValid(true);
                    setSelectedFile(undefined)
                    props.setDeleteImage?.(true)
                    props.formik.setFieldValue(`${props.inputName}`, undefined)
                    props.callback(undefined);
                }}> | Elimina File</label>
            )
        } else {
            return null
        }
    }, [selectedFile, isValid])

    return (
        <>
            <div className={` w-100 h-100 ${props.className} text-start position-relative`} >
                <div className="d-flex  " style={props.aspectRatio === "1:1" ? { height:"110px", width:"110px"}: {height:"100%", maxHeight:"350px" }}> 
                {showPreviewImage}
                </div> 
                <input
                    autoComplete="new-password"
                    {...props.formik.getFieldProps(`${props.inputName}`)}
                    name={props.inputName}
                    accept={props.fileType}
                    className="d-none"
                    id={inputId}
                    type="file"
                    onChange={onSelectFile}
                    onBlur={() => props.formik.setFieldTouched(`${props.inputName}`, true)}
                    value={undefined}
                />
                <div className="position-absolute button-upload-delete cursor-inherit">
                    <div className="d-flex flex-wrap justify-content-center">

                        <label
                            onBlur={() => props.formik.setFieldTouched(`${props.inputName}`, true)}
                            htmlFor={inputId}
                            className="cursor-pointer"
                        >Seleziona file</label>
                        {showDeleteFile}
                    </div>
                    <div>
                        <span style={{ fontSize: 14, fontWeight: 600, marginRight: 10 }} className='required mb-2'>{props.label}</span>
                        <OverlayTrigger
                            placement={"top"}
                            overlay={
                                <Tooltip>
                                    You can upload: {props.fileType}. <br /> {props.dimensioni !== "" && `Dimension ${props.dimensioni}`}
                                </Tooltip>
                            }
                        >
                            <i style={{ lineHeight: "25px" }} className={`fa fa-solid fa-question text-gray-600 fs-5 p-0`}></i>
                        </OverlayTrigger>
                    </div>
                </div>
            </div>
        </>

    )
}