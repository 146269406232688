import React from "react";
import HeaderRow from "./HeaderRow";

import Txt from "../primitives/Txt";
import { useMediaQuery } from "react-responsive";
import FlexTableCell from "./TableCell";
import { MOBILE_TABLE_TEMPLATE, TABLE_TEMPLATE } from "../../utils/const/utilsConst";
import { columnsFlexDefinitions } from "./columnsDefinitions";

export const OrderTableHeading = () => {
  const isMobile = useMediaQuery({ maxWidth: 500 });
  return (
    <HeaderRow>
      <FlexTableCell flex={columnsFlexDefinitions.orderNumber}>
        <Txt textAlign={'start'} marginLeft={4}>Num. Ordine</Txt>
      </FlexTableCell>
      <FlexTableCell flex={columnsFlexDefinitions.nameSurname}>
        <Txt>Nome e Cognome</Txt>
      </FlexTableCell>
      <FlexTableCell flex={columnsFlexDefinitions.email}>
        <Txt>Prodotto</Txt>
      </FlexTableCell>
      <FlexTableCell flex={columnsFlexDefinitions.date}>
        <Txt>Data</Txt>
      </FlexTableCell>
      <FlexTableCell flex={columnsFlexDefinitions.orderDiscountedPrice}>
        <Txt>Valore</Txt>
      </FlexTableCell>
      <FlexTableCell justifyContent={"center"} flex={columnsFlexDefinitions.actions}>
        <Txt>Azioni</Txt>
      </FlexTableCell>
    </HeaderRow>
  );
};
