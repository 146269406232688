
import { createContext, useEffect, useState } from 'react'

import Txt from '../components/primitives/Txt'
import { ModalTypeEnum, TitleEnum } from '../type/enum'
import { ApiSimpleResponse, BaseResponse } from '../type/response'
import { ActionButton } from '../components'
import Flex from '../components/primitives/Flex'


export interface ModalContextType {
  modalType: ModalTypeEnum
  setModalType: (modalType: ModalTypeEnum) => void
  show: boolean
  setShow: (show: boolean) => void
  child: React.ReactNode
  title?: TitleEnum | string
  setTitle: (title: TitleEnum | string) => void
  isLoading: boolean
  setIsLoading: (isLoading: boolean) => void
  setLoadingModal: () => void
  closeModal: (msDelay?: number) => void
  openModalWithChildren: (
    child: React.ReactElement,
    type: ModalTypeEnum,
    title?: TitleEnum | string
  ) => void
  openApiModalMessage: (response: ApiSimpleResponse<BaseResponse>) => void
  openMessageModal: (title: string, message: string, type: ModalTypeEnum) => void
  openConfirmModal: (title: string, message: string, type: ModalTypeEnum, onConfirm: () => void) => void
}

//
const modalContextDefaultValue: ModalContextType = {
  modalType: ModalTypeEnum.edit,
  setModalType: (_: ModalTypeEnum) => { },
  show: false,
  setShow: (_: boolean) => { },
  title: '',
  setTitle: (_: TitleEnum | string) => { },
  isLoading: false,
  setIsLoading: (_: boolean) => { },
  setLoadingModal: () => { },
  closeModal: () => { },
  openModalWithChildren: () => { },
  child: <></>,
  openApiModalMessage: () => { },
  openMessageModal: () => { },
  openConfirmModal: () => { },
}
export const ModalContext = createContext<ModalContextType>(
  modalContextDefaultValue
)

interface ModalContextProviderProps {
  children: React.ReactElement
}
export const ModalContextProvider = ({
  children,
}: ModalContextProviderProps) => {
  const [show, setShow] = useState<boolean>(false)
  const [title, setTitle] = useState<TitleEnum | string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [child, setChild] = useState<React.ReactElement>()
  const [modalType, setModalType] = useState<ModalTypeEnum>(
    ModalTypeEnum.loading
  )

  const setLoadingModal = () => {
    setShow(true)
    setTitle(TitleEnum.nowLoading)
    setIsLoading(true)
    setModalType(ModalTypeEnum.loading)
    setChild(<></>)
  }

  const openMessageModal = (title: string, message: string, type: ModalTypeEnum) => {
    setShow(true)
    setTitle(title)
    setModalType(type)
    setChild(<Txt>{message}</Txt>)
  }
  const openConfirmModal = (title: string, message: string, type: ModalTypeEnum, onConfirm: () => void) => {
    setShow(true)
    setTitle(title)
    setModalType(type)
    setChild(
      <Flex justifyContent={'space-around'} flexDirection={'column'} >
        <Txt>{message}</Txt>
        <ActionButton onAbort={() => { closeModal() }} onConfirm={() => { onConfirm() }} />
      </Flex>
    )
  }

  const closeModal = (msDelay?: number) => {
    setTimeout(() => {
      setShow(false)
      setIsLoading(false)
    }, msDelay ?? 0);
  }

  useEffect(() => {
    if (!show) {
      setTitle('')
      setIsLoading(false)
      setChild(<></>)
      setModalType(ModalTypeEnum.loading)
    }
  }, [show])

  const openModalWithChildren = (
    child: React.ReactElement,
    type: ModalTypeEnum,
    title?: TitleEnum | string
  ) => {
    setShow(true)
    setChild(child)
    setModalType(type)
    if (title) {
      setTitle(title)
    }
  }

  const openApiModalMessage = (response: ApiSimpleResponse<BaseResponse>) => {
    setIsLoading(false)
    setShow(true)
    if (!response.isSuccess) {
      setTitle(TitleEnum.error)
      setChild(
        <Txt>
          {response.error ?? 'Something went wrong!'}
        </Txt>
      )
      setModalType(ModalTypeEnum.error)
    } else {
      setTitle(TitleEnum.success)
      setChild(<Txt>Operation Confirmed</Txt>)
      setModalType(ModalTypeEnum.success)
    }
  }

  const modalContextInitialValue: ModalContextType = {
    modalType,
    setModalType,
    show,
    setShow,
    title,
    setTitle,
    isLoading,
    setIsLoading,
    setLoadingModal,
    closeModal,
    openModalWithChildren,
    child,
    openApiModalMessage,
    openMessageModal,
    openConfirmModal
  }
  return (
    <ModalContext.Provider value={modalContextInitialValue}>
      {children}
    </ModalContext.Provider>
  )
}
