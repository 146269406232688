import { useContext } from "react";
import "./App.css";

import "react-toastify/dist/ReactToastify.css";
import { ThemeProvider } from "styled-components";

import { CustomThemeContext } from "./context/ThemeContext";
import { AuthContextProvider } from "./context/AuthContext";


import GlobalStyle from "./theme/globalStyle";
import Flex from "./components/primitives/Flex";
import { AppGeneralContextProvider } from "./context/AppGeneralContext";
import { Layout } from "./components/molecules/Layout";
import { ModalContextProvider } from "./context/ModalContext";
import Modal from "./components/Modal/Modal";
import { TooltipProvider } from "@radix-ui/react-tooltip";
import { CustomRoutes } from "./pages";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';


function App() {
  // passing themeValue
  const { customTheme } = useContext(CustomThemeContext);

  return (

    <ThemeProvider theme={customTheme}>
      <GlobalStyle theme={customTheme} />
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <ModalContextProvider>
          <AppGeneralContextProvider>
            <AuthContextProvider>
              <Flex width={"100%"} justifyContent={"center"} m={"0 auto"}>
                <Layout>
                  <CustomRoutes />
                </Layout>
                <TooltipProvider>
                  <Modal></Modal>
                </TooltipProvider>
              </Flex>
            </AuthContextProvider>
          </AppGeneralContextProvider>
        </ModalContextProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default App;
