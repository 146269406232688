import { Outlet } from "react-router-dom";
import { OrderContextProvider } from "../context/OrderContext";

export const OrderMainBody = () => {
  return (
    <OrderContextProvider>
      <Outlet />
    </OrderContextProvider>
  );
};
