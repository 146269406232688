import { Customer, Product, Coupon, Order} from "./models";

export enum ActionsType {
    UPDATE_CUSTOMER,
    CREATE_CUSTOMER,
    RESET_CUSTOMER,
    UPDATE_COUPON,
    RESET_COUPON,
    UPDATE_PROD,
    CREATE_PROD,
    RESET_PROD,
    UPDATE_ORDER,
    CREATE_ORDER,
    RESET_ORDER,
}

type updateCustomer = { type: ActionsType.UPDATE_CUSTOMER; payload: Customer }
type editCustomer = { type: ActionsType.CREATE_CUSTOMER; payload: Customer }
type resetCustomer = { type: ActionsType.RESET_CUSTOMER }
export type CustomerActions = updateCustomer | editCustomer | resetCustomer;


export const couponInit: Coupon = {
    id: '',
    referralCode: '',
    creationDate: new Date(),
    creationDateString: '',
    customerId: ''
}
export const customerInit: Customer = {
    id: '',
    name: '',
    surname: '',
    email: '',
    note: '',
    phoneNumber: '',
    referrerId: '',
    registrationDate: new Date(),
    referredCount: 0,
    lostLead:false,
    appointmentTaken:false,
    preferenceMorning:false,
    preferenceEvening:false,
    preferenceAfternoon:false,
    waitingListAdventCalendar:false
}
export const prodInit: Product = {
    id: '',
    title: '',
    description: '',
    brandName: '',
    discountedPrice:'',
    price: '',
    orderQuantityLimit: 0,
    quantity: 0,
    date: ''
}
export const orderInit: Order = {
    id: '',
    orderNumber: '',
    customerId: '',
    productServiceId: '',
    isPaid: false,
    isDelivered: false,
    date: new Date (),
    dateString: '',
    quantity: 0
}

export const customerInitState: (_: Customer) => Customer = (args: Customer) => {
    return { ...args }
}

export function customerReducer(state: Customer, action: CustomerActions) {
    switch (action.type) {
        case ActionsType.UPDATE_CUSTOMER:
            return {
                ...state,
                id: action.payload.id,
                name: action.payload.name,
                surname: action.payload.surname,
                email: action.payload.email,
                note: action.payload.note,
                phoneNumber: action.payload.phoneNumber,
                referredCount: action.payload.referredCount,
                referrerId: action.payload.referrerId,
                referrer: action.payload.referrer,
                registrationDate: new Date(),
                coupon: action.payload.coupon,
                appointmentTaken: action.payload.appointmentTaken,
                lostLead: action.payload.lostLead
            } as Customer
        case ActionsType.CREATE_CUSTOMER:
            return {
                ...state,
                name: action.payload.name,
                surname: action.payload.surname,
                email: action.payload.email,
                note: action.payload.note,
                phoneNumber: action.payload.phoneNumber,
                referrerId: action.payload.referrerId,
                registrationDate: new Date(),
                referredCount: 0
            } as Customer
        case ActionsType.RESET_CUSTOMER:
            return customerInitState(customerInit)
        default:
            return state
    }
}

type updateCoupon = { type: ActionsType.UPDATE_COUPON; payloadCoupon: Coupon }
type resetCoupon = { type: ActionsType.RESET_COUPON }
export type CouponActions = updateCoupon | resetCoupon;

export const couponInitState: (_: Coupon) => Coupon = (args:Coupon) => {
    return { ...args }
}

export function couponReducer(state: Coupon, action: CouponActions) {
    switch (action.type) {
        case ActionsType.UPDATE_COUPON:
            return {
                ...state,
                id: action.payloadCoupon.id,
                creationDate: new Date (),
                referralCode: action.payloadCoupon.referralCode,
                customerId: action.payloadCoupon.customerId                
            } as Coupon
        case ActionsType.RESET_COUPON:
            return couponInitState(couponInit)
        default:
            return state
    }
}

type updateProduct = { type: ActionsType.UPDATE_PROD; payload: Product }
type editProduct = { type: ActionsType.CREATE_PROD; payload: Product }
type resetProduct = { type: ActionsType.RESET_PROD }
export type ProductActions = updateProduct | editProduct | resetProduct;

export const prodInitState: (_: Product) => Product = (args: Product) => {
    return { ...args }
}

export function prodReducer(state: Product, action: ProductActions) {
    switch (action.type) {
        case ActionsType.UPDATE_PROD:
            return {
                ...state,
                id: action.payload.id,
                title: action.payload.title,
                brandName: action.payload.brandName,
                description: action.payload.description,
                price: action.payload.price,
                discountedPrice: action.payload.discountedPrice,
                quantity: 0,
                orderQuantityLimit: 0,
                date: action.payload.date,
            } as Product
        case ActionsType.CREATE_PROD:
            return {
                ...state,
                title: action.payload.title,
                brandName: action.payload.brandName,
                description: action.payload.description,
                price: action.payload.price,
                discountedPrice: action.payload.discountedPrice,
                quantity: 0,
                orderQuantityLimit: 0,
                date: action.payload.date,
            } as Product
        case ActionsType.RESET_PROD:
            return prodInitState(prodInit)
        default:
            return state
    }
}

type updateOrder = { type: ActionsType.UPDATE_ORDER; payload: Order }
type editOrder = { type: ActionsType.CREATE_ORDER; payload: Order }
type resetOrder = { type: ActionsType.RESET_ORDER}
export type OrderActions = updateOrder | editOrder | resetOrder;

export const orderInitState: (_: Order) => Order = (args: Order) => {
    return { ...args }
}

export function orderReducer(state: Order, action: OrderActions) {
    switch (action.type) {
        case ActionsType.UPDATE_ORDER:
            return {
                ...state,
                id: action.payload.id,
                orderNumber: action.payload.orderNumber,
                customerId: action.payload.customerId,
                productServiceId: action.payload.productServiceId,
                isPaid: action.payload.isPaid,
                isDelivered: action.payload.isDelivered,
                date: action.payload.date,
                quantity: action.payload.quantity
            } as Order
        case ActionsType.CREATE_ORDER:
            return {
                ...state,
                id: action.payload.id,
                orderNumber: action.payload.orderNumber,
                customerId: action.payload.customerId,
                productServiceId: action.payload.productServiceId,
                isPaid: action.payload.isPaid,
                isDelivered: action.payload.isDelivered,
                date: action.payload.date,
                quantity: action.payload.quantity
            } as Order
        case ActionsType.RESET_ORDER:
            return orderInitState(orderInit)
        default:
            return state
    }
}