/* eslint-disable react-hooks/exhaustive-deps */

import { X } from "react-bootstrap-icons";

import { useContext, useEffect, useMemo } from "react";

import { useMediaQuery } from "react-responsive";
import styled, { ThemeContext } from "styled-components";
import { ModalTypeEnum, TitleEnum } from "../../type/enum";
import { useScrollBlock } from "../../utils/hooks/useScrollBlock";
import Flex from "../primitives/Flex";
import Box from "../primitives/Box";
import LoadingSpinner from "../molecules/LoadingSpinner";
import { ModalContext } from "../../context/ModalContext";


const Modal = () => {
  const { show, closeModal, child, title, isLoading, modalType } =
    useContext(ModalContext);
  const isMobile = useMediaQuery({ maxWidth: 950 });
  const isPotrait = useMediaQuery({orientation:'portrait'})
  const {colors} = useContext(ThemeContext)
  const [blockScroll, allowScroll] = useScrollBlock();

  const toggleScroll = (enable: boolean) => {
    if (enable) {
      blockScroll();
    } else {
      allowScroll();
    }
  };

  useEffect(() => {
    toggleScroll(show);
    return () => toggleScroll(show);
  }, [show]);

  useEffect(() => {
    return () => {
      allowScroll();
    };
  }, []);

  const handleCloseClick = () => {
    closeModal();
  };


  const loadingModalCSS = {
    width: `${isMobile ? "80%" : "40%"}`,
    height: `${isMobile ? "30%" : "60%"}`,
    background: "transparent",
    color:colors.primaryBgSubtle
  };

  const messageModalCss = {
    width: `${isMobile ? "80%" : "60%"}`,
    height: `${isPotrait ? "50%" : isMobile?"90%":"70%"}`,
  };

  const detailModalCss = {
    width: `${isMobile ? "80%" : "80%"}`,
    height: `${isMobile ? "80%" : "80%"}`,
  };

  const errorModalCss = {
    width: `${isMobile ? "80%" : "30%"}`,
    height: `${isMobile ? "30%" : "40%"}`,
    color:colors.dangerText
  }

  const successModalCss = {
    width: `${isMobile ? "80%" : "30%"}`,
    height: `${isMobile ? "30%" : "40%"}`,
    color:colors.successText
  }
  const selectCSS = useMemo(() => {
  
   switch (modalType) {
      case ModalTypeEnum.detail:
        return detailModalCss;
      case ModalTypeEnum.edit:
        return detailModalCss;
      case ModalTypeEnum.loading:
        return loadingModalCSS;
      case ModalTypeEnum.error:
        return errorModalCss;
      case ModalTypeEnum.message:
        return messageModalCss;
      case ModalTypeEnum.success:
        return successModalCss;
    }
    
  }, [modalType,isMobile,isPotrait]);

  const modalContent = show ? (
    <StyledModalOverlay>
      <StyledModal style={selectCSS}>
        <StyledModalHeader>
          {title !== TitleEnum.nowLoading && (
            <X
            
              onClick={() => handleCloseClick()}
              color={colors.dangerSolid}
             size={'1em'}
            />
          )}
        </StyledModalHeader>
        {!!title && (
          <StyledModalTitle>
            <h2>{title.toString()}</h2>
          </StyledModalTitle>
        )}

        <StyledModalBody>
          {isLoading && (
            <Box>
              <Flex
                justifyContent="center"
                alignItems="center"
                style={{ height: 150 }}
              >
                <LoadingSpinner style={{ justifySelf: "center" }} />
              </Flex>
            </Box>
          )}

          {child}
        </StyledModalBody>
      </StyledModal>
    </StyledModalOverlay>
  ) : null;

  return <>{modalContent}</>;
};

const StyledModalBody = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
  color:${props=>props.theme.colors.textColor};
  
  &::-webkit-scrollbar {
    -webkit-appearance: none;
  }
`;
const StyledModalTitle = styled.div`
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  line-height: normal;
  padding-left: 5px;
  padding-right: 5px;

`;

const StyledModalHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  font-size: 25px;
  & svg {
    color:${(props) => props.theme.colors.primarySolid};
  }
  & svg:hover {
    color: ${(props) => props.theme.colors.primarySolidHover};
    cursor: pointer;
  }
`;
//'#1A1B1F'

const StyledModal = styled.div`
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.colors.gray1};
  z-index: 999;
  border-radius: 10px;
  padding: 30px;
  position: fixed;
`;
const StyledModalOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
`;

export default Modal;
