import React, { useCallback, useContext, useEffect, useMemo, useReducer, useState } from 'react'
import Box from '../components/primitives/Box'
import Flex from '../components/primitives/Flex'
import { AppGeneralContext } from '../context/AppGeneralContext';
import Txt from '../components/primitives/Txt';
import Label from '../components/primitives/Label';
import { Input } from '../components/atoms';
import { Search, Trash } from 'react-bootstrap-icons';
import { PagerComponent } from '../components/primitives';
import { postReferredList, upsertCustomer } from '../api/CustomersCall';
import { useDebounce } from '../utils';
import TextArea from '../components/atoms/TextArea'
import { useFormik } from 'formik'

import { ThemeContext } from 'styled-components';
import { ModalContext } from '../context/ModalContext';
import { toastType } from '../type/enum';
import { ListCouponsRequest, ListCustomersRequest } from '../type/request';
import { Coupon, Customer } from '../type/models';
import { ApiSimpleResponse, CustomerResponse, Pager } from '../type/response';
import { PagerInitialState } from '../utils/const/initialValue';
import Form from '../components/primitives/Form'
import { couponInit, couponReducer, couponInitState } from '../type/useReducer';
import { customerValidationSchema as couponValidationSchema } from '../utils/formikSchemas';
import { ActionButton } from '../components';
import { ReferredTableHeading } from '../components/table/ReferredTableHeading';
import { ReferredTableBody } from '../components/table/ReferredTableBody';
import { useLocation, useNavigate } from 'react-router-dom';
import { FORM_URL } from '../utils/const/apiConst';
import { getCouponById } from '../api/CouponsCall';
import { CouponContext } from '../context/CouponContext';

export const CouponDetail = () => {
  const { coupon: data, page, setPage } = useContext(CouponContext)
  const location = useLocation()
  const couponId = location.state.couponId as string
  const { isMobile, addToast } = useContext(AppGeneralContext);
  const [nameToSearch, setNameToSearch] = useState<string | undefined>("");
  const [surnameToSearch, setSurnameToSearch] = useState<string | undefined>("");
  const [emailToSearch, setEmailToSearch] = useState<string | undefined>("");
  const [phoneToSearch, setPhoneToSearch] = useState<string | undefined>("");
  const [codeToSearch, setCodeToSearch] = useState<string | undefined>("");
  const searchSurnameParams = useDebounce(surnameToSearch, 200);
  const searchNameParams = useDebounce(nameToSearch, 200);
  const searchEmailParams = useDebounce(emailToSearch, 200);
  const searchPhoneParams = useDebounce(phoneToSearch, 200);
  const searchCodeParams = useDebounce(codeToSearch, 200);
  const { colors } = useContext(ThemeContext);
  const [couponDetail, setCouponDetail] = useState<Coupon>()
  const [referredList, setReferredList] = useState<Customer[]>([])
  const [pager, setPager] = useState<Pager>(PagerInitialState)
  const [binAction, setBinAction] = useState<boolean>(false)
  const [couponState, couponDispatch] = useReducer(couponReducer, couponInit, couponInitState)
  const [copiedToClipboard, setCopiedToClipboard] = useState<boolean>(false)

  const { setLoadingModal, closeModal } = useContext(ModalContext);

  const modalDelay = 300

  const getCoupon = async (id: string) => {
    setLoadingModal()
    const resp = await getCouponById(id)
    if (!resp.response || !resp.isSuccess) {
      closeModal(modalDelay)
      addToast(toastType.error, 'Errore durante il caricamento dei dati')
      return
    }
    closeModal(modalDelay)
    setCouponDetail(resp.response.data)
  }

  useEffect(() => {
    if (!!couponId) {
      getCoupon(couponId)
      getReferredList()
    }
  }, []);

  const getReferredList = useCallback(async () => {
    if (!couponDetail?.customerId) {
      return
    }

    setLoadingModal()
    const req: ListCustomersRequest = {
      pageNum: page ?? 1,
      pageSize: 10,
      name: searchNameParams ?? '',
      surname: searchSurnameParams ?? '',
      email: searchEmailParams ?? '',
      referrerId: couponDetail.customerId
    }
    const resp = await postReferredList(req)
    if (!resp.response || !resp.isSuccess) {
      closeModal(modalDelay)
      addToast(toastType.error, 'Errore durante il caricamento dei dati')
      return
    }
    setBinAction(false)
    closeModal(modalDelay)
    setReferredList(resp.response.data)
    setPager(resp.response.pager)
  }, [searchSurnameParams, searchNameParams, searchEmailParams, searchPhoneParams, page, couponDetail?.customerId])

  useEffect(() => {
    getReferredList()
  }, [getReferredList]);

  useEffect(() => {
    setPage(1)
  }, [searchSurnameParams, searchNameParams, searchEmailParams, searchPhoneParams]);

  const showPagination = useMemo(() => {
    if (pager.totalPages < 1) {
      return <></>;
    }
    return (
      <Flex
        flexDirection={"row"}
        width={"100%"}
        justifyContent={"end"}
        height={'20%'}
      >
        <PagerComponent pager={pager} setPage={setPage}></PagerComponent>
      </Flex>
    );
  }, [pager]);


  useEffect(() => {
    if (!copiedToClipboard) {
      return
    }
    const timeout = setTimeout(() => {
      setCopiedToClipboard(false);
    }, 1500);
    return () => clearInterval(timeout)
  }, [copiedToClipboard])

  const referralUrl = `${FORM_URL}${couponDetail?.referralCode}`;

  const copyToClipboard = (textToWrite: string) => {
    navigator.clipboard.writeText(textToWrite).then(() => {
      setCopiedToClipboard(true)
    })
    setCopiedToClipboard(true)
  }

  return (
    <Flex flexDirection={"column"} width={"90%"} height={"100vh"}>
      <Flex justifyContent={"center"} alignItems={"center"} height={"12%"} mt={'20px'} mb={'20px'}>
        <Txt>
          <h2>Buono passaparola {couponDetail?.referralCode}</h2>
        </Txt>
      </Flex>
      <Form width={'100%'} flexDirection={'column'} justifyContent={'space-around'}>
        <Flex flexWrap={'wrap'} justifyContent={'space-evenly'}>
          <Flex flexDirection={'row'} justifyContent={'center'} borderBottom={'2px solid pink'}>
            <Flex width={"lg"} marginRight={[0, 3]} alignItems={'center'}>
              <Txt fontWeight={700}> Codice passaparola: {couponDetail?.referralCode}
              </Txt>
            </Flex>
            <Flex width={"lg"} marginRight={[0, 3]} alignItems={'center'}>
              <Txt fontWeight={700}> Data di creazione: {couponDetail?.creationDateString}
              </Txt>
            </Flex>
          </Flex>
        </Flex>
        <Flex flexWrap={'wrap'} justifyContent={'center'}>
          <Flex flexDirection={'row'} justifyContent={'center'} mt={'40px'}>
            <Flex width={"lg"} marginRight={[0, 3]} alignItems={'center'}>
              <Txt fontWeight={700}> Dati proprietario: {couponDetail?.customer?.name} {couponDetail?.customer?.surname}
              </Txt>
            </Flex>
          </Flex>
        </Flex>
        <Flex flexWrap={'wrap'} justifyContent={'center'} >
          <Flex flexDirection={'row'} justifyContent={'center'} mt={'20px'} borderBottom={'2px solid pink'} >
            <Flex width={"lg"} marginRight={[0, 3]} alignItems={'center'}>
              <Txt fontWeight={700}> E-mail: {couponDetail?.customer?.email}
              </Txt>
            </Flex>
            <Flex width={"lg"} marginRight={[0, 3]} alignItems={'center'}>
              <Txt fontWeight={700}> Telefono: {couponDetail?.customer?.phoneNumber}
              </Txt>
            </Flex>
          </Flex>
        </Flex>
        <Flex flexWrap={'wrap'} justifyContent={'center'} >
          <Flex flexDirection={'row'} justifyContent={'center'} mt={'40px'} borderBottom={'2px solid pink'}>
            <Flex width={"lg"} marginRight={[0, 3]} alignItems={'center'}>
              <Txt fontWeight={700}> Passaparola da:  {!!data.customer?.referrer ? `${data.customer.referrer?.name} ${data.customer.referrer?.surname}` : "Nessuno"}</Txt>
            </Flex>
            <Flex width={"lg"} marginRight={[0, 3]} alignItems={'center'}>
              <Txt fontWeight={700} >Quantità clienti referenziati: {couponDetail?.customer?.referredCount}</Txt>
            </Flex>
          </Flex>
        </Flex>
        <Flex width={'100%'} flexDirection={'row'} justifyContent={'space-evenly'} mt={'40px'}>
          <Flex cursor='pointer' width={"lg"} marginRight={[0, 3]} alignItems={'center'} justifyContent={'center'}>
            <Txt fontWeight={700} onClick={() => copyToClipboard(referralUrl)}>
              {copiedToClipboard ? 'Copiato!' : `Link da condividere: ${referralUrl}`}
            </Txt>
          </Flex>
          <Flex width={"lg"} marginRight={[0, 3]} alignItems={'center'}>
            <Txt><a href={`${'https://wa.me:/'}${data.customer?.phoneNumber}${'&text=message&app_absent=0'}`}> Clicca qui per inviare il Coupon al proprietario via whatsapp </a></Txt>
          </Flex>
        </Flex>

      </Form >
      {
        referredList.length === 0 ?
          <Flex justifyContent={"center"} alignItems={"center"} height={"12%"} mt={'50px'} mb={'20px'}>
            <Txt>
              <h3>Non ci sono clienti referenziati a questo coupon da visualizzare</h3>
            </Txt>
          </Flex>
          :
          <>
            <Flex justifyContent={"center"} alignItems={"center"} height={"12%"} mt={'80px'}>
              <Txt>
                <h3>Lista clienti referenziati</h3>
              </Txt>
            </Flex>
            <Flex width={"100%"} flexDirection={'row'} height={'10%'} marginBottom={3} justifyContent={'space-between'}>
              <Flex alignItems={"end"} width={'100%'} >
                {referredList.length === 0 && surnameToSearch === "" && nameToSearch === ""
                  && emailToSearch === "" && phoneToSearch === "" && !binAction ? (
                  <></>
                ) : (
                  <>
                    <Box width={"md"} marginRight={3} textAlign={"left"}>
                      <Label pl={2}>Filtra per Nome</Label>
                      <Input
                        leftIconBackground={colors.transparent}
                        clearable
                        icon={<Search color={colors.primaryText} />}
                        leftIcon
                        variant={"normal"}
                        value={nameToSearch}
                        type="text"
                        name={"Nome"}
                        formik={undefined}
                        onChangeCallback={(e) => setNameToSearch(e.target.value)}
                        onClear={() => setNameToSearch("")}
                        placeholder="Nome"
                      />
                    </Box>
                    <Box width={"md"} marginRight={3} textAlign={"left"}>
                      <Label pl={2}>Filtra per Cognome</Label>
                      <Input
                        leftIconBackground={colors.transparent}
                        clearable
                        icon={<Search color={colors.primaryText} />}
                        leftIcon
                        variant={"normal"}
                        value={surnameToSearch}
                        type="text"
                        name={"Cognome"}
                        formik={undefined}
                        onChangeCallback={(e) => setSurnameToSearch(e.target.value)}
                        onClear={() => setSurnameToSearch("")}
                        placeholder="Cognome"
                      />
                    </Box>
                    <Box width={"md"} marginRight={3} textAlign={"left"}>
                      <Label pl={2}>Filtra per E-mail</Label>
                      <Input
                        leftIconBackground={colors.transparent}
                        clearable
                        icon={<Search color={colors.primaryText} />}
                        leftIcon
                        variant={"normal"}
                        value={emailToSearch}
                        type="text"
                        name={"Email"}
                        formik={undefined}
                        onChangeCallback={(e) => setEmailToSearch(e.target.value)}
                        onClear={() => setEmailToSearch("")}
                        placeholder="Indirizzo e-mail"
                      />
                    </Box>
                    <Box width={"md"} textAlign={"left"}>
                      <Label pl={2}>Filtra per Telefono</Label>
                      <Input
                        leftIconBackground={colors.transparent}
                        clearable
                        icon={<Search color={colors.primaryText} />}
                        leftIcon
                        variant={"normal"}
                        value={phoneToSearch}
                        type="text"
                        name={"Telefono"}
                        formik={undefined}
                        onChangeCallback={(e) => setCodeToSearch(e.target.value)}
                        onClear={() => setCodeToSearch("")}
                        placeholder="Numero telefono"
                      />
                    </Box>
                  </>
                )}
                {(nameToSearch !== "" || surnameToSearch !== "" || emailToSearch !== "" || phoneToSearch !== "") &&
                  <Box marginLeft={4} marginBottom={1}>
                    <Trash size={'1.3em'} color={colors.primaryText}
                      onClick={() => {
                        setSurnameToSearch(''); setNameToSearch(''); setEmailToSearch(''); setCodeToSearch(''); setBinAction(true)
                      }}
                      cursor={'pointer'} /></Box>}
              </Flex>
            </Flex>
            <Flex
              flexDirection={"column"}
              justifyContent={"space-between"}
              height={"78%"}
              position={"relative"}
              width={'100%'}
            >
              {referredList.length > 0 ? (
                <Flex
                  flexDirection={"column"}
                  height={"80%"}
                  marginTop={isMobile ? "80px" : "59px"}
                  overflowY={"scroll"}
                  width={"100%"}
                >
                  <ReferredTableHeading />
                  <ReferredTableBody getList={getReferredList} data={referredList} />
                </Flex>) : (<>Mi dispiace, ma non ho trovato ciò che cerchi</>)}
              {showPagination}
            </Flex>
          </>
      }
    </Flex >
  )
}
