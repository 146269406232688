import React from "react";
import HeaderRow from "./HeaderRow";

import Txt from "../primitives/Txt";
import { useMediaQuery } from "react-responsive";
import FlexTableCell from "./TableCell";
import { columnsFlexDefinitions } from "./columnsDefinitions";

export const ProductsTableHeading = () => {
  const isMobile = useMediaQuery({ maxWidth: 500 });
  return (
    <HeaderRow>
      <FlexTableCell flex={columnsFlexDefinitions.date}>
        <Txt textAlign={'start'} marginLeft={4}>Data</Txt>
      </FlexTableCell>
      <FlexTableCell flex={columnsFlexDefinitions.title}>
        <Txt>Title</Txt>
      </FlexTableCell>
      <FlexTableCell flex={columnsFlexDefinitions.brandName}>
        <Txt>Brand</Txt>
      </FlexTableCell>
      <FlexTableCell flex={columnsFlexDefinitions.price}>
        <Txt>Prezzo Listino</Txt>
      </FlexTableCell>
      <FlexTableCell flex={columnsFlexDefinitions.discountedPrice}>
        <Txt>Prezzo scontato</Txt>
      </FlexTableCell>
      <FlexTableCell flex={columnsFlexDefinitions.quantity}>
        <Txt>Qtà disponibile</Txt>
      </FlexTableCell>
      <FlexTableCell justifyContent={"center"} flex={columnsFlexDefinitions.actions}>
        <Txt>Azioni</Txt>
      </FlexTableCell>
    </HeaderRow>
  );
};
