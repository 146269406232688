import { useContext, useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { NotFound } from "./NotFound";
import { Login } from "./Login";
import { MainBody } from "./MainBody";

import { AuthContext } from "../context/AuthContext";

import Flex from "../components/primitives/Flex";
import { PAGE } from "./pagesPath";
import { AppGeneralContext } from "../context/AppGeneralContext";

import { UnauthorizedBody } from "./UnauthorizedBody";
import { CustomerMainBody } from "./CustomerMainBody";
import { CustomersList } from "./CustomersList";
import { CustomerDetail } from "./CustomerDetail";
import { CouponsList } from "./CouponsList";
import { CouponDetail } from "./CouponDetail";
import { CouponMainBody } from "./CouponMainBody";
import { AdventCustomerMainBody } from "./AdventCustomerMainBody";
import { AdventCustomersList } from "./AdventCustomersList";
import { AdventCustomerDetail } from "./AdventCustomerDetail";
import { ProductMainBody } from "./ProductMainBody";
import { ProductsList } from "./ProductsList";
import { ProductDetail } from "./ProductDetail";
import { OrderMainBody } from "./OrderMainBody";
import { OrdersList } from "./OrdersList";
import { OrderDetail } from "./OrderDetail";

export const CustomRoutes = () => {
  const { auth } = useContext(AuthContext);
  const { isMobile } = useContext(AppGeneralContext);


  return (
    <Flex justifyContent={"center"} width={"100%"} height={"100%"}>
      <Routes>
        {!!auth?.jwt ? (
          <Route path="/" element={<MainBody />}>
            <Route index element={<Navigate to={`./${PAGE.customers.path}`} />} />
            <Route path={PAGE.customers.path} element={<CustomerMainBody />}>
              <Route index element={<Navigate to={`./${PAGE.customers.children.list.path}`} />} />
              <Route path={PAGE.customers.children.list.path} element={<CustomersList />} />
              <Route path={PAGE.customers.children.detail.path} element={<CustomerDetail />} />
            </Route>
            <Route path={PAGE.coupons.path} element={<CouponMainBody />}>
              <Route index element={<Navigate to={`./${PAGE.coupons.children.list.path}`} />} />
              <Route path={PAGE.coupons.children.list.path} element={<CouponsList />} />
              <Route path={PAGE.coupons.children.detail.path} element={<CouponDetail />} />
            </Route>
            <Route path={PAGE.orders.path} element={<OrderMainBody />}>
              <Route index element={<Navigate to={`./${PAGE.orders.children.list.path}`} />} />
              <Route path={PAGE.orders.children.list.path} element={<OrdersList />} />
              <Route path={PAGE.orders.children.detail.path} element={<OrderDetail />} />
            </Route>
            <Route path={PAGE.adventCustomers.path} element={<AdventCustomerMainBody />}>
              <Route index element={<Navigate to={`./${PAGE.adventCustomers.children.list.path}`} />} />
              <Route path={PAGE.adventCustomers.children.list.path} element={<AdventCustomersList />} />
              <Route path={PAGE.adventCustomers.children.detail.path} element={<AdventCustomerDetail />} />
            </Route>
            <Route path={PAGE.prods.path} element={<ProductMainBody />}>
              <Route index element={<Navigate to={`./${PAGE.prods.children.list.path}`} />} />
              <Route path={PAGE.prods.children.list.path} element={<ProductsList />} />
              <Route path={PAGE.prods.children.detail.path} element={<ProductDetail />} />
            </Route>
            {/* <Route path="*" element={<Navigate to={`/${PAGE.customersList.path}`} />} /> */}
            <Route path="*" element={<NotFound />} />
          </Route>
        ) : (
          <Route element={<UnauthorizedBody />}>
            <Route path={PAGE.login.path} element={<Login />} />
            <Route path="*" element={<Navigate to={`/${PAGE.login.path}`} />} />
          </Route>
        )}
      </Routes>
    </Flex>
  );
};
