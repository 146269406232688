import React, { useContext, useState } from "react";

import { Pencil, Download, Trash } from 'react-bootstrap-icons'

import Tooltip from "../primitives/Tooltip";
import { ThemeContext } from "styled-components";
import { useMediaQuery } from "react-responsive";
import FlexTableCell from "./TableCell";
import TableRow from "./TableRow";
import Txt from "../primitives/Txt";
import Flex from "../primitives/Flex";
import { MOBILE_TABLE_TEMPLATE, TABLE_TEMPLATE } from "../../utils/const/utilsConst";
import { ModalContext } from "../../context/ModalContext";
import { ModalTypeEnum, toastType } from "../../type/enum";
import { Customer } from "../../type/models";
import { createLink, createProjectLink } from "../../utils";
import { download } from "../../utils/function/download";
import { AppGeneralContext } from "../../context/AppGeneralContext";
import { deleteCustomer, getCustomerById } from "../../api/CustomersCall";
import { CMS_API_CONTROLLER, adventIcon } from "../../utils/const/apiConst";
import { columnsFlexDefinitions } from "./columnsDefinitions";
import { CustomerContext } from '../../context/CustomerContext';
import { useNavigate } from 'react-router-dom';
import { ActionsType } from '../../type/useReducer';
import { PAGE } from "../../pages";
interface AdventCustomerTableProps {
  data: Customer[]
}

export const AdventTableBody = ({ data }: AdventCustomerTableProps) => {
  const { colors } = useContext(ThemeContext);
  const { getCustomersList, customerDispatch } = useContext(CustomerContext)
  const navigate = useNavigate()
  const { addToast } = useContext(AppGeneralContext)
  const { setLoadingModal, openConfirmModal, closeModal } = useContext(ModalContext)

  const getCustomer = async (id: string) => {
    const state = { customerId: id }
    navigate(`/${PAGE.adventCustomers.path}/${PAGE.adventCustomers.children.detail.path}`, { state })
  }


  const deleteMethod = async (id: string) => {
    setLoadingModal()
    const resp = await deleteCustomer(id)
    if (!resp.response || !resp.isSuccess) {
      closeModal()
      addToast(toastType.error, 'Errore nella cancellazione')
      return
    }
    closeModal()
    addToast(toastType.success, 'Cancellazione avvenuta con successo')
    getCustomersList()
  }

  return (
    <>
      {data.map((el) => {
        return (
          <Flex key={el.id}>
            <FlexTableCell flex={columnsFlexDefinitions.name}>
              <Txt marginLeft={4} display={'block'} textAlign={'start'}> {el.name}</Txt>
            </FlexTableCell>

            <FlexTableCell flex={columnsFlexDefinitions.surname}>
              <Txt>{el.surname}</Txt>
            </FlexTableCell>

            <FlexTableCell flex={columnsFlexDefinitions.email}>
              <Txt>{el.email}</Txt>
            </FlexTableCell>

            <FlexTableCell flex={columnsFlexDefinitions.phoneNumber}>
              <Txt>{el.phoneNumber}</Txt>
            </FlexTableCell>

            <FlexTableCell flex={columnsFlexDefinitions.actions} justifyContent={'space-around'}>
              <Tooltip content={'Visualizza Cliente'}>
                <Pencil
                  onClick={() => getCustomer(el.id)}
                  cursor={'pointer'} color={colors.primaryText}
                />
              </Tooltip>

              <Tooltip content={'Elimina Cliente'}>
                <Trash
                  onClick={() => openConfirmModal(
                    'Elimina', `Sei sicuro di voler cancellare definitivamente il cliente ${el.name} ${el.surname}?`,
                    ModalTypeEnum.message, () => deleteMethod(el.id))}
                  cursor={'pointer'} color={colors.primaryText}
                />
              </Tooltip>
            </FlexTableCell>
          </Flex>
        );
      })}
    </>
  );
};
