import React, { useContext, useState } from "react";

import { Pencil, Download, Trash } from 'react-bootstrap-icons'

import Tooltip from "../primitives/Tooltip";
import { ThemeContext } from "styled-components";
import { useMediaQuery } from "react-responsive";
import FlexTableCell from "./TableCell";
import TableRow from "./TableRow";
import Txt from "../primitives/Txt";
import Flex from "../primitives/Flex";
import { ModalContext } from "../../context/ModalContext";
import { ModalTypeEnum, toastType } from "../../type/enum";
import { Coupon, Order } from "../../type/models";
import { AppGeneralContext } from "../../context/AppGeneralContext";
import { columnsFlexDefinitions } from "./columnsDefinitions";
import { CustomerContext } from '../../context/CustomerContext';
import { useNavigate } from 'react-router-dom';
import { ActionsType } from '../../type/useReducer';
import { PAGE } from "../../pages";
import { deleteOrder } from "../../api/OrdersCall";
import { OrderContext } from "../../context/OrderContext";


interface OrderTableProps {
  data: Order[]
}

export const OrderTableBody = ({ data }: OrderTableProps) => {
  const { colors } = useContext(ThemeContext);
  const navigate = useNavigate()
  const { setLoadingModal, openConfirmModal, closeModal } = useContext(ModalContext)
  const {getOrdersList } = useContext(OrderContext)
  const { addToast } = useContext(AppGeneralContext)

  
  const getOrder = async (id: string) => {
    const state = { orderId: id }
    navigate(`/${PAGE.orders.path}/${PAGE.orders.children.detail.path}`, { state })
  }
  
  const deleteMethod = async (id: string) => {
    setLoadingModal()
    const resp = await deleteOrder(id)
    if (!resp.response || !resp.isSuccess) {
      closeModal()
      addToast(toastType.error, 'Errore nella cancellazione')
      return
    }
    closeModal()
    addToast(toastType.success, 'Cancellazione avvenuta con successo')
    getOrdersList()
  }

  return (
    <>
      {data.map((el) => {
        return (
          <Flex key={el.id}>
            <FlexTableCell flex={columnsFlexDefinitions.orderNumber}>
              <Txt  marginLeft={4} display={'block'} textAlign={'start'}> {el.orderNumber}</Txt>
            </FlexTableCell>
            <FlexTableCell flex={columnsFlexDefinitions.nameSurname}>
              <Txt> {el.customer?.name} {el.customer?.surname}</Txt>
            </FlexTableCell>
            <FlexTableCell flex={columnsFlexDefinitions.email}>
              <Txt >{el.productService?.title}</Txt>
            </FlexTableCell>
            <FlexTableCell flex={columnsFlexDefinitions.date} justifyContent={'center'} >
              <Txt >{el.dateString}</Txt>
            </FlexTableCell>
            <FlexTableCell flex={columnsFlexDefinitions.orderDiscountedPrice} justifyContent={'space-around'}>
              <Txt >€ {el.productService?.discountedPrice}</Txt>
            </FlexTableCell>
            <FlexTableCell flex={columnsFlexDefinitions.actions} justifyContent={'space-around'}>
              <Tooltip content={'Visualizza Ordine'}>
                <Pencil
                  onClick={() => getOrder(el.id)}
                  cursor={'pointer'} color={colors.primaryText}
                />
              </Tooltip>
              <Tooltip content={'Elimina Ordine'}>
                <Trash
                  onClick={() => openConfirmModal(
                    'Elimina', `Sei sicuro di voler cancellare definitivamente l'ordine ${el.orderNumber}?`,
                    ModalTypeEnum.message, () => deleteMethod(el.id))}
                  cursor={'pointer'} color={colors.primaryText}
                />
              </Tooltip>
            </FlexTableCell>
          </Flex>
        );
      })}
    </>
  );
};
