import axios, { AxiosError, AxiosResponse } from 'axios'
import { ApiSimpleResponse, BaseResponse, ErrorResponse } from '../type/response/baseResponses';
import { StatusCode } from '../utils/const/statusCode';
import { QueryRequest } from '../type/request';

const resolveFunc = <TResponse extends BaseResponse>(r: AxiosResponse<TResponse>): ApiSimpleResponse<TResponse> => {
    const response = r.data;
    return { isSuccess: response.errorCode === 0, response }
}

const rejectFunc = (e: AxiosError<ErrorResponse>) => {
    const response = e.response;

    if (!response || response?.status === StatusCode.UNSUPPORTED_MEDIA_TYPE || response?.status >= StatusCode.INTERNAL_SERVER_ERROR) {
        return { isSuccess: false, error: "An unexpected error occurred, retry later" }
    }

    if (response?.status === StatusCode.NOT_AUTHORIZED) {
        return { isSuccess: false, error: "Not Authorized" }
    }

    if (response?.status === StatusCode.BAD_REQUEST || response?.status === StatusCode.NOT_FOUND) {
        return { isSuccess: false, error: response.data.errorMessage }
    }

    const errorDetails = response.data.details;
    if (!!errorDetails.errors) {
        const [firstError] = Object.keys(errorDetails.errors)

        return { isSuccess: false, error: errorDetails.errors[firstError][0] }
    }
    return { isSuccess: false, error: response.data.details.detail }
}

/**
* Creates a post call to the endpoint.
*
* @param TRequest - The request type
* @param TResponse - The expected response type
* @param endpoint - The endpoint
* @param request - The body of the request
* @returns An {@link ApiSimpleResponse} of TResponse
*/
export const postCall =
    async <TRequest, TResponse extends BaseResponse>(endpoint: string, request: TRequest): Promise<ApiSimpleResponse<TResponse>> => {
        return await axios
            .post(endpoint, request)
            .then((r: AxiosResponse<TResponse>) => resolveFunc<TResponse>(r))
            .catch(rejectFunc);
    }

/**
* Creates a delete call to the endpoint.
*
* @param TResponse - The expected response type
* @param endpoint - The endpoint
* @returns An {@link ApiSimpleResponse} of TResponse
*/
export const deleteCall =
    async <TResponse extends BaseResponse>(endpoint: string): Promise<ApiSimpleResponse<TResponse>> => {
        return await axios
            .delete(endpoint)
            .then((r: AxiosResponse<TResponse>) => resolveFunc<TResponse>(r))
            .catch(rejectFunc);
    }

/**
* Creates a get call to the endpoint.
*
* @param TResponse - The expected response type
* @param endpoint - The endpoint
* @returns An {@link ApiSimpleResponse} of TResponse
*/
export const getCall =
    async <TParams = QueryRequest, TResponse extends BaseResponse = BaseResponse>(endpoint: string, params?: TParams): Promise<ApiSimpleResponse<TResponse>> => {
        return await axios
            .get(endpoint, { params })
            .then((r: AxiosResponse<TResponse>) => resolveFunc<TResponse>(r))
            .catch(rejectFunc);
    }

/**
* Creates a postForm call to the endpoint for uploading file from form.
*
* @param TRequest - The request type
* @param TResponse - The expected response type
* @param endpoint - The endpoint
* @param request - The body of the request
* @returns An {@link ApiSimpleResponse} of TResponse
*/
export const postFormCall =
    async <TRequest, TResponse extends BaseResponse>(endpoint: string, request: TRequest): Promise<ApiSimpleResponse<TResponse>> => {
        return await axios
            .postForm(endpoint, request)
            .then((r: AxiosResponse<TResponse>) => resolveFunc<TResponse>(r))
            .catch(rejectFunc);
    }

/**
* Creates a put call to the endpoint.
*
* @param TRequest - The request type
* @param TResponse - The expected response type
* @param endpoint - The endpoint
* @param request - The body of the request
* @returns An {@link ApiSimpleResponse} of TResponse
*/
export const putCall =
    async <TRequest, TResponse extends BaseResponse>(endpoint: string, request: TRequest): Promise<ApiSimpleResponse<TResponse>> => {
        return await axios
            .putForm(endpoint, request)
            .then((r: AxiosResponse<TResponse>) => resolveFunc<TResponse>(r))
            .catch(rejectFunc);
    }