import  styled from 'styled-components' 
import Row from './Row'
import Flex from '../primitives/Flex'

// const HeaderRow = styled(Row)`
// font-weight: bold;
// border-bottom: 1px solid ${props=>props.theme.colors.primaryBorder};
// position:absolute;
// width: 100%;
// background-color: ${props=>props.theme.colors.primaryBg};
// top:0;
// `
const HeaderRow = styled(Flex)`
font-weight: bold;
border-bottom: 1px solid ${props=>props.theme.colors.primaryBorder};
position:absolute;
width: 100%;
background-color: ${props=>props.theme.colors.primaryBg};
top:0;
`

export default HeaderRow