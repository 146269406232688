/* eslint-disable react-hooks/exhaustive-deps */
import { Dispatch, FC, SetStateAction, createContext, useEffect, useState } from "react";
import { WithChildren } from "./AuthContext";
import { useMediaQuery } from "react-responsive";
import { toast } from "react-toastify";
import { toastType } from "../type/enum";
import { Aside } from "../type/models";

interface AppGeneralContextProps {
  isPotrait: boolean;
  isMobile: boolean;
  setIsMobile: (isMobile: boolean) => void;
  addToast: (type: toastType, message: string) => void;
  aside: Aside,
  setAside: Dispatch<SetStateAction<Aside>>,
  setCloseAside: () => void,
}

const appContextInit: AppGeneralContextProps = {
  isPotrait: true,
  isMobile: false,
  setIsMobile: () => { },
  addToast: () => { },
  aside: {isOpen:false, title:''},
  setAside: () => { },
  setCloseAside: () => { },
};

export const AppGeneralContext =
  createContext<AppGeneralContextProps>(appContextInit);

export const AppGeneralContextProvider: FC<WithChildren> = ({ children }) => {
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [isPotrait, setIsPotrait] = useState<boolean>(false);
  const isSmallDevice = useMediaQuery({ maxWidth: 768 });
  const isPotraitView = useMediaQuery({ orientation: 'portrait' });
  const [aside, setAside] = useState<Aside>({isOpen:false, title:''})
  const setCloseAside = () => setAside(x => ({ ...x, isOpen: false }))


  const addToast = (type: toastType, message: string) => {
    switch (type) {
      case toastType.error:
        toast.error(message);
        return;
      case toastType.success:
        toast.success(message);
        return;
      case toastType.warning:
        toast.warning(message);
        return;
    }
  };
  
  useEffect(() => {
    if (isPotraitView) {
      setIsPotrait(true);

    } else {
      setIsPotrait(false);

    }
  }, [isPotraitView]);

  useEffect(() => {
    if (isSmallDevice) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [isSmallDevice]);


  const appContextInitValue: AppGeneralContextProps = {
    isPotrait,
    isMobile,
    setIsMobile,
    addToast,
    aside,setAside,
    setCloseAside
  };

  return (
    <AppGeneralContext.Provider value={appContextInitValue}>
      {children}
    </AppGeneralContext.Provider>
  );
};
