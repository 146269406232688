import { Coupon} from "../type/models"
import { ListCouponsRequest } from "../type/request"
import { CouponResponse, PaginatedResponse } from "../type/response"
import { BASE_URL, CMS_API_CONTROLLER } from "../utils/const/apiConst"
import { getCall, postCall } from "./baseCall"

export const getCouponList = async (params: ListCouponsRequest) => {
    return await postCall<ListCouponsRequest, PaginatedResponse<Coupon>>(`${BASE_URL}${CMS_API_CONTROLLER}/couponsList`, params)
}
export const getCouponById = async (id: string) => {
    return await getCall<{}, CouponResponse>(`${BASE_URL}${CMS_API_CONTROLLER}/getCouponById?id=${id}`)
}