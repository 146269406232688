import * as Yup from "yup";
import { Customer, Product } from "../type/models";

type SchemaObject = {
    [key in keyof Customer]: Yup.Schema<any>
}

export const customerValidationSchema = Yup.object().shape<Partial<SchemaObject>>({
    name: Yup.string().nullable()
        .max(150, 'Massimo 150 caratteri'),
    surname: Yup.string().nullable()
        .max(150, 'Massimo 150 caratteri'),
    note: Yup.string().nullable()
        .max(250, 'Massimo 250 caratteri'),
    email: Yup.string().nullable()
        .matches(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/, "Verificare la correttezza dell'indirizzo e-mail inserito."),
    phoneNumber: Yup.string().nullable()
        .max(20, 'Massimo 20 caratteri')
})

export const orderValidationSchema = Yup.object().shape<Partial<SchemaObject>>({
})

type SchemaProduct = {
    [key in keyof Product]: Yup.Schema<any>
}

export const productValidationSchema = Yup.object().shape<Partial<SchemaProduct>>({
    title: Yup.string().nullable()
        .max(150, 'Massimo 250 caratteri'),
    description: Yup.string().nullable()
        .max(500, 'Massimo 500 caratteri'),
    price: Yup.string().nullable()
        .max(250, 'Massimo 10 caratteri'),
    date: Yup.date().nonNullable()
})

