import React, { useContext, useEffect, useReducer, useState } from 'react'
import Box from '../components/primitives/Box'
import Flex from '../components/primitives/Flex'
import { AppGeneralContext } from '../context/AppGeneralContext';
import Txt from '../components/primitives/Txt';
import Label from '../components/primitives/Label';
import { Input } from '../components/atoms';
import { getProductById, upsertProduct } from '../api/ProductsCall';
import { useDebounce } from '../utils';
import TextArea from '../components/atoms/TextArea'
import { useFormik } from 'formik'

import { ThemeContext } from 'styled-components';
import { ModalContext } from '../context/ModalContext';
import { toastType } from '../type/enum';
import { Product } from '../type/models';
import { ApiSimpleResponse, ProductResponse, Pager } from '../type/response';
import { PagerInitialState } from '../utils/const/initialValue';
import Form from '../components/primitives/Form'
import { productValidationSchema } from '../utils/formikSchemas';
import { ActionButton } from '../components';
import { ProductContext } from '../context/ProductContext';
import { useLocation } from 'react-router-dom';
import { BASE_URL, defaultimg } from '../utils/const/apiConst';
import { prodInit, prodInitState, prodReducer } from '../type/useReducer';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs, { Dayjs } from 'dayjs';
import { UploadFileComponent } from '../components/molecules/UploadFileComponent';


export const ProductDetail = () => {
  const { setProductsList, ProductsList } = useContext(ProductContext)
  const location = useLocation()
  const productId = location.state.productId as string | undefined
  const { addToast } = useContext(AppGeneralContext);
  const { colors } = useContext(ThemeContext);
  const [ProductDetail, setProductDetail] = useState<Product>(prodInit)
  const [pager, setPager] = useState<Pager>(PagerInitialState)
  const [binAction, setBinAction] = useState<boolean>(false)
  const [ProductState, ProductDispatch] = useReducer(prodReducer, prodInit, prodInitState)

  const { setLoadingModal, closeModal, openApiModalMessage } = useContext(ModalContext);

  const [selectedImage, setSelectedImage] = useState<File>()
  const [errorImage, setErrorImage] = useState("")
  const [deleteImageCover, setDeleteImageCover] = useState(false)
  const [dimensionNotValid, setDimensionNotValid] = useState<boolean>()


  const modalDelay = 300

  const formik = useFormik<Product>({
    enableReinitialize: true,
    initialValues: ProductDetail ?? prodInit,
    validationSchema: productValidationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setLoadingModal()
      setSubmitting(true)
      let resp: ApiSimpleResponse<ProductResponse>
      resp = await upsertProduct(ProductDetail?.id ?? '', { ...values })
      if (!resp.response || !resp.isSuccess) {
        openApiModalMessage(resp)
        return
      }
      closeModal(modalDelay)
      setSubmitting(false)
      getProductById(resp.response.data.id)
    }
  })

  const getProduct = async (id: string) => {
    setLoadingModal()
    const resp = await getProductById(id)
    if (!resp.response || !resp.isSuccess) {
      closeModal(modalDelay)
      addToast(toastType.error, 'Errore durante il caricamento dei dati')
      return
    }
    closeModal(modalDelay)
    setProductDetail(resp.response.data)
  }

  useEffect(() => {
    if (!!productId) {
      getProduct(productId)
    }
  }, []);

  return (
    <Flex flexDirection={"column"} width={"90%"} height={"100vh"}>
      <Flex justifyContent={"center"} alignItems={"center"} height={"12%"} mt={'20px'} mb={'20px'}>
        <Txt>
          <h2>Anagrafica {formik.values.title} - {formik.values.brandName}</h2>
        </Txt>
      </Flex>
      <Form width={'100%'} onSubmit={formik.handleSubmit} flexDirection={'column'} justifyContent={'space-around'}>
        <Flex flexWrap={'wrap'} justifyContent={'center'}>
          <Flex width={'100%'} flexDirection={'row'} justifyContent={'space-evenly'}>
            <Box width={"lg"} marginRight={[0, 3]} textAlign={"left"}>
              <Label pl={2}>Titolo</Label>
              <Input
                {...formik.getFieldProps("nome")}
                id='title'
                variant={"normal"}
                value={formik.values.title}
                type="text"
                name={"title"}
                formik={formik}
                placeholder="Nome"
              />
              {formik.errors.title && formik.touched.title && (
                <Txt variant="error" id="title">
                  {formik.errors.title}
                </Txt>
              )}
            </Box>
            <Box width={"lg"} marginRight={[0, 3]} textAlign={"left"}>
              <Label pl={2}>Marchio/Brand</Label>
              <Input
                {...formik.getFieldProps("brandName")}
                id='brandName'
                variant={"normal"}
                value={formik.values.brandName}
                type="text"
                name={"brandName"}
                formik={formik}
                placeholder="Nome del marchio"
              />
              {formik.errors.brandName && formik.touched.brandName && (
                <Txt variant="error" id="brandName">
                  {formik.errors.brandName}
                </Txt>
              )}
            </Box>
            <Box width={"lg"} marginRight={[0, 3]} textAlign={"left"}>
              <Label pl={2}>Data pubblicazione</Label>
              <DatePicker<Dayjs>
                className='datepicker'
                format='DD/MM/YYYY'
                value={dayjs(formik.values.date)}
                onChange={(datevalue) => {
                  formik.setFieldValue("date", datevalue?.toISOString())
                }}
              />
              {formik.errors.date && formik.touched.date && (
                <Txt variant="error" id="date">
                  {formik.errors.date}
                </Txt>
              )}
            </Box>
          </Flex>
          <Flex flexDirection={'row'} width={"100%"} justifyContent={'center'} mt={'30px'}>
            <Box minWidth={"50%"} height={'100%'} marginRight={[0, 3]} textAlign={"left"}>
              <Label pl={2}>Descrizione</Label>
              <TextArea
                {...formik.getFieldProps("description")}
                id='description'
                name={"description"}
                value={formik.values.description}
                placeholder='Descrizione aggiuntiva'
                onChange={formik.handleChange}
                onBlur={() => formik.setFieldTouched('description', true)}
              />
              {formik.errors.description && formik.touched.description && (
                <Txt variant="error" id="description">
                  {formik.errors.description}
                </Txt>
              )}
            </Box>
            <Box minWidth={"35%"} height={'100%'} textAlign={"left"}>
              <div className='image-container cursor-pointer position-relative  m- p-0   '>
                <Label pl={2}>Immagine prodotto o servizio</Label>
                <UploadFileComponent
                  inputName='imgFile'
                  className='image-profile'
                  url={`${BASE_URL}/${ProductDetail?.imgUrl}` ?? `${BASE_URL}/${defaultimg}`}
                  formik={formik}
                  aspectRatio={"16/9"}
                  dimensioni="800x450px"
                  file={formik.values.imgFile}
                  fileType={".jpeg, .jpg, .gif, .png"}
                  callback={setSelectedImage}
                  label=""
                  id='imgFile'
                  setError={setErrorImage}
                  setDeleteImage={setDeleteImageCover}
                  imageDeleted={deleteImageCover}
                  imgPlaceholder={defaultimg}
                  dimensionNotValid={dimensionNotValid}
                />
              </div>
            </Box>
          </Flex>
          <Flex width={'100%'} flexDirection={'row'} justifyContent={'space-evenly'} mt={'20px'}>
            <Box width={"lg"} marginRight={[0, 3]} textAlign={"left"}>
              <Label pl={2}>Prezzo di listino</Label>
              <Input
                {...formik.getFieldProps("price")}
                id='price'
                variant={"normal"}
                value={formik.values.price}
                type="text"
                name={"price"}
                formik={formik}
                placeholder="Prezzo di listino"
              />
              {formik.errors.price && formik.touched.price && (
                <Txt variant="error" id="price">
                  {formik.errors.price}
                </Txt>
              )}
            </Box>
            <Box width={"lg"} marginRight={[0, 3]} textAlign={"left"}>
              <Label pl={2}>Prezzo scontato</Label>
              <Input
                {...formik.getFieldProps("discountedPrice")}
                id='discountedPrice'
                variant={"normal"}
                value={formik.values.discountedPrice}
                type="text"
                name={"discountedPrice"}
                formik={formik}
                placeholder="Prezzo scontato"
              />
              {formik.errors.discountedPrice && formik.touched.discountedPrice && (
                <Txt variant="error" id="discountedPrice">
                  {formik.errors.discountedPrice}
                </Txt>
              )}
            </Box>
          </Flex>
          <Flex width={'100%'} flexDirection={'row'} justifyContent={'space-evenly'} mt={'20px'}>
            <Box width={"lg"} marginRight={[0, 3]} textAlign={"left"}>
              <Label pl={2}>Quantità totale a disposizione</Label>
              <Input
                {...formik.getFieldProps("quantity")}
                id='quantity'
                variant={"normal"}
                value={formik.values.quantity}
                type="number"
                name={"quantity"}
                formik={formik}
                placeholder="quantity"
              />
              {formik.errors.quantity && formik.touched.quantity && (
                <Txt variant="error" id="quantity">
                  {formik.errors.quantity}
                </Txt>
              )}
            </Box>
            <Box width={"lg"} marginRight={[0, 3]} textAlign={"left"}>
              <Label pl={2}>Quantità acquistabile per persona</Label>
              <Input
                {...formik.getFieldProps("discountedPrice")}
                id='orderQuantityLimit'
                variant={"normal"}
                value={formik.values.orderQuantityLimit}
                type="number"
                name={"orderQuantityLimit"}
                formik={formik}
                placeholder="Quantità per utente"
              />
              {formik.errors.orderQuantityLimit && formik.touched.orderQuantityLimit && (
                <Txt variant="error" id="orderQuantityLimit">
                  {formik.errors.orderQuantityLimit}
                </Txt>
              )}
            </Box>
          </Flex>
        </Flex>
        <ActionButton onAbort={() => closeModal()} onConfirm={() => { }}></ActionButton>
      </Form >
    </Flex>
  )
}
