import { useContext, useEffect, useMemo } from 'react'
import Box from '../components/primitives/Box'
import Flex from '../components/primitives/Flex'
import { AppGeneralContext } from '../context/AppGeneralContext';
import Txt from '../components/primitives/Txt';
import Label from '../components/primitives/Label';
import { Input } from '../components/atoms';
import { PlusCircle, Search, Trash } from 'react-bootstrap-icons';
import { PagerComponent } from '../components/primitives';
import Tooltip from '../components/primitives/Tooltip';
import { ThemeContext } from 'styled-components';
import { ProductContext } from '../context/ProductContext';
import { useNavigate } from 'react-router-dom';
import { ActionsType } from '../type/useReducer';
import { PAGE } from './pagesPath';
import { ProductsTableBody } from '../components/table/ProductsTableBody';
import { ProductsTableHeading } from '../components/table/ProductsTableHeading';
import { DatePicker } from '@mui/x-date-pickers';
import { Dayjs } from 'dayjs';

export const ProductsList = () => {
  const { isMobile } = useContext(AppGeneralContext);
  const navigate = useNavigate()
  const { colors } = useContext(ThemeContext);
  const { ProductDispatch, binAction, setBinAction, pager, setPage, ProductsList, getProductsList, setTitleToSearch, titleToSearch, setBrandNameToSearch,
    brandNameToSearch, setDateFromToSearch, dateFromToSearch, setDateToToSearch, dateToToSearch } = useContext(ProductContext)

  const showPagination = useMemo(() => {
    if (pager.totalPages < 1) {
      return <></>;
    }
    return (
      <Flex
        flexDirection={"row"}
        width={"100%"}
        justifyContent={"end"}
        height={'20%'}
      >
        <PagerComponent pager={pager} setPage={setPage}></PagerComponent>
      </Flex>
    );
  }, [pager]);

  const newproductHandler = () => {
    const state = { isCreate: true }
    ProductDispatch({ type: ActionsType.RESET_PROD })
    navigate(`/${PAGE.prods.path}/${PAGE.prods.children.detail.path}`, { state })
  };

  useEffect(() => {
    ProductDispatch({ type: ActionsType.RESET_PROD })
  }, [])

  useEffect(() => {
    getProductsList()
  }, [getProductsList]);

  return (
    <Flex flexDirection={"column"} width={"90%"} height={"100vh"}>
      <Flex justifyContent={"center"} alignItems={"center"} height={"12%"} mt={'20px'} mb={'25px'}>
        <Txt>
          <h2>Lista prodotti e servizi calendario avvento</h2>
        </Txt>
      </Flex>
      <Flex width={"100%"} flexDirection={'row'} height={'10%'} marginBottom={3} justifyContent={'space-between'}>
        <Flex alignItems={"end"} width={'100%'} >
          {ProductsList.length === 0 && brandNameToSearch === "" && titleToSearch === ""
            && dateFromToSearch === "" && dateToToSearch === "" && !binAction ? (
            <></>
          ) : (
            <>
              <Box width={"md"} marginRight={3} textAlign={"left"}>
                <Label pl={2}>Filtra per Titolo</Label>
                <Input
                  leftIconBackground={colors.transparent}
                  clearable
                  icon={<Search color={colors.primaryText} />}
                  leftIcon
                  variant={"normal"}
                  value={titleToSearch}
                  type="text"
                  name={"Titolo"}
                  formik={undefined}
                  onChangeCallback={(e) => setTitleToSearch(e.target.value)}
                  onClear={() => setTitleToSearch("")}
                  placeholder="Titolo"
                />
              </Box>
              <Box width={"md"} marginRight={3} textAlign={"left"}>
                <Label pl={2}>Filtra per Brand</Label>
                <Input
                  leftIconBackground={colors.transparent}
                  clearable
                  icon={<Search color={colors.primaryText} />}
                  leftIcon
                  variant={"normal"}
                  value={brandNameToSearch}
                  type="text"
                  name={"Brand"}
                  formik={undefined}
                  onChangeCallback={(e) => setBrandNameToSearch(e.target.value)}
                  onClear={() => setBrandNameToSearch("")}
                  placeholder="Brand"
                />
              </Box>
              <Box width={"md"} marginRight={3} textAlign={"left"}>
                <Label pl={2}>Filtra per Data da</Label>
                <DatePicker<Dayjs>
                  className='datepicker'
                  format='DD/MM/YYYY'
                  // value={dateFromToSearch}
                  // onChangeCallback={(e) => setDateFromToSearch(e.target.value)}
                />                
                </Box>
                <Box width={"md"} marginRight={3} textAlign={"left"}>
                <Label pl={2}>a</Label>
                <DatePicker<Dayjs>
                  className='datepicker'
                  format='DD/MM/YYYY'
                  // value={dateToToSearch}
                  // onChangeCallback={(e) => setDateToToSearch(e.target.value)}
                />                
                </Box>
            </>
          )}
          {(titleToSearch !== "" || brandNameToSearch !== "" || dateFromToSearch !== "" || dateToToSearch !== "") &&
            <Box marginLeft={4} marginBottom={1}>
              <Trash size={'1.3em'} color={colors.primaryText}
                onClick={() => {
                  setBrandNameToSearch(''); setTitleToSearch(''); setDateFromToSearch(''); setDateToToSearch(''); setBinAction(true)
                }}
                cursor={'pointer'} /></Box>}
        </Flex>
        <Flex
          alignItems={"end"}
          justifyContent={"center"}
          height={"100%"}
        >
          <Tooltip content={"Aggiungi nuovo prodotto"}>
            <PlusCircle
              size={'2.5em'}
              color={colors.primarySolidHover}
              cursor={"pointer"}
              onClick={() => {
                newproductHandler();
              }}
            />
          </Tooltip>
        </Flex>
      </Flex>
      <Flex
        flexDirection={"column"}
        justifyContent={"space-between"}
        height={"78%"}
        position={"relative"}
        width={'100%'}
      >
        {ProductsList.length > 0 ? (
          <Flex
            flexDirection={"column"}
            height={"80%"}
            marginTop={isMobile ? "80px" : "59px"}
            overflowY={"scroll"}
            width={"100%"}
          >
            <ProductsTableHeading />
            <ProductsTableBody data={ProductsList} />
          </Flex>) : (<>Mi dispiace, ma non ho trovato ciò che cerchi</>)}
        {showPagination}
      </Flex>
    </Flex>
  )
}
