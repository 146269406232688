
import React, { useEffect, useMemo, useState } from "react";
import { Eye, EyeSlash } from "react-bootstrap-icons";

import styled from "styled-components";

interface iHidePassword {
  background?:string
  className?: string;
  link?: string;
  size?: string;
  color?: string;
  containerSize?: string;
  iconSize:string | number | undefined
  iconPosition?: "left" | "right";
  callback?: (isHidden: boolean) => void;
}

export const StyledIconContainer = styled.div<Partial<iHidePassword>>`
  height: ${(p) => p.containerSize};
  width: ${(p) => p.containerSize};
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  transform: translate(0%, -50%);
  ${(p) => {
    switch (p.iconPosition) {
      case "left":
        return `
      left:3px;
      height:70%;
      width:30px;
      background:${p.background};
      border-radius:100%;
    `;
      case "right":
        return `
      right:15px;
    `;
      case undefined:
        return `
      right:15px
    `;
    }
  }}
`;
export const HidePasswordIcon = ({
  className,
  size,
  color,
  containerSize,
  callback,
  iconSize,
  background,
  ...props
}: iHidePassword) => {
  const [isHidden, setIsHidden] = useState<boolean>(true);

  const showIcon = useMemo(() => {
    if (isHidden) {
      return (
        <Eye
          cursor={"pointer"}
         
          color={ color }
          size={iconSize}
        />
      );
    } else {
      return (
        <EyeSlash
          cursor={"pointer"}
          
          color={color }
          size={iconSize}
        />
      );
    }
  }, [isHidden, color, iconSize]);

  useEffect(() => {
    if (!!callback) callback(isHidden);
  }, [isHidden, callback]);

  return (
    <StyledIconContainer
      {...props}
      color={color}
      containerSize={containerSize}
      onClick={() => setIsHidden((x) => !x)}
    >
      {showIcon}
    </StyledIconContainer>
  );
};
