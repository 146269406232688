import { Coupon, Customer } from "../type/models"
import { CustomersRequest, ListCouponsRequest, ListCustomersRequest } from "../type/request"
import { BaseResponse, PaginatedResponse, CustomerResponse } from "../type/response"
import { BASE_URL, CMS_API_CONTROLLER } from "../utils/const/apiConst"
import { deleteCall, getCall, postCall, postFormCall, putCall } from "./baseCall"

export const getCustomerList = async (params: ListCustomersRequest) => {
    return await postCall<ListCustomersRequest, PaginatedResponse<Customer>>(`${BASE_URL}${CMS_API_CONTROLLER}/customersList`, params)
}
export const getCustomerById = async (id: string) => {
    return await getCall<{}, CustomerResponse>(`${BASE_URL}${CMS_API_CONTROLLER}/getCustomerById?id=${id}`)
}
export const createCustomer = async (customer: CustomersRequest) => {
    return await postFormCall<CustomersRequest, CustomerResponse>(`${BASE_URL}${CMS_API_CONTROLLER}`, customer)
}
export const upsertCustomer = async (id: string, customer: CustomersRequest) => {
    return await postCall<CustomersRequest, CustomerResponse>(`${BASE_URL}${CMS_API_CONTROLLER}/upsertCustomer`, customer)
}
export const deleteCustomer = async (id: string) => {
    return await deleteCall<BaseResponse>(`${BASE_URL}${CMS_API_CONTROLLER}/deleteCustomer?id=${id}`)
}

export const postReferredList = async (params: ListCustomersRequest) => {
    return await postCall<ListCustomersRequest, PaginatedResponse<Customer>>(`${BASE_URL}${CMS_API_CONTROLLER}/customersList`, params)
}

export const getAdventCustomersList = async (params: ListCustomersRequest) => {
    return await postCall<ListCustomersRequest, PaginatedResponse<Customer>>(`${BASE_URL}${CMS_API_CONTROLLER}/adventCalendarCustomersList`, params)
}