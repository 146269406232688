import { createGlobalStyle } from "styled-components";

//create global theme
const GlobalStyle = createGlobalStyle`

  html{
    height: 100%;
    line-height: 1.5;
    -webkit-text-size-adjust: '100%';
    -moz-tab-size: 4;
    tab-size: 4;
    font-family: 'DM Sans', sans-serif !important ;

  }
  body {
    margin: 0;
    padding: 0;
    font-size: 16px;
    box-sizing: border-box;
    scroll-behavior: smooth;
    background-color:${(props) => props.theme.colors.background};
    color: ${(props) => props.theme.colors.textColor};
    text-align: center;
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
 //   scrollbar-width: none;  /* Firefox */
    scrollbar-width: 10px;
    scrollbar-color:${(props) => props.theme.colors.primaryBorderHover} ;
    
  }
 *::-webkit-scrollbar { 
 //   display: none;  /* Safari and Chrome */
width: 5px;
}
*::-webkit-scrollbar-thumb{
 background: ${(props) => props.theme.colors.primaryBorderHover};
 border-radius: 5px;

}
*::-webkit-scrollbar-track{
  background-color: ${(props) => props.theme.colors.primaryBorder};
  border-radius: 5px;

}
  *, *::before, *::after {
    box-sizing: border-box;
  }
  a{
    text-decoration: none;
    color:${(props) => props.theme.colors.primaryText};
    font-weight: bold;
  }
  small {
    font-Size: '80%',
  }

  table {
    text-Indent: 0 ;
    border-Color: inherit ;
    border-Collapse: collapse ;
  }
  img, svg, video, canvas, audio, iframe, embed, object {
    display: block;
    
  }
  
  img, video {
    max-Width: 100%;
    height: auto;
  }
 

  button  {
    cursor: pointer;
  }

  h1, h2, h3, h4, h5, h6 {
 margin:0;

  }

  :focus-visible{
    outline: auto 1px ${(props) => props.theme.colors.primarySolidHover} ;
  }

 @media(max-width:576px){
   body{
    font-size: 14px;
   }

  
}


`;

export default GlobalStyle;
