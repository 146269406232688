import { Outlet } from "react-router-dom";
import { AdventCustomerContextProvider } from "../context/AdventCustomerContext";


export const AdventCustomerMainBody = () => {
  return (
    <AdventCustomerContextProvider>
      <Outlet />
    </AdventCustomerContextProvider>
  );
};
